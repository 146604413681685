/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import loadinggif from './../../assets/gif/lorrygif.gif'
import ReactToPrint from 'react-to-print';
// reactstrap components
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
} from "reactstrap";
// core components
import ReactDatetime from "react-datetime";
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import Moment from 'moment';
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
class Sales extends React.Component {
  
  constructor(props){
    super(props);  
  } 

  state = {
    tabs: 1,
    tabsimportexport:1,   
    loading:false,   
    saleslist:[],
    producttablelist:[],
    stockphonelist:[],
    stockaccessorieslist:[],
    stocksparepartlist:[],
    searchtextvalue:'',
    detailsalemodalonoff:false,
    editdetailinvoice:false,
    //linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
     linkurl:"http://pos.mzprotrading.com/api/mzprotrading.php?",
    // linkurl:"https://cors-anywhere.herokuapp.com/http://pos.mzprotrading.com/api/mzprotrading.php?",
    startDate:Moment(new Date).add(-1,'d'),
    endDate:new Date,
    deletemodalproductonoff:false,
    deletemodalonoff:false,
    addnewproductmodalonoff:false,
    messageboxmodalonoff:false,
    messageformessagebox:"",
    selectedjobid:0,
    userid:sessionStorage.getItem('userid'),
  };

  getsaleslist = () =>{     
   
    
      var enddatetimestamp = Moment(this.state.endDate).unix();
      console.log("enddatetimestamp: " + enddatetimestamp);

      var startdatetimestamp = Moment(this.state.startDate).unix();
      console.log("startdatetimestamp: " + startdatetimestamp);

     var urlencoded = new URLSearchParams();

     if(startdatetimestamp > enddatetimestamp){
      window.confirm("Start Date cannot before End Date")
       return;
     }
     urlencoded.append("startdatetimestamp", startdatetimestamp);
     urlencoded.append("enddatetimestamp", enddatetimestamp);


     let link = this.state.linkurl +'mode=admin-selectsaleslist'
     fetch(link, {
       method: 'POST', 
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
       },
       body:urlencoded
     })
     .then(response => response.json())
     .then(json => {
       console.log(json.success)
       //console.log(json)
       if(json.success == 1){
        
        console.log(json)
        this.setState({saleslist:[]});   
         var lengthemployee = Object.keys(json.employee).length;
         for(let i=0;i<lengthemployee;i++){
           var invoicedatetoshow = Moment(json.employee[i].invoicedate*1000).format('DD/MM/YYYY')
            this.setState({saleslist:[...this.state.saleslist,
              { no:i+1,
                ref:json.employee[i].Ref,
                invoiceno:json.employee[i].invoicenumber,   
                salesperson:json.employee[i].salesperson,             
                totalprice:json.employee[i].totalprice,
                invoicedate:invoicedatetoshow, 
                totalnumberofproduct:json.employee[i].totalnumberofproduct,  
                saleperson:json.employee[i].salesperson,  
                customername:json.employee[i].customername,  
                phonenumbervalue:json.employee[i].phonenumbervalue,  
                paymentmethodvalue:json.employee[i].paymentmethodvalue, 
                paymentmethod1value:json.employee[i].paymentmethod1value, 
                paymentmethod2:json.employee[i].paymentmethod2, 
                paymentmethod2value:json.employee[i].paymentmethod2value, 
                remark:json.employee[i].remark, 
              },            
            ]})
          
          //  console.log(phonenumbervalue)
           
          }
         // this.setState({loading:false})  
        }        
           
     else{
      this.setState({saleslist:[]}); 
      this.setState({loading:false}) 
    console.log(json)
  }
      })
   
  }  

  getphonestocklist = () =>{     
   
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectphonestocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       // console.log(json)
       this.setState({stockphonelist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          if(json.employee[i].statusvalue == "Ready For Sell"){
            this.setState({stockphonelist:[...this.state.stockphonelist,
              { no:i+1,
                productname:json.employee[i].productname,   
                imei:json.employee[i].imei,             
                storagevalue:json.employee[i].storagevalue,
                colorvalue:json.employee[i].colorvalue,
                //fingerprintvalue:json.employee[i].fingerprintvalue,
                //brandvalue:json.employee[i].brandvalue,      
                //faceidvalue:json.employee[i].faceidvalue,
                saleprice:json.employee[i].saleprice,
                quantityvalue:json.employee[i].quantityvalue,
                purchasedate:purchasedateshow,
                statusvalue:json.employee[i].statusvalue,
                title:json.employee[i].imei,
                productid:json.employee[i].Ref,
              },            
            ]})
          }
          
         
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }

  selectedcategoryfunction = (value) =>{
    this.setState({selectedcategory:value})

    //empty all
    this.setState({
      selectedproductname:'',
      selectedquantity:0,
      selectedremark:'',
      selectedsubtotal:0,
      selectedquantity:1,
      selectedsalesdate:'',
      selectedwarrantyduration:'',
      selectedwarrantystartdate:'',
      selectedsaleperson:'',
      selectedcustomername:'',
      selectedcustomerphone:'',
      selectedpaymentmethod1:'',
      selectedpaymentmethod2:'',
      selectedpaidvalue2:'',
      selectedpaidvalue1:'',
      howmanypaymentmethod:'',
      selectedunitprice:0,
    })


    if(value == "Phone"){
      this.getphonestocklist();
    }else if(value == "Accessories"){
      this.getaccessoriesstocklist();
    }else if(value == "Spare Part"){
      this.getsparepartstocklist();
    }
  }

  getaccessoriesstocklist = () =>{     
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectaccesoriesstocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
      
      console.log(json)
      this.setState({stockaccessorieslist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          if(json.employee[i].statusvalue == "Ready For Sell"){
          this.setState({stockaccessorieslist:[...this.state.stockaccessorieslist,
            { no:i+1,
              productname:json.employee[i].productname,   
              typevalue:json.employee[i].typevalue,     
              brandvalue:json.employee[i].brandvalue,     
              saleprice:json.employee[i].saleprice,
              quantityvalue:json.employee[i].quantityvalue,
              purchasedate:purchasedateshow,
              statusvalue:json.employee[i].statusvalue,
              productid:json.employee[i].Ref,
              title:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
            },            
          ]})
          }
          
        }
        // this.setState({loading:false})  
      }        
          
    else{
    this.setState({loading:false}) 
  console.log(json)
  }
    })

  }

  getsparepartstocklist = () =>{     
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectsparepartstocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)2
      if(json.success == 1){
      
      console.log(json)
      this.setState({stocksparepartlist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          if(json.employee[i].statusvalue == "Ready For Sell"){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          this.setState({stocksparepartlist:[...this.state.stocksparepartlist,
            { no:i+1,
              productname:json.employee[i].productname,   
              typevalue:json.employee[i].typevalue,     
              brandvalue:json.employee[i].brandvalue,     
              saleprice:json.employee[i].saleprice,
              quantityvalue:json.employee[i].quantityvalue,
              purchasedate:purchasedateshow,
              statusvalue:json.employee[i].statusvalue,
              productid:json.employee[i].Ref,
              title:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
            },            
          ]})
          }
          
        }
        // this.setState({loading:false})  
      }        
          
    else{
    this.setState({loading:false}) 
  console.log(json)
  }
    })

  }

  submitaddproductfunction = () =>{
    
  var errorornot = false;
 
  
  if(this.state.howmanypaymentmethod ==""){
    this.setState({alerterrormessage:'Please fill in How Many Payment Method '})
    errorornot=true;  
  }else{
   // this.setState({paymentmethodvalueerrorvalidation:false});
    //this.setState({paymentmethodvalueerrormessage:""});
    errorornot=false;  
  }

  if(this.state.selectedpaymentmethod1 ==""){
    this.setState({alerterrormessage:'Please fill in Payment Method '})
    errorornot=true;  
  }else{
   // this.setState({paymentmethodvalueerrorvalidation:false});
    //this.setState({paymentmethodvalueerrormessage:""});
    errorornot=false;  
  }
    

  if(this.state.selectedcategory == "Phone"){

    if(this.state.selectedimei ==""){
      this.setState({imei1errorvalidation:true});
      this.setState({imei1errormessage:"Please fill in the detail"});
      errorornot=true;  
     
    }else{
      this.setState({imei1errorvalidation:false});
      this.setState({imei1errormessage:""});
    }  

  }

  if(this.state.selectedproductname ==""){
    this.setState({productname1errorvalidation:true});
    this.setState({productname1errormessage:"Please fill in the detail"});
    errorornot=true;  
  }else{
    this.setState({productname1errorvalidation:false});
    this.setState({productname1errormessage:""});
  }


  if(this.state.selectedquantity ==0){
    this.setState({quantity1errorvalidation:true});
    this.setState({quantity1eerrormessage:"Please fill in the detail"});
    errorornot=true;  
   
  }else{
    this.setState({quantity1errorvalidation:false});
    this.setState({quantity1errormessage:""});
  }  


  if(this.state.selectedsalesdate ==""){
    this.setState({salesdate1errorvalidation:true});
    this.setState({salesdate1errormessage:"Please fill in the detail"});
    errorornot=true;  
   
  }else{
    this.setState({salesdate1errorvalidation:false});
    this.setState({salesdate1errormessage:""});
  }  


  if(this.state.selectedwarrantystartdate ==""){
    this.setState({warrantystart1errorvalidation:true});
    this.setState({warrantystart1errormessage:"Please fill in the detail"});
    errorornot=true;  
   
  }else{
    this.setState({warrantystart1errorvalidation:false});
    this.setState({warrantystart1errormessage:""});
  }  

  if(this.state.selectedwarrantyduration ==""){
    this.setState({warrantyend1errorvalidation:true});
    this.setState({warrantyend1errormessage:"Please fill in the detail"});
    errorornot=true; 
  }else{
    this.setState({warrantydurationerrorvalidation:false});
    this.setState({warrantydurationerrormessage:""});
  }

  if(errorornot == true){
    this.setState({alerterrormessage:'Something wrong, Please fill in all details'})
  }  

  if( errorornot==true){
    window.scrollTo(0, 0)
    this.setState({errorsubmit:true})
  }else{
      this.setState({aftersubmitstatus:3})
      var urlencoded = new URLSearchParams();      
       
        urlencoded.append("selectedcategory", this.state.selectedcategory);
        if(this.state.selectedcategory == "Phone"){
          urlencoded.append("selectedimei", this.state.selectedimei);
          urlencoded.append("selectedtype", '-');
          urlencoded.append("selectedbrand", '-');
        }else{
          urlencoded.append("selectedimei", '-');
          urlencoded.append("selectedtype", this.state.selectedtype);
          urlencoded.append("selectedbrand", this.state.selectedbrand);
        }
        urlencoded.append("selectedproductid", this.state.selectedproductid);      
        urlencoded.append("selectedunitprice", this.state.selectedunitprice);  
        urlencoded.append("selectedproductname", this.state.selectedproductnameforupload);
        urlencoded.append("selectedquantity", this.state.selectedquantity);
        urlencoded.append("selectedsubtotal", this.state.selectedsubtotal);

        var salesdate = new Date(this.state.selectedsalesdate).getTime()/1000;
        urlencoded.append("selectedsalesdate", salesdate);

      
        var warrantystart = new Date(this.state.selectedwarrantystartdate).getTime()/1000;
        var warrantystartdate = Moment(this.state.selectedwarrantystartdate, 'DD/MM/YYYY');
        urlencoded.append("selectedwarrantystart", warrantystart);

        if(this.state.selectedwarrantyduration == "1 Week"){
          var warrantyenddate = warrantystartdate.add(1, 'week')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "2 Weeks"){
          var warrantyenddate = warrantystartdate.add(2, 'week')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "3 Weeks"){
          var warrantyenddate = warrantystartdate.add(3, 'week')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration== "1 Month"){
          var warrantyenddate = warrantystartdate.add(1, 'M')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "2 Months"){
          var warrantyenddate = warrantystartdate.add(2, 'M')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "3 Months"){
          var warrantyenddate = warrantystartdate.add(3, 'M')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "1 Year"){
          var warrantyenddate = warrantystartdate.add(1, 'y')   
          var warrantyend = warrantyenddate.unix();     
          urlencoded.append("selectedwarrantyend", warrantyend);
        }else if(this.state.selectedwarrantyduration == "No Warranty"){
          var warrantyenddate = 0
          var warrantyend = warrantyenddate.unix();
          urlencoded.append("selectedwarrantyend", warrantyend);
        }
        
       
       
        
      }
 
      urlencoded.append("invoicenumber", this.state.selectedinvoicenumber);
      urlencoded.append("selectedinvoicedate", this.state.selectedinvoicedate);
      urlencoded.append("selectedsaleperson", this.state.selectedsaleperson);
      urlencoded.append("selectedcustomername", this.state.selectedcustomername);

      if(this.state.selectedphonenumber ==""){
        urlencoded.append("selectedphonenumber", '-')
      }else{       
        urlencoded.append("selectedphonenumber", this.state.selectedphonenumber)
      }
      
      if(this.state.howmanypaymentmethod == "Two"){
        urlencoded.append("selectedpaymentmethod1", this.state.selectedpaymentmethod1);
        urlencoded.append("selectedpaidvalue1", this.state.selectedpaidvalue1);
        urlencoded.append("selectedpaymentmethod2", this.state.selectedpaymentmethod2);
        urlencoded.append("selectedpaidvalue2", this.state.selectedpaidvalue2);
      }else{
        urlencoded.append("selectedpaymentmethod1", this.state.selectedpaymentmethod1);
        urlencoded.append("selectedpaidvalue1", 0);
        urlencoded.append("selectedpaymentmethod2", "");
        urlencoded.append("selectedpaidvalue2", 0);
      }
      
    //  console.log("selectedwarrantystart: " + this.state.selectedwarrantystartdate);
     // console.log("warrantystartdate: " + warrantystartdate);
    //  console.log("warrantyend: " + warrantyend);

     // console.log("selectedpaymentmethod1: " + this.state.selectedpaymentmethod1);
     // console.log("selectedpaidvalue1: " + this.state.selectedpaidvalue1);
     // console.log("selectedpaymentmethod2: " + this.state.selectedpaymentmethod2);
    //  console.log("selectedpaidvalue2: " + this.state.selectedpaidvalue2);
     // console.log("selectedinvoicedate: " + this.state.selectedinvoicedate);
      
     // return
      //return;
      urlencoded.append("selectedremark", this.state.selectedremark);
     
      let link = this.state.linkurl +'mode=admin-updateaddnewproduct'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        if(json.success == 1){
          
              //this.setState({aftersubmitstatus:1})
              this.setState({messageformessagebox:"Update Success"});
        
              this.setState({
                endDate:new Date,
                startDate:Moment(new Date).add(-1,'d'),
              })
              this.getsaleslist();
              this.togglemessagemodal("messageboxmodalonoff")
              this.toggleModaldetailinvoice("detailsalemodalonoff")
              this.toggleaddnewproduct("addnewproductmodalonoff")
              this.setState({loading:false}) 
        }
                 
           else{
             //fail submit
            this.setState({aftersubmitstatus:2})
            this.setState({loading:false}) 
            console.log(json)
        }
      
       })    
  }
  
  



  selectuser =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectuser'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({userlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({userlist:[...this.state.userlist,
                { no:i+1,
                  username:json.employee[i].name,
                  title:json.employee[i].name,
                  value:json.employee[i].name,
                },            
              ]})
               console.log(this.state.userlist)
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  componentDidMount(){
    this.getsaleslist();  
    this.selectuser();
   // this.getphonestocklist();
  }

  rendersaleslist() {
    
    return this.state.saleslist.map((data, index) => {
    
       const {no,ref,invoiceno,remark,saleperson,totalprice,invoicedate, totalnumberofproduct,customername,phonenumbervalue,paymentmethodvalue,paymentmethod1value,paymentmethod2,paymentmethod2value} = data 
       return (
    
            
            <tbody>
              
             <tr>
   
            <td>{no})</td>
              <td >{invoiceno}</td>
              <td>{invoicedate}</td> 
              <td >{saleperson}</td>
              <td >{totalnumberofproduct}</td>        
                  <td>{totalprice}</td> 
                 
                  <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                      href="#pablo"
                      onClick={()=>this.toggleModaldetailinvoiceeditdetail("editdetailinvoice",invoiceno,remark,totalprice,invoicedate,totalnumberofproduct,saleperson,customername,phonenumbervalue,paymentmethodvalue,paymentmethod1value,paymentmethod2,paymentmethod2value)}
                    >
                      Edit Detail
                    </DropdownItem> 
                    <DropdownItem
                      href="#pablo"
                      onClick={()=>this.toggleModaldetailinvoice("detailsalemodalonoff",invoiceno,saleperson,totalprice,invoicedate,totalnumberofproduct)}
                    >
                      Edit Product
                    </DropdownItem>                   
                   
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.toggledeletemodal("deletemodalonoff",invoiceno)}
                    >
                     Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
                  </tr>    
                 
                </tbody>

       
       
   
       )
    })
  } 

  renderproductsaletable() {
      
    return this.state.producttablelist.map((data, index) => {
    
       const {no,ref,productname,type,imei,category,brand,unitprice, quantity,salesdate,warrantystart,warrantyend} = data 
       var substotal = quantity * unitprice
       var substotalfinal = substotal.toFixed(2)

   
       return (
    
            
            <tbody>
              
             <tr>
   
            <td>{no})</td>
              <td >{productname}</td>
              {category == 'Phone' ?
              <td>{imei}</td> 
              :
              <td>{type}</td> 
              }
              
              <td >{brand}</td>
              <td >{unitprice}</td>        
                  <td>{quantity}</td> 
                  <td>{substotalfinal}</td> 
                  <td>{salesdate}</td> 
                  <td>{warrantystart}</td> 
                  <td>{warrantyend}</td> 
                  <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem
                      href="#pablo"
                       onClick={()=>this.toggledeleteproductmodal("deletemodalproductonoff",ref)}
                    >
                      Delete Product
                    </DropdownItem>                     
                   
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
                  </tr>    
                 
                </tbody>

       
       
   
       )
    })
  }

  toggledeletemodal = (state,invoicenumber) =>{
    this.setState({
      [state]: !this.state[state],
      selectedjobid:invoicenumber,
    });
  }

  toggledeleteproductmodal = (state,ref) =>{
    this.setState({
      [state]: !this.state[state],
      selectedref:ref,
    });
  }

  toggleaddnewproduct= (state,selectedinvoicenumber) =>{
    this.setState({
      [state]: !this.state[state],
      invoicecnumber:selectedinvoicenumber,
      selectedproductnameforupload:'',
      selectedquantity:'',
      selectedremark:'',
      selectedsubtotal:'',
      selectedunitprice:'',
      selectedproductname:'',
      selectedcategory:'',
      selectedpaymentmethod1:'',
      selectedsaleperson:'',
      selectedsalesdate:'',
      selectedwarrantyduration:'',
      selectedwarrantystartdate:'',
      selectedimei:'',
      selectedcustomername:'',
      selectedcustomerphone:'',
      selectedpaymentmethod2:'',
      selectedpaidvalue1:'',
      selectedpaidvalue2:'',


    });
  }

  togglemessagemodal = state =>{
    this.setState({
      [state]: !this.state[state]
    });
  }

  toggleModaldetailinvoice = (state,invoicenumber,salesperson,totalprice,invoicedate,totalnumberofproduct) => {
    this.setState({
      [state]: !this.state[state],
      selectedinvoicenumber:invoicenumber,      
      selectedtotalprice:totalprice,
      selectedinvoicedate:invoicedate,
      selectedtotalnumberofproduct:totalnumberofproduct,
    });
    if(!this.state[state] == true){
      this.gettdettailinvoicetable(invoicenumber);
    }
  };

  toggleModaldetailinvoiceeditdetail = (state,invoicenumber,remark,totalprice,invoicedate,totalnumberofproduct,saleperson,customername,phonenumbervalue,paymentmethodvalue,paymentmethod1value,paymentmethod2,paymentmethod2value) => {
 
    this.setState({
      [state]: !this.state[state],
      selectedinvoicenumber:invoicenumber,
      selectedtotalprice:totalprice,
      selectedinvoicedate:invoicedate,
      selectedtotalnumberofproduct:totalnumberofproduct,
      selectedsaleperson:saleperson,
      selectedcustomername:customername,
      selectedphonenumber:phonenumbervalue,
      selectedpaymentmethod1:paymentmethodvalue,
      selectedpaymentmethod2:paymentmethod2,
      selectedpaidvalue1:paymentmethod1value,
      selectedpaidvalue2:paymentmethod2value,
      selectedremark:remark,

    });
    if(paymentmethod2 == ""){
      this.setState({howmanypaymentmethod:"One"})
    }else{
      this.setState({howmanypaymentmethod:"Two"})
    }
    
    console.log("phonenumbervalue:" +phonenumbervalue)
  };

  gettdettailinvoicetable = (invoicenumber) =>{
    
   var urlencoded = new URLSearchParams();
   console.log("selectedinvoicenumber: " +invoicenumber )
   urlencoded.append("invoicenumber", invoicenumber);

   let link = this.state.linkurl +'mode=admin-selectedinvoicenumberdata'
   fetch(link, {
     method: 'POST', 
     headers: {
       'Accept': 'application/json',
       'Content-Type': 'application/x-www-form-urlencoded',
     },
     body:urlencoded
   })
   .then(response => response.json())
   .then(json => {
     console.log(json.success)
     //console.log(json)
     if(json.success == 1){
      
      console.log(json)
      this.setState({producttablelist:[]});   
       var lengthemployee = Object.keys(json.employee).length;
       for(let i=0;i<lengthemployee;i++){
         var invoicedatetoshow = Moment(json.employee[i].invoicedate*1000).format('DD/MM/YYYY')
         var salesdatetoshow = Moment(json.employee[i].salesdate*1000).format('DD/MM/YYYY')
         var warrantystarttoshow = Moment(json.employee[i].warrantystart*1000).format('DD/MM/YYYY')
         var warrantyendtoshow = Moment(json.employee[i].warrantyend*1000).format('DD/MM/YYYY')
          this.setState({producttablelist:[...this.state.producttablelist,
            { no:i+1,
              ref:json.employee[i].Ref,
              invoiceno:json.employee[i].invoicenumber,   
              productname:json.employee[i].productname,     
              imei:json.employee[i].imeivalue,
              category:json.employee[i].category,
              brand:json.employee[i].brandvalue,
              type:json.employee[i].typevalue,
              unitprice:json.employee[i].unitprice,
              quantity:json.employee[i].quantity,      
              salesdate:salesdatetoshow,     
              warrantystart:warrantystarttoshow,     
              warrantyend:warrantyendtoshow,     
    
            },            
          ]})
        
         
        }
       // this.setState({loading:false})  
      }        
         
   else{
    this.setState({producttablelist:[]}); 
    this.setState({loading:false}) 
  console.log(json)
}
    })
  }

  cancelledsearchbuttonclick = () =>{
    if(this.state.tabs == 1){
     this.setState({searchtextvalue:""})
     this.setState({currentcompletedpagenumber:1})
    
     // this.getaccessoriesstocklist();    
     
    }else if( this.state.tabs ==2){
     this.setState({searchtextvalue:""})
     if(this.state.tabsimportexport == 1){
     // this.getorderlistapi("Import");
     }else{
      //this.getorderlistapi("Export");
     }
    }else if( this.state.tabs ==3){
      this.setState({searchtextvalue:""})
       // this.getsparepartstocklist();
       
     }      
  }

  nextpaginationfunction = (e) =>{
    if(this.state.completelist.length/100 <= this.state.currentcompletedpagenumber){
      
    }else{
     this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber +1})
    }
  }
 
  previouspaginationfunction = (e) =>{
   if(this.state.currentcompletedpagenumber== 1){
     
   }else{
    this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber - 1})
   }
  }

  searchfunctionsales = (value) =>{   
    
    this.setState({
      startDate:0,
      endDate:0,
    })
    this.setState({searchtextvalue:value})

    console.log("searchvalue: " + value);
      this.setState({loading:true})

      var urlencoded = new URLSearchParams();    
     urlencoded.append("searchvalue",value);
    // urlencoded.append("category","Phone");
    let link = this.state.linkurl +'mode=admin-searchsalelist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({saleslist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var invoicedatetoshow = Moment(json.employee[i].invoicedate*1000).format('DD/MM/YYYY')
           this.setState({saleslist:[...this.state.saleslist,
             { no:i+1,
              ref:json.employee[i].Ref,
               invoiceno:json.employee[i].invoicenumber,   
               salesperson:json.employee[i].salesperson,             
               totalprice:json.employee[i].totalprice,
               invoicedate:invoicedatetoshow, 
               totalnumberofproduct:json.employee[i].totalnumberofproduct,            
             },            
           ]})
         
          
         }
         this.setState({loading:false})  
       }        
          
    else{
     this.setState({saleslist:[]}); 
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
   
  }

  deletewholeinvoice = (invoicenumber) =>{
    //console.log("delete called")
   
    var urlencoded = new URLSearchParams();
    urlencoded.append("invoicenumber", invoicenumber);
    urlencoded.append("userref", this.state.userid);
    let link = this.state.linkurl +'mode=admin-deletesaleinvoice'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Delete Success"});
          this.setState({
            endDate:new Date,
            startDate:Moment(new Date).add(-1,'d'),
          })
          this.getsaleslist();
          this.togglemessagemodal("messageboxmodalonoff")
          this.toggledeletemodal("deletemodalonoff",0)
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:"Delete Fail Please try again"});
      this.togglemessagemodal("messageboxmodalonoff")
      this.toggledeletemodal("deletemodalonoff",0)
      this.setState({loading:false}) 
      console.log(json)
      }
     })
  }

  deleteproductofinvoice = (id) =>{
    //console.log("delete called")   
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("userref", this.state.userid);
    urlencoded.append("invoicenumber", this.state.selectedinvoicenumber);
    let link = this.state.linkurl +'mode=admin-deleteproductinvoice'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Delete Success"});
          this.setState({
            endDate:new Date,
            startDate:Moment(new Date).add(-1,'d'),
          })
          this.getsaleslist();
          this.togglemessagemodal("messageboxmodalonoff")
          this.toggledeleteproductmodal("deletemodalproductonoff",0)
          this.toggledeleteproductmodal("detailsalemodalonoff",0)
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:"Delete Fail Please try again"});
      this.togglemessagemodal("messageboxmodalonoff")
      this.toggledeleteproductmodal("deletemodalproductonoff",0)
      this.setState({loading:false}) 
      console.log(json)
      }
     })
  }

  updateeditdetailsales = (invoicenumber) =>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("invoicenumber", invoicenumber);
    urlencoded.append("userref", this.state.userid);
    urlencoded.append("selectedsaleperson", this.state.selectedsaleperson);
    urlencoded.append("selectedcustomername", this.state.selectedcustomername);
    urlencoded.append("selectedphonenumber", this.state.selectedphonenumber);
    if(this.state.howmanypaymentmethod == "Two"){
      urlencoded.append("selectedpaymentmethod1", this.state.selectedpaymentmethod1);
      urlencoded.append("selectedpaymentmethod2", this.state.selectedpaymentmethod2);
      urlencoded.append("selectedpaidvalue1", this.state.selectedpaidvalue1);
      urlencoded.append("selectedpaidvalue2", this.state.selectedpaidvalue2);
    }else{
      urlencoded.append("selectedpaymentmethod1", this.state.selectedpaymentmethod1);
      urlencoded.append("selectedpaymentmethod2", "");
      urlencoded.append("selectedpaidvalue1", 0);
      urlencoded.append("selectedpaidvalue2", 0);
    }
    urlencoded.append("selectedremark", this.state.selectedremark);

    let link = this.state.linkurl +'mode=admin-updateeditdetailsales'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log("success: " + json.success)
      console.log("message: "+ json.message)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Update Success"});
        
          this.setState({
            endDate:new Date,
            startDate:Moment(new Date).add(-1,'d'),
          })
          this.getsaleslist();
          this.togglemessagemodal("messageboxmodalonoff")
          this.toggleModaldetailinvoiceeditdetail("editdetailinvoice",0)
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:json.message});
      this.togglemessagemodal("messageboxmodalonoff")
      this.toggleModaldetailinvoiceeditdetail("editdetailinvoice",0)
      this.setState({loading:false}) 
      console.log(json)
    }
     })
  }

  selectedquantityfunction = (value) =>{
      this.setState({
        selectedquantity:value,
        selectedsubtotal:value*this.state.selectedunitprice,
      })
  }

  selectedimeifunction = (value)=>{
    this.setState({
      selectedimei:value
    })
    var lengthofphonelist = Object.keys(this.state.stockphonelist).length;
    for(let i=0;i<lengthofphonelist;i++){
      if(this.state.stockphonelist[i].imei == value){
        this.setState({
        selectedproductname:this.state.stockphonelist[i].productname ,        
        selectedunitprice:this.state.stockphonelist[i].saleprice,
        selectedproductid:this.state.stockphonelist[i].productid,
        selectedimei:this.state.stockphonelist[i].imei,
       
        })
      }
     
        }  
  }

  selectedproductnamefunction = (value) =>{
    this.setState({
      selectedproductname:value
    })

    if(this.state.selectedcategory == "Accessories"){
      var lengthofaccessorieslist = Object.keys(this.state.stockaccessorieslist).length;
      for(let i=0;i<lengthofaccessorieslist;i++){
  
          var nametoshow = this.state.stockaccessorieslist[i].productname + '---' +this.state.stockaccessorieslist[i].typevalue + '---' +this.state.stockaccessorieslist[i].brandvalue +'---'+ this.state.stockaccessorieslist[i].productid
         
          if(nametoshow == value){
           this.setState({
            selectedunitprice:this.state.stockaccessorieslist[i].saleprice,       
            selectedproductid:this.state.stockaccessorieslist[i].productid,
            selectedtype:this.state.stockaccessorieslist[i].typevalue,
            selectedbrand:this.state.stockaccessorieslist[i].brandvalue,
            selectedproductnameforupload:this.state.stockaccessorieslist[i].productname,
            selectedquantitymax:this.state.stockaccessorieslist[i].quantityvalue,

           })
         }
        
           }  
    }else{
      var lengthofaccessorieslist = Object.keys(this.state.stocksparepartlist).length;
      for(let i=0;i<lengthofaccessorieslist;i++){
  
          var nametoshow = this.state.stocksparepartlist[i].productname + '---' +this.state.stocksparepartlist[i].typevalue + '---' +this.state.stocksparepartlist[i].brandvalue +'---'+ this.state.stocksparepartlist[i].productid
         
          if(nametoshow == value){
           this.setState({
            selectedunitprice:this.state.stocksparepartlist[i].saleprice,      
            selectedproductid:this.state.stocksparepartlist[i].productid,
            selectedtype:this.state.stocksparepartlist[i].typevalue,
            selectedbrand:this.state.stocksparepartlist[i].brandvalue, 
            selectedproductnameforupload:this.state.stocksparepartlist[i].productname,
            selectedquantitymax:this.state.stocksparepartlist[i].quantityvalue,
           })
         }
        }  
    }
    //"select * from stocktable where productname='Housing---Black---Normal---17' and typevalue='' and brandvalue='' and statusvalue='Ready For Sell'"
  }

  render() {
    return (
      <>
        <Header />
        {/* Page contentas */}
        <Container className=" mt--6" fluid>
          {/* Table */}
          <Row>
            <div className=" col-12 ">
        
            </div>
            <div className=" col-12 ">
              <Card className=" shadow  ">
              

              
                  <Row className="marginleftcss">                
                    <Col md="0.5" className="margintopcss">
                    <span className="margintopcss">From: </span>
                    </Col>
                    <Col md={2}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "From Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ startDate: e })  }
                  value={this.state.startDate}
                />
              </InputGroup>
            </FormGroup>
          </Col>
                    <Col md="0.5" className="margintopcss">
                    <span className="margintopcss">To: </span>
                    </Col>
                    <Col md={2}>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "To Date"
                  }}
                  dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                  renderDay={(props, currentDate, selectedDate) => {
                    let classes = props.className;
                    if (
                      this.state.startDate &&
                      this.state.endDate &&
                      this.state.startDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " start-date";
                    } else if (
                      this.state.startDate &&
                      this.state.endDate &&
                      new Date(this.state.startDate._d + "") <
                        new Date(currentDate._d + "") &&
                      new Date(this.state.endDate._d + "") >
                        new Date(currentDate._d + "")
                    ) {
                      classes += " middle-date";
                    } else if (
                      this.state.endDate &&
                      this.state.endDate._d + "" === currentDate._d + ""
                    ) {
                      classes += " end-date";
                    }
                    return (
                      <td {...props} className={classes}>
                        {currentDate.date()}
                      </td>
                    );
                  }}
                  
                  closeOnSelect={true}
                  onChange={e =>this.setState({ endDate: e })}
                  value={this.state.endDate}
                />
                {/* , this.setState({ endDate: e }) */}
              </InputGroup>
            </FormGroup>
          </Col>
                    <Col md={2}>
                    <Button  className={classnames("mb-sm-3 mb-md-0")} color="blue" type="button" style={{marginLeft:-10}} onClick={(e) => this.getsaleslist()}>
                         Search
                      </Button>
                    </Col>
                    
                    <Col md="5 form-inline  d-md-flex">


                        {/* Searchbar is here */}
                          {/* till here */}
                          {window.innerWidth <= 760 ?
                        <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>                   
                              <i className="fas fa-search" />                     
                            </InputGroupText>
                          </InputGroupAddon>

                            <Input
                              placeholder="Search" 
                              value={this.state.searchtextvalue}
                              onChange = {(event,value) => this.searchfunctionsales(value)}
                              type="text"
                             // onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                            />
                          
                        {this.state.searchtextvalue == "" ?
                        null:
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                          
                        <Button className="buttonsearch" onClick={(e)=>this.searchfunctionsales(this.state.searchtextvalue)}>Search</Button >
                          
                        </InputGroupText>
                        </InputGroupAddon>
                        }
                            
                            
                          
                        </InputGroup>
                        :
                        <InputGroup className="col-6 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>

                          <i className="fas fa-search" />
                          
                        </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          placeholder="Search" 
                          value={this.state.searchtextvalue}
                          onChange = {(event) => this.searchfunctionsales(event.target.value)}
                          type="text"
                        //  onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                        />

                        {this.state.searchtextvalue == "" ?
                        null:
                        <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                                      
                        <span className="buttonsearch" onClick={(e)=>this.searchfunctionsales(this.state.searchtextvalue)}>Search</span >

                        </InputGroupText>
                        </InputGroupAddon>
                        }
                        
                          

                        </InputGroup>
                        }



                        </Col>

                 </Row>
          

         
             
            
         
            
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center mt-5"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
              :
              <CardBody >
               
                <TabContent  activeTab={"tabs" + this.state.tabs + "andtabsimportexport"+ this.state.tabsimportexport}>
                  <TabPane tabId="tabs1andtabsimportexport1">
                
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                  
                 <tr>
                   <th>No.</th>
                   <th >Invoice No</th>
                   <th >Invoice Date</th>
                   <th  >Sales Person</th>    
                   <th  >Total Quantity Product</th>       
                   <th  >Total Price</th>  
                   {/* <th scope="col">Remark</th> */}
                   <th >Action</th>
                   <th scope="col" />
                 </tr>
               </thead>
                   
                   {this.rendersaleslist()}                       
                   </Table>
                  
             
                  </TabPane>
                   
                </TabContent>
              
               
              </CardBody>
                  }

                  
             
                
              </Card>
            </div>
          </Row>
          
        </Container>
        
        {/*See Detail Modal Accessories*/}
      <Modal
          className="modal-dialog-centered"
          size="lg"
          isOpen={this.state.detailsalemodalonoff}
          toggle={() => this.setState({detailsalemodalonoff:false})}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="detailsalemodalonoff">
              Edit Product
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailsalemodalonoff:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">   
          <h5>
         Invoice No: {this.state.selectedinvoicenumber}
            </h5>
            <h5>
         Invoice Date: {this.state.selectedinvoicedate}
            </h5>        
            <h5>
         Total Quantity: {this.state.selectedtotalnumberofproduct}
            </h5> 
            <h5>
         Total Price: {this.state.selectedtotalprice}
            </h5>
        
            <TabContent  activeTab={"tabs" + this.state.tabs + "andtabsimportexport"+ this.state.tabsimportexport}>
                  <TabPane tabId="tabs1andtabsimportexport1">
                
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                  
                 <tr>
                   <th>No.</th>
                   <th >Product Name</th>
                   <th >Type/IMEI</th>
                   <th  >Brand</th>    
                   <th  >Unit Price</th>       
                   <th  >Quantity</th> 
                   <th  >Subtotal</th>
                   <th  >Sales Date</th>   
                   <th  >Warranty Start</th>   
                   <th  >Warranty End</th>  
                   {/* <th scope="col">Remark</th> */}
                   <th >Action</th>
                   <th scope="col" />
                 </tr>
               </thead>
                   
                   {this.renderproductsaletable()}                       
                   </Table>
                  
             
                  </TabPane>
                   
                </TabContent>
              


            
         </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({detailsalemodalonoff:false})}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={(e)=>this.toggleaddnewproduct("addnewproductmodalonoff",this.state.selectedinvoicenumber)}>
              Add Product
            </Button>
          </div>
        </Modal>
     

         
         {/*Edit editdetailinvoice*/}
      <Modal
          className="modal-dialog-centered"
          isOpen={this.state.editdetailinvoice}
          toggle={() => this.setState({editdetailinvoice:false})}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="editdetailinvoice">
              Edit Detail
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editdetailinvoice:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">   
          
          <h5>
         Invoice No: {this.state.selectedinvoicenumber}
            </h5>
            <h5>
         Invoice Date: {this.state.selectedinvoicedate}
            </h5>        
            <h5>
         Total Quantity: {this.state.selectedtotalnumberofproduct}
            </h5> 
            <h5>
         Total Price: {this.state.selectedtotalprice}
            </h5> 


            <h5>
         Sales Person:
            </h5> 
            <Autocomplete
                        options={this.state.userlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedsaleperson:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedsaleperson} variant="outlined"  />}  
                       disableClearable={true}
                      />     
            <h5>
         Customer Name:
            </h5>   
            <Input
                          type="text"
                          onChange={e => this.setState({selectedcustomername: e.target.value})}
                          value={this.state.selectedcustomername}
                          
                        />

            <h5>
         Customer Phone:
            </h5>
            <Input
                          type="text"
                          onChange={e => this.setState({selectedphonenumber: e.target.value})}
                          value={this.state.selectedphonenumber}
                          
                        />

            <h5>
         How Many Payment Method:
            </h5> 
            <Autocomplete
                        options={[{title:"One"},{title:"Two"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({howmanypaymentmethod:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.howmanypaymentmethod} variant="outlined"  />}  
                       disableClearable={true}
                      />  
                      
            <h5>
         Payment Method 1:
            </h5> 
            <Autocomplete
                        options={[{title:"Online Banking"},{title:"Credit Card"},{title:"Cash"},{title:"Ewallet"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedpaymentmethod1:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedpaymentmethod1} variant="outlined"  />}  
                       disableClearable={true}
                      /> 


            {this.state.howmanypaymentmethod == "Two" ?
            <div>
             <h5>
             Paid Value 1:
                </h5>     
                <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({selectedpaidvalue1: e.target.value})}
                          value={this.state.selectedpaidvalue1}
                          
                        />
                <h5>
             Payment Method 2:
                </h5> 
                <Autocomplete
                        options={[{title:"Online Banking"},{title:"Credit Card"},{title:"Cash"},{title:"Ewallet"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedpaymentmethod2:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedpaymentmethod2} variant="outlined"  />}  
                       disableClearable={true}
                      />  
                <h5>
                Paid Value 2:
                </h5>  
                <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({selectedpaidvalue2: e.target.value})}
                          value={this.state.selectedpaidvalue2}
                          
                        />
                </div>
            :
            null
            }
           
            <h5>
         Remark:
            </h5>       
            <Input
                          type="text"
                          onChange={e => this.setState({selectedremark: e.target.value})}
                          value={this.state.selectedremark}
                          
                        />

            
         </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editdetailinvoice:false})}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.updateeditdetailsales.bind(this,this.state.selectedinvoicenumber)}>
              Submit Edit
            </Button>
          </div>
        </Modal>
     

             
        {/*delete confirm*/}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.deletemodalonoff}
          toggle={() => this.toggledeletemodal("deletemodalonoff")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="deletemodalonoff">
              Confirm
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggledeletemodal("deletemodalonoff")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">Are you sure you want to delete this invoice?</div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggledeletemodal("deletemodalonoff")}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.deletewholeinvoice.bind(this,this.state.selectedjobid)}>
              Confirm
            </Button>
          </div>
        </Modal>
     

          
        {/*delete confirm product*/}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.deletemodalproductonoff}
          toggle={() => this.toggledeleteproductmodal("deletemodalproductonoff")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="deletemodalproductonoff">
              Confirm
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggledeleteproductmodal("deletemodalproductonoff")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">Are you sure you want to delete this product?</div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggledeleteproductmodal("deletemodalproductonoff")}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.deleteproductofinvoice.bind(this,this.state.selectedref)}>
              Confirm
            </Button>
          </div>
        </Modal>
     

      {/*Message*/}
      <Modal
          className="modal-dialog-centered"
          isOpen={this.state.messageboxmodalonoff}
          toggle={() => this.togglemessagemodal("messageboxmodalonoff")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="messageboxmodalonoff">
              Message
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.togglemessagemodal("messageboxmodalonoff")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">{this.state.messageformessagebox}</div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.togglemessagemodal("messageboxmodalonoff")}
            >
              Close
            </Button>
         
          </div>
        </Modal>
     

     {/*Add New product*/}
     <Modal
          className="modal-dialog-centered"
          isOpen={this.state.addnewproductmodalonoff}
          toggle={() => this.setState({addnewproductmodalonoff:false})}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="addnewproductmodalonoff">
              Edit Detail
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({addnewproductmodalonoff:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">   
          
          <h5>
        Category: 
            </h5>
            <Autocomplete
                        options={[{title:"Phone"},{title:"Accessories"},{title:"Spare Part"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.selectedcategoryfunction(value)}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedcategory} variant="outlined"  />}  
                       disableClearable={true}
                      />   


            {this.state.selectedcategory == "Phone" ?
            <div>
        <h5>
        IMEI:
          </h5>  
          <Autocomplete
                        options={this.state.stockphonelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.selectedimeifunction(value)}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedimei} variant="outlined"  />}  
                       disableClearable={true}
                      />   
          <h5>
          Product Name:
            </h5> 
            <Input
                          type="text"
                          onChange={e => this.setState({selectedproductname: e.target.value})}
                          value={this.state.selectedproductname}
                          disabled
                        />

            <h5>
          Unit Price:
            </h5> 
            <Input
                          type="text"
                          onChange={e => this.setState({selectedunitprice: e.target.value})}
                          value={this.state.selectedunitprice}
                          disabled
                        />
            <h5>
          Quantity:
            </h5> 
            <Input
                          type="number"
                          min="0"
                          max="1"
                          onChange={e => this.selectedquantityfunction(e.target.value)}
                          value={this.state.selectedquantity}
                          disabled
                        />
            <h5>
          Subtotal:
            </h5> 
            <Input
                          type="text"
                          onChange={e => this.setState({selectedsubtotal: e.target.value})}
                          value={this.state.selectedsubtotal}
                          disabled
                        />
            <h5>
          Sales Date:
            </h5> 
            <ReactDatetime
                     inputProps={{
                       placeholder: "Sales Date",                  
                     }}    
                   closeOnSelect={true}                  
                     value={this.state.selectedsalesdate}
                     onChange={(e) => this.setState({selectedsalesdate:e})}                           
                     timeFormat={false}
                     dateFormat="DD/MM/YYYY"
                   />


            <h5>
          Warranty Start Date:
            </h5> 
            <ReactDatetime
                     inputProps={{
                       placeholder: "Warranty Start Date",                  
                     }}    
                   closeOnSelect={true}                  
                     value={this.state.selectedwarrantystartdate}
                     onChange={(e) => this.setState({selectedwarrantystartdate:e})}                           
                     timeFormat={false}
                     dateFormat="DD/MM/YYYY"
                   />

            <h5>
          Warranty Duration:
            </h5>
            <Autocomplete
                        options={[{title:'1 Week'},{title:'2 Weeks'},{title:'3 Weeks'},{title:'1 Month'},{title:'2 Months'},{title:'3 Months'},{title:'1 Year'},{title:'2 Years'},{title:'3 Years'},{title:'No Warranty'}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedwarrantyduration:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedwarrantyduration} variant="outlined"  />}  
                       disableClearable={true}
                      />  
            </div>
            :
            this.state.selectedcategory == "Accessories" || this.state.selectedcategory == "Spare Part" ?
            <div>   
              {this.state.selectedcategory == "Accessories" ?
              <div>
                  <h5>
                 Product Name:
                   </h5> 
                   <Autocomplete
                               options={this.state.stockaccessorieslist}
                               getOptionLabel={(option) => option.title}                       
                               style={{ flex:1,display:"block" }}
                               onInputChange={(event,value) =>this.selectedproductnamefunction(value)}
                               renderInput={(params) => <TextField {...params} label={this.state.selectedproductname} variant="outlined"  />}  
                              disableClearable={true}
                             />  
              </div>
               
              :
              <div>
              <h5>
              Product Name:
                </h5> 
                <Autocomplete
                            options={this.state.stocksparepartlist}
                            getOptionLabel={(option) => option.title}                       
                            style={{ flex:1,display:"block" }}
                            onInputChange={(event,value) =>this.selectedproductnamefunction(value)}
                            renderInput={(params) => <TextField {...params} label={this.state.selectedproductname} variant="outlined"  />}  
                           disableClearable={true}
                          />  
                             </div>
              }    
        

            <h5>
          Unit Price:
            </h5> 
            <Input
                          type="text"
                          onChange={e => this.setState({selectedunitprice: e.target.value})}
                          value={this.state.selectedunitprice}
                          disabled
                        />

            <h5>
          Quantity:
            </h5> 
            <Input
                          type="number"
                          min="0"
                          max={this.state.selectedquantitymax}
                          onChange={e => this.selectedquantityfunction(e.target.value)}
                          value={this.state.selectedquantity}
                          
                        />
            
            <h5>
          Subtotal:
            </h5> 
            <Input
                          type="text"
                          onChange={e => this.setState({selectedsubtotal: e.target.value})}
                          value={this.state.selectedsubtotal}
                          disabled
                        />


            <h5>
          Sales Date:
            </h5> 
            <ReactDatetime
                     inputProps={{
                       placeholder: "Sales Date",                  
                     }}    
                   closeOnSelect={true}                  
                     value={this.state.selectedsalesdate}
                     onChange={(e) => this.setState({selectedsalesdate:e})}                           
                     timeFormat={false}
                     dateFormat="DD/MM/YYYY"
                   />

            <h5>
          Warranty Start Date:
            </h5> 
            <ReactDatetime
                     inputProps={{
                       placeholder: "Warranty Start Date",                  
                     }}    
                   closeOnSelect={true}                  
                     value={this.state.selectedwarrantystartdate}
                     onChange={(e) => this.setState({selectedwarrantystartdate:e})}                           
                     timeFormat={false}
                     dateFormat="DD/MM/YYYY"
                   />

            <h5>
          Warranty Duration:
            </h5>
            <Autocomplete
                        options={[{title:'1 Week'},{title:'2 Weeks'},{title:'3 Weeks'},{title:'1 Month'},{title:'2 Months'},{title:'3 Months'},{title:'1 Year'},{title:'2 Years'},{title:'3 Years'},{title:'No Warranty'}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({selectedwarrantyduration:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.selectedwarrantyduration} variant="outlined"  />}  
                       disableClearable={true}
                      />  
            </div> 
           :
           null
            }



            {this.state.selectedcategory == "" || this.state.selectedcategory == null ?
            null
          :
          <div>    <h5>
          Sales Person:
             </h5> 
             <Autocomplete
                         options={this.state.userlist}
                         getOptionLabel={(option) => option.title}                       
                         style={{ flex:1,display:"block" }}
                         onInputChange={(event,value) =>this.setState({selectedsaleperson:value})}
                         renderInput={(params) => <TextField {...params} label={this.state.selectedsaleperson} variant="outlined"  />}  
                        disableClearable={true}
                       />     
             <h5>
          Customer Name:
             </h5>   
             <Input
                           type="text"
                           onChange={e => this.setState({selectedcustomername: e.target.value})}
                           value={this.state.selectedcustomername}
                           
                         />
 
             <h5>
          Customer Phone:
             </h5>
             <Input
                           type="text"
                           onChange={e => this.setState({selectedphonenumber: e.target.value})}
                           value={this.state.selectedphonenumber}
                           
                         />
 
             <h5>
          How Many Payment Method:
             </h5> 
             <Autocomplete
                         options={[{title:"One"},{title:"Two"}]}
                         getOptionLabel={(option) => option.title}                       
                         style={{ flex:1,display:"block" }}
                         onInputChange={(event,value) =>this.setState({howmanypaymentmethod:value})}
                         renderInput={(params) => <TextField {...params} label={this.state.howmanypaymentmethod} variant="outlined"  />}  
                        disableClearable={true}
                       />  
                       
             <h5>
          Payment Method 1:
             </h5> 
             <Autocomplete
                         options={[{title:"Online Banking"},{title:"Credit Card"},{title:"Cash"},{title:"Ewallet"}]}
                         getOptionLabel={(option) => option.title}                       
                         style={{ flex:1,display:"block" }}
                         onInputChange={(event,value) =>this.setState({selectedpaymentmethod1:value})}
                         renderInput={(params) => <TextField {...params} label={this.state.selectedpaymentmethod1} variant="outlined"  />}  
                        disableClearable={true}
                       /> 
 
 
             {this.state.howmanypaymentmethod == "Two" ?
             <div>
              <h5>
              Paid Value 1:
                 </h5>     
                 <Input
                           type="number"
                           min="0"
                           onChange={e => this.setState({selectedpaidvalue1: e.target.value})}
                           value={this.state.selectedpaidvalue1}
                           
                         />
                 <h5>
              Payment Method 2:
                 </h5> 
                 <Autocomplete
                         options={[{title:"Online Banking"},{title:"Credit Card"},{title:"Cash"},{title:"Ewallet"}]}
                         getOptionLabel={(option) => option.title}                       
                         style={{ flex:1,display:"block" }}
                         onInputChange={(event,value) =>this.setState({selectedpaymentmethod2:value})}
                         renderInput={(params) => <TextField {...params} label={this.state.selectedpaymentmethod2} variant="outlined"  />}  
                        disableClearable={true}
                       />  
                 <h5>
                 Paid Value 2:
                 </h5>  
                 <Input
                           type="number"
                           min="0"
                           onChange={e => this.setState({selectedpaidvalue2: e.target.value})}
                           value={this.state.selectedpaidvalue2}
                           
                         />
                 </div>
             :
             null
             }
            
             <h5>
          Remark:
             </h5>       
             <Input
                           type="text"
                           onChange={e => this.setState({selectedremark: e.target.value})}
                           value={this.state.selectedremark}
                           
                         /></div>
         
          }
         

            
         </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({addnewproductmodalonoff:false})}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.submitaddproductfunction.bind(this,this.state.selectedinvoicenumber)}>
              Submit Edit
            </Button>
          </div>
        </Modal>
     



      </>
    );
  }

  }


export default Sales;
