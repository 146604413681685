/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

const themecolor = "#92e0e1"
const secondarycolor = "#5170c9"


class AdminNavbar extends React.Component {

  state={
    level:sessionStorage.getItem('level'),
    name:sessionStorage.getItem('name'),
    commission:sessionStorage.getItem('commission'),
    linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",   

  }

  
  
  render() {
   // const imgurl = this.state.imageurllink + sessionStorage.getItem('Profilepictureurl')
   const imgurl = this.state.imageurllink + sessionStorage.getItem('Profilepictureurl')
    console.log(imgurl)
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="ml-3 h2 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {this.props.brandText}
            </Link>
            {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                {window.location.href == "http://crm.mewahtrans.com.my/admin/user-profile" ?
                  <DropdownToggle className="pr-0" nav>
                  {/* <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src = {imgurl}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block"> */}
                      <span className="mb-0 text-sm font-weight-bold">
                        Settings
                      </span>
                    {/* </Media>
                  </Media> */}
                </DropdownToggle>
                :
                <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center ">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      width="70"
                      height="40"
                      //src = {imgurl}
                      src={require('./../../assets/img/brand/mzprofile.png')}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {this.state.name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              
                }
               

                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                 
               

                  <DropdownItem divider />
                  <DropdownItem href="#pablo" to="/auth/login" tag={Link} >
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
