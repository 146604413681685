/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar,Doughnut,Pie  } from "react-chartjs-2";
import "assets/vendor/nucleo/css/nucleo.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardTitle,
  Badge,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4
} from "variables/charts.jsx";

import Header from "components/Headers/Header.jsx";
import {Helmet} from "react-helmet";
import Moment from 'moment';
class Dashboard extends React.Component {

  
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    salesperson:sessionStorage.getItem('name'),
    linkurl:"http://pos.mzprotrading.com/api/mzprotrading.php?",
    todaytotalsale:0,
    yesterdaytotalsale:0,
    changepercent:0,
    totalquantityphone:0,
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    this.gettotalsaletoday();
    this.getphonestockremain();
  }


  getphonestockremain = () =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    //sum of phone category quantity status in ready for sell

     //
    urlencoded.append("empty", '-');
    
    let link = this.state.linkurl +'mode=admin-getstockremainphone'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
           this.setState({
             totalquantityphone:json.todayquantity,
           })
          }
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  gettotalsaletoday = () =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    //today time
    //invoice date between this time, and sum all their total price
    //yesterday time
     //invoice date between this time, and sum all their total price

     //
    urlencoded.append("salesperson", this.state.salesperson);
    
    let link = this.state.linkurl +'mode=admin-gettotalsaletoday'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){

          var increasepercent = ((json.todaytotal - json.yesterdaytotal)/json.yesterdaytotal).toFixed(1)
          this.setState({
            todaytotalsale:json.todaytotal,
            yesterdaytotalsale:json.yesterdaytotal,
            changepercent:increasepercent,
          })

          
        
      }
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }
 

  gettotalcontainerbymonthontimekpi = () =>{

    
    var urlencoded = new URLSearchParams();
    var timestampnow = new Date().getTime()/1000;
    //last 6 month
    var timestamp6monthbefore = Moment().subtract(6,"months")
    var datestart = new Date(timestamp6monthbefore).getDate()
    var twodigitdate = ("0" + datestart).slice(-2);
    var monthstart = new Date(timestamp6monthbefore).getMonth() +1
    var twodigitmonth = ("0" + monthstart).slice(-2);
    var yearstart = new Date(timestamp6monthbefore).getFullYear()
    var totaldatestart = yearstart + "-" + twodigitmonth + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass = new Date(totaldatestart).getTime()
    var starttimetopassnotmillilast6month = starttimetopass/1000

    //last 5 month
    var timestamp5monthbefore5 = Moment().subtract(5,"months")
    var datestart5 = new Date(timestamp5monthbefore5).getDate()
    var twodigitdate = ("0" + datestart5).slice(-2);
    var monthstart5 = new Date(timestamp5monthbefore5).getMonth() +1
    var twodigitmonth5 = ("0" + monthstart5).slice(-2);
    var yearstart5 = new Date(timestamp5monthbefore5).getFullYear()
    var totaldatestart5 = yearstart5 + "-" + twodigitmonth5 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass5 = new Date(totaldatestart5).getTime()
    var starttimetopassnotmillilast5month = starttimetopass5/1000

    //last 4 month
    var timestamp5monthbefore4 = Moment().subtract(4,"months")
    var datestart4 = new Date(timestamp5monthbefore4).getDate()
    var twodigitdate4 = ("0" + datestart4).slice(-2);
    var monthstart4 = new Date(timestamp5monthbefore4).getMonth() +1
    var twodigitmonth4 = ("0" + monthstart4).slice(-2);
    var yearstart4 = new Date(timestamp5monthbefore4).getFullYear()
    var totaldatestart4 = yearstart4 + "-" + twodigitmonth4 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass4 = new Date(totaldatestart4).getTime()
    var starttimetopassnotmillilast4month = starttimetopass4/1000


    //last 3 month
    var timestamp5monthbefore3 = Moment().subtract(3,"months")
    var datestart3 = new Date(timestamp5monthbefore3).getDate()
    var twodigitdate3 = ("0" + datestart3).slice(-2);
    var monthstart3 = new Date(timestamp5monthbefore3).getMonth() +1
    var twodigitmonth3 = ("0" + monthstart3).slice(-2);
    var yearstart3 = new Date(timestamp5monthbefore3).getFullYear()
    var totaldatestart3 = yearstart3 + "-" + twodigitmonth3 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass3 = new Date(totaldatestart3).getTime()
    var starttimetopassnotmillilast3month = starttimetopass3/1000


    //last 2 month
    var timestamp5monthbefore2 = Moment().subtract(2,"months")
    var datestart2 = new Date(timestamp5monthbefore2).getDate()
    var twodigitdate2 = ("0" + datestart5).slice(-2);
    var monthstart2 = new Date(timestamp5monthbefore2).getMonth() +1
    var twodigitmonth2 = ("0" + monthstart2).slice(-2);
    var yearstart2 = new Date(timestamp5monthbefore2).getFullYear()
    var totaldatestart2 = yearstart2 + "-" + twodigitmonth2 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass2 = new Date(totaldatestart2).getTime()
    var starttimetopassnotmillilast2month = starttimetopass2/1000


    //last 1 month
    var timestamp1monthbefore = Moment().subtract(1,"months")
    var datestart1 = new Date(timestamp1monthbefore).getDate()
    var twodigitdate1 = ("0" + datestart1).slice(-2);
    var monthstart1 = new Date(timestamp1monthbefore).getMonth() +1
    var twodigitmonth1 = ("0" + monthstart1).slice(-2);
    var yearstart1 = new Date(timestamp1monthbefore).getFullYear()
    var totaldatestart1 = yearstart1 + "-" + twodigitmonth1 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass1 = new Date(totaldatestart1).getTime()
    var starttimetopassnotmillilast1month = starttimetopass1/1000

    //last this month head
    var timestamp1monthbefore0 = Moment().subtract(0,"months")
    var datestart0 = new Date(timestamp1monthbefore0).getDate()
    var twodigitdate0 = ("0" + datestart0).slice(-2);
    var monthstart0 = new Date(timestamp1monthbefore0).getMonth() +1
    var twodigitmonth0 = ("0" + monthstart0).slice(-2);
    var yearstart0 = new Date(timestamp1monthbefore0).getFullYear()
    var totaldatestart0 = yearstart0 + "-" + twodigitmonth0 + "-" + "01" + "T00:00:00+00:00"
    var starttimetopass0 = new Date(totaldatestart0).getTime()
    var starttimetopassnotmillilast1month0 = starttimetopass0/1000
    if( monthstart0 == 1){
      this.state.labelsixmonth[5] = "Jan"
      this.state.labelsixmonth[4] = "Dec"
      this.state.labelsixmonth[3] = "Nov"
      this.state.labelsixmonth[2] = "Oct"
      this.state.labelsixmonth[1] = "Sep"
      this.state.labelsixmonth[0] = "Aug"
    }else if( monthstart0 == 2){
      this.state.labelsixmonth[5] = "Feb"
      this.state.labelsixmonth[4] = "Jan"
      this.state.labelsixmonth[3] = "Dec"
      this.state.labelsixmonth[2] = "Nov"
      this.state.labelsixmonth[1] = "Oct"
      this.state.labelsixmonth[0] = "Sep"
    }else if( monthstart0 == 3){
      this.state.labelsixmonth[5] = "Mar"
      this.state.labelsixmonth[4] = "Feb"
      this.state.labelsixmonth[3] = "Jan"
      this.state.labelsixmonth[2] = "Dec"
      this.state.labelsixmonth[1] = "Nov"
      this.state.labelsixmonth[0] = "Oct"
    }else if( monthstart0 == 4){
      this.state.labelsixmonth[5] = "Apr"
      this.state.labelsixmonth[4] = "Mar"
      this.state.labelsixmonth[3] = "Feb"
      this.state.labelsixmonth[2] = "Jan"
      this.state.labelsixmonth[1] = "Dec"
      this.state.labelsixmonth[0] = "Nov"
    }else if( monthstart0 == 5){
      this.state.labelsixmonth[5] = "May"
      this.state.labelsixmonth[4] = "Apr"
      this.state.labelsixmonth[3] = "Mar"
      this.state.labelsixmonth[2] = "Feb"
      this.state.labelsixmonth[1] = "Jan"
      this.state.labelsixmonth[0] = "Dec"
    }else if( monthstart0 == 6){
      this.state.labelsixmonth[5] = "Jun"
      this.state.labelsixmonth[4] = "May"
      this.state.labelsixmonth[3] = "Apr"
      this.state.labelsixmonth[2] = "Mar"
      this.state.labelsixmonth[1] = "Feb"
      this.state.labelsixmonth[0] = "Jan"
    }else if( monthstart0 == 7){
      this.state.labelsixmonth[5] = "Jul"
      this.state.labelsixmonth[4] = "Jun"
      this.state.labelsixmonth[3] = "May"
      this.state.labelsixmonth[2] = "Apr"
      this.state.labelsixmonth[1] = "Mar"
      this.state.labelsixmonth[0] = "Feb"
    }else if( monthstart0 == 8){
      this.state.labelsixmonth[5] = "Aug"
      this.state.labelsixmonth[4] = "Jul"
      this.state.labelsixmonth[3] = "Jun"
      this.state.labelsixmonth[2] = "May"
      this.state.labelsixmonth[1] = "Apr"
      this.state.labelsixmonth[0] = "Mar"
    }else if( monthstart0 == 9){
      this.state.labelsixmonth[5] = "Sep"
      this.state.labelsixmonth[4] = "Aug"
      this.state.labelsixmonth[3] = "Jul"
      this.state.labelsixmonth[2] = "Jun"
      this.state.labelsixmonth[1] = "May"
      this.state.labelsixmonth[0] = "Apr"
    }else if( monthstart0 == 10){
      this.state.labelsixmonth[5] = "Oct"
      this.state.labelsixmonth[4] = "Sep"
      this.state.labelsixmonth[3] = "Aug"
      this.state.labelsixmonth[2] = "Jul"
      this.state.labelsixmonth[1] = "Jun"
      this.state.labelsixmonth[0] = "May"
    }else if( monthstart0 == 11){
      this.state.labelsixmonth[5] = "Nov"
      this.state.labelsixmonth[4] = "Oct"
      this.state.labelsixmonth[3] = "Sep"
      this.state.labelsixmonth[2] = "Aug"
      this.state.labelsixmonth[1] = "Jul"
      this.state.labelsixmonth[0] = "Jun"
    }else if( monthstart0 == 12){
      this.state.labelsixmonth[5] = "Dec"
      this.state.labelsixmonth[4] = "Nov"
      this.state.labelsixmonth[3] = "Oct"
      this.state.labelsixmonth[2] = "Sep"
      this.state.labelsixmonth[1] = "Aug"
      this.state.labelsixmonth[0] = "Jul"
    }
    


     //next month head
     var timestamp1monthbeforeadd1 = Moment().add(1,"months")
     var datestartadd1 = new Date(timestamp1monthbeforeadd1).getDate()
     var twodigitdateadd1 = ("0" + datestartadd1).slice(-2);
     var monthstartadd1 = new Date(timestamp1monthbeforeadd1).getMonth() +1
     var twodigitmonthadd1 = ("0" + monthstartadd1).slice(-2);
     var yearstartadd1 = new Date(timestamp1monthbeforeadd1).getFullYear()
     var totaldatestartadd1 = yearstartadd1 + "-" + twodigitmonthadd1 + "-" + "01" + "T00:00:00+00:00"
     var starttimetopassadd1 = new Date(totaldatestartadd1).getTime()
     var starttimetopassnotmillilast1monthadd1 = starttimetopassadd1/1000
   

    console.log("starttimetopassnotmillilast1month0: " + starttimetopassnotmillilast1month0)
    console.log("starttimetopassnotmillilast1month: " + starttimetopassnotmillilast1month)
    console.log("starttimetopassnotmillilast2month: " + starttimetopassnotmillilast2month)
    console.log("starttimetopassnotmillilast3month: " + starttimetopassnotmillilast3month)
    console.log("starttimetopassnotmillilast4month: " + starttimetopassnotmillilast4month)
    console.log("starttimetopassnotmillilast5month: " + starttimetopassnotmillilast5month)
    console.log("starttimetopassnotmillilast6month: " + starttimetopassnotmillilast6month)
    console.log("starttimetopassnotmillilast1monthadd1: " + starttimetopassnotmillilast1monthadd1)


    urlencoded.append("billtoparty",this.state.Billtoparty);
    urlencoded.append("starttimetopassnotmilli",starttimetopassnotmillilast6month);
    urlencoded.append("starttimetopassnotmillilast1monthadd1",starttimetopassnotmillilast1monthadd1);
    let link = this.state.linkurl +'mode=admin-gettotalcontainerbymonth'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
       
      if(json.success == 1){
        this.setState({failontime:0})
        this.setState({successontime:0})
        var lengthemployee = Object.keys(json.user).length;
        for(let i=0;i<lengthemployee;i++){        
         
          /////Total Container By Month, IMPORT EXPORT-----------------------------------------------------------------------------------------
          //LAST 5 MONTH
          if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast5month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast4month){
           // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[0] = this.state.sixmonthtotalexport[0] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[0] = this.state.sixmonthtotalimport[0] + 1
            }
            this.state.sixmonthtotalcontainersize[0] = this.state.sixmonthtotalcontainersize[0] + 1
          }

          //LAST 4 MONTH
          if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast4month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast3month){
          //  console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[1] = this.state.sixmonthtotalexport[1] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[1] = this.state.sixmonthtotalimport[1] + 1
            }
            this.state.sixmonthtotalcontainersize[1] = this.state.sixmonthtotalcontainersize[1] + 1
          }

           //LAST 3 MONTH
           if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast3month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast2month){
           // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
            if(json.user[i].importexport == "Export"){
              this.state.sixmonthtotalexport[2] = this.state.sixmonthtotalexport[2] + 1
            }else if(json.user[i].importexport == "Import"){
              this.state.sixmonthtotalimport[2] = this.state.sixmonthtotalimport[2] + 1
            } 
           this.state.sixmonthtotalcontainersize[2] = this.state.sixmonthtotalcontainersize[2] + 1
          }

             //LAST 2 MONTH
             if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast2month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast1month){
             // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
              if(json.user[i].importexport == "Export"){
                this.state.sixmonthtotalexport[3] = this.state.sixmonthtotalexport[3] + 1
              }else if(json.user[i].importexport == "Import"){
                this.state.sixmonthtotalimport[3] = this.state.sixmonthtotalimport[3] + 1
              }  
             this.state.sixmonthtotalcontainersize[3] = this.state.sixmonthtotalcontainersize[3] + 1
            }

            //LAST 1 MONTH
            if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast1month && json.user[i].Activitydatetruckin-28800 <= starttimetopassnotmillilast1month0){
             // console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
              if(json.user[i].importexport == "Export"){
                this.state.sixmonthtotalexport[4] = this.state.sixmonthtotalexport[4] + 1
              }else if(json.user[i].importexport == "Import"){
                this.state.sixmonthtotalimport[4] = this.state.sixmonthtotalimport[4] + 1
              }   
             this.state.sixmonthtotalcontainersize[4] = this.state.sixmonthtotalcontainersize[4] + 1
            }
          
            //this MONTH
            if(json.user[i].Activitydatetruckin-28800 > starttimetopassnotmillilast1month0 ){
                //console.log("thismonth:" + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY"))
                //console.log("sixmonthtotalcontainersize: " +  this.state.sixmonthtotalcontainersize)
                if(json.user[i].importexport == "Export"){
                  this.state.sixmonthtotalexport[5] = this.state.sixmonthtotalexport[5] + 1
                }else if(json.user[i].importexport == "Import"){
                  this.state.sixmonthtotalimport[5] = this.state.sixmonthtotalimport[5] + 1
                }   
                this.state.sixmonthtotalcontainersize[5] = this.state.sixmonthtotalcontainersize[5] + 1
            }


            /////top Shipper vs container---------------------------------------------------------------------------
            var lengthoftopshipperlist = Object.keys(this.state.topshipperlist).length;
            for(let j = 0;j< lengthoftopshipperlist ; j ++){
              if(json.user[i].Shipper == this.state.topshipperlist[j].shippername){
                this.state.topshipperlist[j].containercount = this.state.topshipperlist[j].containercount + 1 
                //console.log("topshipplerlistcount: " + this.state.topshipperlist[j].containercount)
            }
          }
         
             /////on time kpi-----------------------------------------------------------
             if(json.user[i].Requestdate-28800 == 946684800-28800 || json.user[i].Activitydatetruckin-28800 == 946684800-28800 ){
             }else{
              if(json.user[i].Requestdate-28800 > json.user[i].Activitydatetruckin-28800 || Moment.unix(json.user[i].Requestdate-28800).format("DD/MM/YYYY") == Moment.unix(json.user[i].Activitydatetruckin-28800).format("DD/MM/YYYY")){
                this.state.successontime = this.state.successontime + 1
              }else{
                this.state.failontime = this.state.failontime + 1
                this.setState({ontimekpilistfortable:[...this.state.ontimekpilistfortable,
                  { no:this.state.failontime,
                    consignmentno:json.user[i].Consignmentno,
                    shipper:json.user[i].Shipper,
                    bookingno:json.user[i].Bookingno,
                    containerno:json.user[i].Containerno,
                    requestdate:Moment.unix(json.user[i].Requestdate-28800).format("DD/MM/YYYY"),
                    assigndate:Moment.unix(json.user[i].Activitydatetruckin-28800).format("DD/MM/YYYY"),
                  },            
                ]})
              }
             }
            

             

        }

          this.state.topshipperlist.sort((a,b) => (a.containercount < b.containercount) ?1:-1)
          //console.log("shipperlistsort: " + this.state.topshipperlist)
          // for(let j = 0;j< lengthoftopshipperlist ; j ++){
          //   if(this.state.topshipperlist[j].containercount != 0){
          //   console.log("topshippernamesort: " + this.state.topshipperlist[j].shippername)
          //   console.log("topshipplerlistcountsort: " + this.state.topshipperlist[j].containercount)
          //   }
          // }
        this.setState({
          datacontainertotalbymonth: {
            labels:this.state.labelsixmonth,
            datasets: [
              {
                label: "Container",
                data: this.state.sixmonthtotalcontainersize,
              }
            ]
          },
        })

        this.setState({
          datacontainertotalbymonthimportexport: {
            labels: this.state.labelsixmonth,
            datasets: [{
              label: 'Import ',
              yAxisID: 'Import ',
              data: this.state.sixmonthtotalimport,
              backgroundColor: '#77b5fe',
            }, {
              label: 'Export ',
              yAxisID: 'Export ',
              backgroundColor: '#000080',
              data: this.state.sixmonthtotalexport,
            }]
          },
        })
             
        if(this.state.topshipperlist[0].shippername == null){
          this.setState({
            datatopshipperlist: {
              labels: [0,0,0,0,0,0],
              datasets: [
                {
                  backgroundColor: 'rgb(212,175,55)',
                  label: "Shipper",
                  data: [this.state.topshipperlist[0].containercount, this.state.topshipperlist[1].containercount, this.state.topshipperlist[2].containercount, this.state.topshipperlist[3].containercount, this.state.topshipperlist[4].containercount, this.state.topshipperlist[5].containercount]
                }
              ]
            },
          })
        }else{
          this.setState({
            datatopshipperlist: {
              labels: [this.state.topshipperlist[0].shippername.slice(0,8),this.state.topshipperlist[1].shippername.slice(0,8),this.state.topshipperlist[2].shippername.slice(0,8),this.state.topshipperlist[3].shippername.slice(0,8),this.state.topshipperlist[4].shippername.slice(0,8),this.state.topshipperlist[5].shippername.slice(0,8)],
              datasets: [
                {
                  backgroundColor: 'rgb(212,175,55)',
                  label: "Shipper",
                  data: [this.state.topshipperlist[0].containercount, this.state.topshipperlist[1].containercount, this.state.topshipperlist[2].containercount, this.state.topshipperlist[3].containercount, this.state.topshipperlist[4].containercount, this.state.topshipperlist[5].containercount]
                }
              ]
            },
          })
        }
          

            this.setState({
              datalistontimekpi:{    
                labels: ["Fail", "OnTime"],
                datasets: [
                  {
                    backgroundColor: ['rgb(0, 255, 255)','rgb(0, 128, 128)'],
                  
                    label: "KPI",
                    data: [this.state.failontime, this.state.successontime]
                  }
                ]    
            },
            })



      }
      else{
          //this.setState({loading:false}) 
          

          this.setState({loadingdashboard:false})
        console.log(json)
      }
    
    })
  
  }


  gettodaypendingandalljobscheduleandschedule = () =>{
    //before request date count as pending  
    var todaytimestamp = new Date().getTime()/1000
    var status="Order Created"
    var statuscolor = "bg-warning"
    var estimatetimetoshowword = "-"


    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty",this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-gettodaypendingbilltoparty'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      var timestampnow  = new Date().getTime()/1000
      var timestampnowtouse  = new Date().getTime()
      var datestart = new Date(timestampnowtouse).getDate()
      var twodigitdate = ("0" + datestart).slice(-2);
      var monthstart = new Date(timestampnowtouse).getMonth() + 1
      var twodigitmonth = ("0" + monthstart).slice(-2);
      var yearstart = new Date(timestampnowtouse).getFullYear()
      var totaldateend = yearstart + "-" + twodigitmonth + "-" + twodigitdate + "T23:59:59+00:00"
      var totaldatestart = yearstart + "-" + twodigitmonth + "-" + twodigitdate + "T00:00:00+00:00"
      var timestamptodayend = new Date(totaldateend).getTime() / 1000
      var timestamptodaystart = new Date(totaldatestart).getTime() / 1000      

      var timestamptodayendyesterday = timestamptodayend - 86400
      var timestamptodaystartyesterday = timestamptodaystart - 86400
    
      console.log(json.success)      
      //console.log(json)
      if(json.success == 1){

        //today pending ---------------------------------------------------------------
        this.setState({todaypending:0})   
        this.setState({todaydeliver:0}) 
        this.setState({totalinpipeline:0})
        this.setState({todaypendingyesterday:0})   
        this.setState({todaydeliveryesterday:0}) 
        this.setState({totalinpipelineyesterday:0}) 
        var lengthemployee = Object.keys(json.user).length;
        for(let i=0;i<lengthemployee;i++){

          var timestamptruckin = new Date(json.user[i].Activitydatetruckin-28800).getTime()/1000
          var timestamptruckintouse  = new Date(json.user[i].Activitydatetruckin-28800).getTime()
          var datestarttruckin = new Date(timestamptruckintouse).getDate()
          var twodigitdatetruckin = ("0" + datestarttruckin).slice(-2);
          var monthstarttruckin = new Date(timestamptruckintouse).getMonth() + 1
          var twodigitmonthtruckin = ("0" + monthstarttruckin).slice(-2);
          var yearstarttruckin = new Date(timestamptruckintouse).getFullYear()
          var totaldateendtruckin = yearstarttruckin + "-" + twodigitmonthtruckin + "-" + twodigitdatetruckin + "T23:59:59+00:00"
          var totaldatestarttruckin = yearstarttruckin + "-" + twodigitmonthtruckin + "-" + twodigitdatetruckin + "T00:00:00+00:00"
          var timestamptodayendtruckin = new Date(totaldateendtruckin).getTime() / 1000
          var timestamptodaystarttruckin = new Date(totaldatestarttruckin).getTime() / 1000
          //today
          if(json.user[i].Requestdate-28800 == 946684800-28800){
           // console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
           // console.log("consignmentno: " + json.user[i].Consignmentno)
          }else if(json.user[i].Requestdate-28800 < timestamptodayend ){
            if(json.user[i].Activitydatetruckin-28800 ==946684800-28800 ){
              //console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
             // console.log("Activitydatetruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
              //console.log("consignmentno: " + json.user[i].Consignmentno)
              this.setState({todaypending:this.state.todaypending + 1})
            }
           
          }
         // console.log("timestamptodayend: " + timestamptodayend)
          //console.log("timestamptodaystart: " + timestamptodaystart)

          if(json.user[i].Activitydatetruckin-28800 <= timestamptodayend && json.user[i].Activitydatetruckin-28800 >= timestamptodaystart ){
           // console.log("activitytruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
           // console.log("consignmentno: " + json.user[i].Consignmentno)
            this.setState({todaydeliver:this.state.todaydeliver + 1})
          }

          //yesterday
          if(json.user[i].Requestdate-28800 == 946684800-28800){
            // console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
            // console.log("consignmentno: " + json.user[i].Consignmentno)
           }else if(json.user[i].Requestdate-28800 < timestamptodayendyesterday ){
             if(json.user[i].Activitydatetruckin-28800 ==946684800-28800 || (json.user[i].Activitydatetruckin-28800 <= timestamptodayendyesterday && json.user[i].Activitydatetruckin-28800 >= timestamptodaystartyesterday)){
              // console.log("requestdate: " + Moment.unix(json.user[i].Requestdate).format("DD/MM/YYYY HH:mm"))
              // console.log("Activitydatetruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
              // console.log("consignmentno: " + json.user[i].Consignmentno)
               this.setState({todaypendingyesterday:this.state.todaypendingyesterday + 1})
             }
            
           }
          // console.log("timestamptodayendyesterday: " + timestamptodayendyesterday)
         // console.log("timestamptodaystartyesterday: " + timestamptodaystartyesterday)
           if(json.user[i].Activitydatetruckin-28800 <= timestamptodayendyesterday && json.user[i].Activitydatetruckin-28800 >= timestamptodaystartyesterday ){
             // console.log("activitytruckin: " + Moment.unix(json.user[i].Activitydatetruckin).format("DD/MM/YYYY HH:mm"))
             // console.log("consignmentno: " + json.user[i].Consignmentno)
             this.setState({todaydeliveryesterday:this.state.todaydeliveryesterday + 1})
           }


          this.setState({totalinpipeline:this.state.totalinpipeline + 1})      



          //personal schedule
          if(json.user[i].Createdby == this.state.ref){
            if(json.user[i].Requestdate-28800 != 946684800-28800 ){
              if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY') ){
                //console.log("EstimateTimeRange: " + json.user[i].EstimateTimeRange)
                console.log("Requestdate: " + Moment((json.user[i].Requestdate-28800)*1000).format('DD/MM/YYYY'))
                //console.log("todaytimestamp: " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                //if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
                  //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'))
                  //console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                  if(json.user[i].transferhistorydate-28800 != 946684800-28800){
                    status ='Completed'
                    statuscolor='bg-success'
                  }
                  else if(json.user[i].transferbilldate-28800 != 946684800-28800){
                    status ='Billing'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
                    status ='Trucked Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
                    status ='Staging Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                    status ='Trucked In'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
                    status ='Staging In'
                    statuscolor='bg-yellow'
                  }else{
                    status ='Order Created'
                    statuscolor='bg-warning'
                  }
  
                  if(json.user[i].EstimateTimeRange == 1){
                    estimatetimetoshowword = "8am - 12pm"
                  }else if(json.user[i].EstimateTimeRange == 2){
                    estimatetimetoshowword = "12pm - 4pm"
                  }else if(json.user[i].EstimateTimeRange == 3){
                    estimatetimetoshowword = "4pm - 9pm"
                  }else if(json.user[i].EstimateTimeRange == 0){
                    estimatetimetoshowword = "-"
                  }
                  
                  if(json.user[i].importexport=="Import"){
                    this.setState({personalschedulelistimport:[...this.state.personalschedulelistimport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})                    
                  }else{
                    this.setState({personalschedulelistexport:[...this.state.personalschedulelistexport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})
                    
                  }
                 
               // }
              
              }else if(json.user[i].Requestdate-28800 < todaytimestamp){
                //console.log("EstimateTimeRange: " + json.user[i].EstimateTimeRange)
                if((json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 ==946684800-28800)||(json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 !=946684800-28800)){
                  console.log("Requestdate: " + Moment((json.user[i].Requestdate-28800)*1000).format('DD/MM/YYYY'))
                  //console.log("todaytimestamp: " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                  //if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
                  //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'))
                  //console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
                  if(json.user[i].transferhistorydate-28800 != 946684800-28800){
                    status ='Completed'
                    statuscolor='bg-success'
                  }
                  else if(json.user[i].transferbilldate-28800 != 946684800-28800){
                    status ='Billing'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
                    status ='Trucked Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
                    status ='Staging Out'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                    status ='Trucked In'
                    statuscolor='bg-success'
                  }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
                    status ='Staging In'
                    statuscolor='bg-yellow'
                  }else{
                    status ='Order Created'
                    statuscolor='bg-warning'
                  }
  
                  if(json.user[i].EstimateTimeRange == 1){
                    estimatetimetoshowword = "8am - 12pm"
                  }else if(json.user[i].EstimateTimeRange == 2){
                    estimatetimetoshowword = "12pm - 4pm"
                  }else if(json.user[i].EstimateTimeRange == 3){
                    estimatetimetoshowword = "4pm - 9pm"
                  }else if(json.user[i].EstimateTimeRange == 0){
                    estimatetimetoshowword = "-"
                  }
                  if(json.user[i].importexport=="Import"){
                    this.setState({personalschedulelistimport:[...this.state.personalschedulelistimport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})
                  }else{
                    this.setState({personalschedulelistexport:[...this.state.personalschedulelistexport,
                      { no:i,
                        rotno:json.user[i].Rotnumber,
                        consignmentno:json.user[i].Consignmentno,
                        shippername:json.user[i].Shipper.slice(0,28),
                        bookingno:json.user[i].Bookingno,
                        containerno:json.user[i].Containerno,
                        activitystagingindate:json.user[i].Activitydatestaging-28800,
                        activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                        activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                        activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                        activitytransferbilldate:json.user[i].transferbilldate-28800,
                        activitytransferhistory:json.user[i].transferhistorydate-28800,
                        activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                        requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                        estimatetime:estimatetimetoshowword,
                        status:status,
                        statuscolor:statuscolor,
                      },            
                    ]})
                  }
             
                }
              }

            }
                  
              
          }


          //alljob schedule
          if(json.user[i].Requestdate-28800 != 946684800-28800 ){
          if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY') ){
            //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY'))
          // console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
           // if(Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
            // console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY'))
            // console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
              if(json.user[i].transferhistorydate-28800 != 946684800-28800){
                status ='Completed'
                statuscolor='bg-success'
              }
              else if(json.user[i].transferbilldate-28800 != 946684800-28800){
                status ='Billing'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
                status ='Trucked Out'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
                status ='Staging Out'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
                status ='Trucked In'
                statuscolor='bg-success'
              }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
                status ='Staging In'
                statuscolor='bg-yellow'
              }else{
                status ='Order Created'
                statuscolor='bg-warning'
              }

              if(json.user[i].EstimateTimeRange == 1){
                estimatetimetoshowword = "8am - 12pm"
              }else if(json.user[i].EstimateTimeRange == 2){
                estimatetimetoshowword = "12pm - 4pm"
              }else if(json.user[i].EstimateTimeRange == 3){
                estimatetimetoshowword = "4pm - 9pm"
              }else if(json.user[i].EstimateTimeRange == 0){
                estimatetimetoshowword = "-"
              }
              
              if(json.user[i].importexport == "Import"){
                this.setState({alljobschedulelistimport:[...this.state.alljobschedulelistimport,
                  { no:i,
                    rotno:json.user[i].Rotnumber,
                    consignmentno:json.user[i].Consignmentno,
                    shippername:json.user[i].Shipper.slice(0,28),
                    bookingno:json.user[i].Bookingno,
                    containerno:json.user[i].Containerno,
                    activitystagingindate:json.user[i].Activitydatestaging-28800,
                    activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                    activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                    activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                    activitytransferbilldate:json.user[i].transferbilldate-28800,
                    activitytransferhistory:json.user[i].transferhistorydate-28800,
                    activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                    requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                    estimatetime:estimatetimetoshowword,
                    status:status,
                    statuscolor:statuscolor,
                  },            
                ]})
              }else{
                this.setState({alljobschedulelistexport:[...this.state.alljobschedulelistexport,
                  { no:i,
                    rotno:json.user[i].Rotnumber,
                    consignmentno:json.user[i].Consignmentno,
                    shippername:json.user[i].Shipper.slice(0,28),
                    bookingno:json.user[i].Bookingno,
                    containerno:json.user[i].Containerno,
                    activitystagingindate:json.user[i].Activitydatestaging-28800,
                    activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                    activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                    activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                    activitytransferbilldate:json.user[i].transferbilldate-28800,
                    activitytransferhistory:json.user[i].transferhistorydate-28800,
                    activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                    requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                    estimatetime:estimatetimetoshowword,
                    status:status,
                    statuscolor:statuscolor,
                  },            
                ]})
              }
             
              //console.log("personalschedulelist: " + this.state.personalschedulelist)
            //}
          
         }else if(json.user[i].Requestdate-28800 < todaytimestamp){
          if((json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 ==946684800-28800)||(json.user[i].Activitydatetruckin-28800 == 946684800-28800 && json.user[i].Activitydatestaging-28800 !=946684800-28800)){
            if(json.user[i].transferhistorydate-28800 != 946684800-28800){
              status ='Completed'
              statuscolor='bg-success'
            }
            else if(json.user[i].transferbilldate-28800 != 946684800-28800){
              status ='Billing'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatetruckout-28800 != 946684800-28800){
              status ='Trucked Out'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatestagingout-28800 != 946684800-28800){
              status ='Staging Out'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatetruckin-28800 != 946684800-28800){
              status ='Trucked In'
              statuscolor='bg-success'
            }else if(json.user[i].Activitydatestaging-28800 != 946684800-28800){
              status ='Staging In'
              statuscolor='bg-yellow'
            }else{
              status ='Order Created'
              statuscolor='bg-warning'
            }

            if(json.user[i].EstimateTimeRange == 1){
              estimatetimetoshowword = "8am - 12pm"
            }else if(json.user[i].EstimateTimeRange == 2){
              estimatetimetoshowword = "12pm - 4pm"
            }else if(json.user[i].EstimateTimeRange == 3){
              estimatetimetoshowword = "4pm - 9pm"
            }else if(json.user[i].EstimateTimeRange == 0){
              estimatetimetoshowword = "-"
            }
            
            if(json.user[i].importexport =="Import"){
              this.setState({alljobschedulelistimport:[...this.state.alljobschedulelistimport,
                { no:i,
                  rotno:json.user[i].Rotnumber,
                  consignmentno:json.user[i].Consignmentno,
                  shippername:json.user[i].Shipper.slice(0,28),
                  bookingno:json.user[i].Bookingno,
                  containerno:json.user[i].Containerno,
                  activitystagingindate:json.user[i].Activitydatestaging-28800,
                  activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                  activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                  activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                  activitytransferbilldate:json.user[i].transferbilldate-28800,
                  activitytransferhistory:json.user[i].transferhistorydate-28800,
                  activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                  requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                  estimatetime:estimatetimetoshowword,
                  status:status,
                  statuscolor:statuscolor,
                },            
              ]})
            }else{
              this.setState({alljobschedulelistexport:[...this.state.alljobschedulelistexport,
                { no:i,
                  rotno:json.user[i].Rotnumber,
                  consignmentno:json.user[i].Consignmentno,
                  shippername:json.user[i].Shipper.slice(0,28),
                  bookingno:json.user[i].Bookingno,
                  containerno:json.user[i].Containerno,
                  activitystagingindate:json.user[i].Activitydatestaging-28800,
                  activtiytruckindate:json.user[i].Activitydatetruckin-28800,
                  activitystagingoutdate:json.user[i].Activitydatestagingout-28800,
                  activitytruckoutdate:json.user[i].Activitydatetruckout-28800,
                  activitytransferbilldate:json.user[i].transferbilldate-28800,
                  activitytransferhistory:json.user[i].transferhistorydate-28800,
                  activitydatecreatenewtime:json.user[i].Activitydatecreatenew-28800,
                  requestdatetime:Moment(json.user[i].Requestdate*1000-28800000).format('DD/MM/YYYY'),
                  estimatetime:estimatetimetoshowword,
                  status:status,
                  statuscolor:statuscolor,
                },            
              ]})
            }
          
          }
         }       
        
        }
        this.setState({loadingdashboard:false})

          //
        }

        this.state.personalschedulelistimport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
        this.state.personalschedulelistexport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
        this.state.alljobschedulelistimport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
        this.state.alljobschedulelistexport.sort((a,b) => (a.requestdatetime > b.requestdatetime) ?1:-1)
      }
      else{
          //this.setState({loading:false}) 
        console.log(json)
        this.setState({loadingdashboard:false})
      }
      //console.log("todaydeliver: " + this.state.todaydeliver)
      //console.log("todaydeliveryesterday: " + this.state.todaydeliveryesterday)

      //console.log("todaypending: " + this.state.todaypending)
      //console.log("todaypendingyesterday: " + this.state.todaypendingyesterday)
      if(this.state.todaydeliver == 0){
        this.setState({percentdeliver:0})
      }else{
        this.setState({percentdeliver:(this.state.todaydeliver - this.state.todaydeliveryesterday)/(this.state.todaydeliver) *100})
      }
      
      if(this.state.todaypending == 0){
        this.setState({percentpending:0})
      }else{
        this.setState({percentpending:(this.state.todaypending - this.state.todaypendingyesterday)/(this.state.todaypending)*100})
      }
      
     
 
      //personal schedule ---------------------------------------------------------------
      // if(json.success == 1){
      //   for(let i=0;i<lengthemployee;i++){
         
      //   }
      // }

      //alljob list schedule
      // if(json.success == 1){
      //   for(let i=0;i<lengthemployee;i++){
      //   //  if(json.user[i].Createdby == this.state.ref){
      //     //console.log("json.user[i].EstimateTimeRange: " + json.user[i].EstimateTimeRange)
      //       if(json.user[i].EstimateTimeRange != 0){
      //         //console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY'))
      //       // console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
      //         if(Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY') == Moment(todaytimestamp*1000).format('DD/MM/YYYY')){
      //         // console.log("Moment(json.user[i].Requestdate).format('DD/MM/YYYY'): " + Moment(json.user[i].Requestdate*1000).format('DD/MM/YYYY'))
      //         // console.log("Moment(todaytimestamp).format('DD/MM/YYYY'): " + Moment(todaytimestamp*1000).format('DD/MM/YYYY'))
      //           if(json.user[i].transferhistorydate != 946684800){
      //             status ='Completed'
      //             statuscolor='bg-success'
      //           }
      //           else if(json.user[i].transferbilldate != 946684800){
      //             status ='Billing'
      //             statuscolor='bg-success'
      //           }else if(json.user[i].Activitydatetruckout != 946684800){
      //             status ='Trucked Out'
      //             statuscolor='bg-success'
      //           }else if(json.user[i].Activitydatestagingout != 946684800){
      //             status ='Staging Out'
      //             statuscolor='bg-success'
      //           }else if(json.user[i].Activitydatetruckin != 946684800){
      //             status ='Trucked In'
      //             statuscolor='bg-success'
      //           }else if(json.user[i].Activitydatestaging != 946684800){
      //             status ='Staging In'
      //             statuscolor='bg-yellow'
      //           }else{
      //             status ='Order Created'
      //             statuscolor='bg-warning'
      //           }

      //           if(json.user[i].EstimateTimeRange == 1){
      //             estimatetimetoshowword = "8am - 12pm"
      //           }else if(json.user[i].EstimateTimeRange == 2){
      //             estimatetimetoshowword = "12pm - 3pm"
      //           }else if(json.user[i].EstimateTimeRange == 3){
      //             estimatetimetoshowword = "3pm - 9pm"
      //           }
                
      //           this.setState({alljobschedulelist:[...this.state.alljobschedulelist,
      //             { no:i,
      //               rotno:json.user[i].Rotnumber,
      //               consignmentno:json.user[i].Consignmentno,
      //               shippername:json.user[i].Shipper.slice(0,32),
      //               bookingno:json.user[i].Bookingno,
      //               containerno:json.user[i].Containerno,
      //               activitystagingindate:json.user[i].Activitydatestaging,
      //               activtiytruckindate:json.user[i].Activitydatetruckin,
      //               activitystagingoutdate:json.user[i].Activitydatestagingout,
      //               activitytruckoutdate:json.user[i].Activitydatetruckout,
      //               activitytransferbilldate:json.user[i].transferbilldate,
      //               activitytransferhistory:json.user[i].transferhistorydate,
      //               activitydatecreatenewtime:json.user[i].Activitydatecreatenew,
      //               estimatetime:estimatetimetoshowword,
      //               status:status,
      //               statuscolor:statuscolor,
      //             },            
      //           ]})
      //           //console.log("personalschedulelist: " + this.state.personalschedulelist)
      //         }
            
      //     // }         
          
      //     }
        
        
      //   }
      // }


  })
  }

 

  render() {
    //const {id} = this.props.location.state
  
    return (
      <>
       <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>MZ Pro Trading</title>
            </Helmet>
        </div>
        <Header  />
        {/* Card stats */}
        <Container className="mt--7" fluid>
        {this.state.loadingdashboard == true?
         <Row className="mt-9">
         <Col md="12" >
       <img 
       className="img-center"
       width="120"
       height="120"
       alt="..."
       src={require("./../assets/gif/lorrygif3.gif")}
     />
     </Col>
       </Row>
        :
        <div>
         
        <Row>
                <Col lg="12" xl="12">
                  <Card className="card-stats mb-5 mb-xl-5">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Today Sales:
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">
                          {this.state.todaytotalsale}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="ni ni-delivery-fast" />
                          </div>
                        </Col>
                      </Row>
                      <p className="mt-3 mb-0 text-muted text-sm">
                        {this.state.changepercent < 0 ?
                              <span className="text-danger mr-2">
                              <i className="fa fa-arrow-down" /> {Math.round(this.state.percentdeliver * 100) / 100}%
                            </span>
                        :
                        <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" /> {this.state.changepercent}
                      </span>
                        }
                  
                        <span className="text-nowrap">Since yesterday</span>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="12" xl="12">
                  <Card className="card-stats mb-5 mb-xl-5">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Phone Stock Remain (Ready For Sell Phone):
                          </CardTitle>
                          <span className="h1 font-weight-bold mb-0">
                           {this.state.totalquantityphone}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="ni ni-time-alarm" />
                          </div>
                        </Col>
                      </Row>
                   
                    </CardBody>
                  </Card>
                </Col>
               
              </Row>
      



  </div>
        }
        
        </Container>
     
     
     
      </>
    );
  }
}

export default Dashboard;
