/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';


// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Alert,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormFeedback,
  FormText,
  Label,
} from "reactstrap";
// core components
import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import ReactDatetime from "react-datetime";
import { textSpanIntersectsWith } from "typescript";
import axios ,{post} from 'axios'; 
import Moment from 'moment';
import { controllers } from "chart.js";
//Validation date?
//export or import, also check type
//change shipmenttype then empty type


class AddNewStock extends React.Component {
  
  state = {
    tabs: 1,
    exampleModal: false,
    editpopuout:false,
    productname:'',
    Createdbyaccount:sessionStorage.getItem('Ref'),
    requestdateerrorinvalid:false,
    categoryinput:'',  
    categorylist:[{title:'Phone'},{title:'Accessories'},{title:'Spare Part'}],
    userlist:[],
    selectedcategory:'',
    storagelist:[],
    modellist:[],
    productnamelist:[],
    supplierlist:[],
    storagelist:[],
    colorlist:[],
    costmoney:0,
    salesmoney:0,
    quantityproduct:0,
    repairbywhochoosevalue:'',
    productname:'',
    brand:'',
    imei:'',
    repaircostmoney:0,
    repairdate:0,
    wastageornot:'No',
    suppliername:'',
    purchasedate:Moment().format("DD/MM/YYYY"),
    statusstock:'',
    remark:'',
    storagevalue:'',    
    colorphonevalue:'',
    fingerprinthaveornot:'No',
    faceidhaveornot:'No',
    phonemodelvalue:'',
    typevalue:'',
    productnamevalueerrorvalidation:false,
    typevalueerrorvalidation:false,
    branderrorvalidation:false,
    suppliernameerrorvalidation:false,
    feedbackmessage:"Something wrong, please try again",
    choosencategory:0,
    choosennewproductornot:2,
    cabletype:[{title:"Type C"},{title:'Normal'}],
    cablebrand:[{title:"Apple"},{title:'Android'}],
    chargertype:[{title:"Normal"},{title:'Fast Charger'}],
    chargerbrand:[{title:"Apple"},{title:'Android'}],
    earpodtype:[{title:"Normal"}],
    earpodbrand:[{title:"Apple"},{title:'Android'}],
    casingtype:[{title:"Black"},{title:'Blue'},{title:'Transparent'},{title:'Green'}],
    casingbrand:[{title:""}],
    temperedglassbrand:[{title:"Normal"}],
    temperedglasstype:[{title:"Privacy"},{title:"Matt"},{title:"Normal"}],
    boxtype:[{title:"Normal"}],
    boxbrand:[{title:"Apple"},{title:'Android'}],
    batterytype:[{title:"Normal"}],
    batterybrand:[{title:"Normal"}],
    lcdtype:[{title:"Normal"}],
    lcdbrand:[{title:"Brand1"},{title:"Brand2"}],
    housingtype:[{title:"Black"},{title:"White"}],
    housingbrand:[{title:"Normal"}],
    tradeinornot:"",

    linkurl:"http://pos.mzprotrading.com/api/mzprotrading.php?",
     //linkurl:"https://cors-anywhere.herokuapp.com/http://pos.mzprotrading.com/api/mzprotrading.php?",
  };

  componentDidMount(){
    this.selectuser();
    this.selectstorage();
    this.selectcolorlist();     
    this.selectphonemodel();
    
  }

  selectuser =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectuser'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({userlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({userlist:[...this.state.userlist,
                { no:i+1,
                  username:json.employee[i].name,
                  title:json.employee[i].name,
                  value:json.employee[i].name,
                },            
              ]})
               console.log(this.state.userlist)
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  // selecttypeaccessorieslist =(value) =>{
  //   this.setState({loading:true})
  //   console.log(value)
  //   var urlencoded = new URLSearchParams();
  //   urlencoded.append("productname", value);
  //   let link = this.state.linkurl +'mode=admin-selecttypeaccessorieslist'
  //   fetch(link, {
  //     method: 'POST', 
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body:urlencoded
  //   })
  //   .then(response => response.json())
  //   .then(json => {
  //     console.log(json.success)
  //     if(json.success == 1){
        
  //           console.log(json)
  //           this.setState({typeaccessorieslist:[]});   
  //            var lengthemployee = Object.keys(json.employee).length;
  //            for(let i=0;i<lengthemployee;i++){

  //              this.setState({typeaccessorieslist:[...this.state.typeaccessorieslist,
  //               { no:i+1,
  //                 title:json.employee[i].typevalue,
  //                 value:json.employee[i].typevalue,
  //               },            
  //             ]})
  //              console.log(this.state.typeaccessorieslist)
  //             }
  //            // this.setState({loading:false})  
  //           }        
               
  //        else{
  //         this.setState({typeaccessorieslist:[{title:""}]})
  //         this.setState({loading:false}) 
  //       console.log(json)
  //     }
    
  //    })
  
  // }

  // selectbrandaccessorieslist =(value) =>{
  //   this.setState({loading:true})
  //   console.log(value)
  //   var urlencoded = new URLSearchParams();
  //   urlencoded.append("productname", value);
  //   let link = this.state.linkurl +'mode=admin-selectbrandaccessorieslist'
  //   fetch(link, {
  //     method: 'POST', 
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body:urlencoded
  //   })
  //   .then(response => response.json())
  //   .then(json => {
  //     console.log(json.success)
  //     if(json.success == 1){
        
  //           console.log(json)
  //           this.setState({brandaccessorieslist:[]});   
  //            var lengthemployee = Object.keys(json.employee).length;
  //            for(let i=0;i<lengthemployee;i++){

  //              this.setState({brandaccessorieslist:[...this.state.brandaccessorieslist,
  //               { no:i+1,
  //                 title:json.employee[i].brandvalue,
  //                 value:json.employee[i].brandvalue,
  //               },            
  //             ]})
  //              console.log(this.state.brandaccessorieslist)
  //             }
  //            // this.setState({loading:false})  2
  //           }        
               
  //        else{
  //         this.setState({brandaccessorieslist:[{title:""}]})
  //         this.setState({loading:false}) 
  //       console.log(json)
  //     }
    
  //    })
  
  // }

  // selecttypesparepartlist =(value) =>{
  //   this.setState({loading:true})
  //   console.log(value)
  //   var urlencoded = new URLSearchParams();
  //   urlencoded.append("productname", value);
  //   let link = this.state.linkurl +'mode=admin-selecttypesparepartlist'
  //   fetch(link, {
  //     method: 'POST', 
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body:urlencoded
  //   })
  //   .then(response => response.json())
  //   .then(json => {
  //     console.log(json.success)
  //     if(json.success == 1){
        
  //           console.log(json)
  //           this.setState({typesparepartlist:[]});   
  //            var lengthemployee = Object.keys(json.employee).length;
  //            for(let i=0;i<lengthemployee;i++){

  //              this.setState({typesparepartlist:[...this.state.typesparepartlist,
  //               { no:i+1,
  //                 title:json.employee[i].typevalue,
  //                 value:json.employee[i].typevalue,
  //               },            
  //             ]})
  //              console.log(this.state.typesparepartlist)
  //             }
  //            // this.setState({loading:false})  
  //           }        
               
  //        else{
  //         this.setState({typesparepartlist:[{title:""}]})
  //         this.setState({loading:false}) 
  //       console.log(json)
  //     }
    
  //    })
  
  // }

  // selectbrandsparepartlist =(value) =>{
  //   this.setState({loading:true})
  //   var urlencoded = new URLSearchParams();
  //   console.log(value)
  //   urlencoded.append("productname", value);
  //   let link = this.state.linkurl +'mode=admin-selectbrandsparepartlist'
  //   fetch(link, {
  //     method: 'POST', 
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     body:urlencoded
  //   })
  //   .then(response => response.json())
  //   .then(json => {
  //     console.log(json.success)
  //     if(json.success == 1){
        
  //           console.log(json)
  //           this.setState({brandsparepartlist:[]});   
  //            var lengthemployee = Object.keys(json.employee).length;
  //            for(let i=0;i<lengthemployee;i++){

  //              this.setState({brandsparepartlist:[...this.state.brandsparepartlist,
  //               { no:i+1,
  //                 title:json.employee[i].brandvalue,
  //                 value:json.employee[i].brandvalue,
  //               },            
  //             ]})
  //              console.log(this.state.brandsparepartlist)
  //             }
  //            // this.setState({loading:false})  2
  //           }        
               
  //        else{
  //         this.setState({typesparepartlist:[{title:""}]})
  //         this.setState({loading:false}) 
  //       console.log(json)
  //     }
    
  //    })
  
  // }

  selectphonemodel = () =>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectproductmodel'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({modellist:[]});   
            this.setState({casingbrand:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){
            
                this.setState({modellist:[...this.state.modellist,
                  { no:i+1,
                    username:json.employee[i].phonemodel,
                    title:json.employee[i].phonemodel,
                    value:json.employee[i].phonemodel,
                  },            
                ]})

                this.setState({casingbrand:[...this.state.casingbrand,
                  { no:i+1,
                    username:json.employee[i].phonemodel,
                    title:json.employee[i].phonemodel,
                    value:json.employee[i].phonemodel,
                  },            
                ]})
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
     })
  }

  selectsupplier =(value) =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectsupplier'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({supplierlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){
               console.log("productname: "+json.employee[i].productname)
               console.log("selectedcategory: "+value)
              if(json.employee[i].productname == value){
                this.setState({supplierlist:[...this.state.supplierlist,
                  { no:i+1,
                    username:json.employee[i].suppliername,
                    title:json.employee[i].suppliername,
                    value:json.employee[i].suppliername,
                  },            
                ]})
              }
               
               console.log(this.state.supplierlist)
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  selectproductmodel =(value) =>{
    this.setState({loading:true})
 

    if(value == 'Phone'){  
      this.setState({loading:false})
    }else if(value == 'Accessories'){
      this.setState({productnamelist:[]})
      this.setState({productnamelist:[{title:"Cable"},{title:"Charger"},{title:"Earpod"},{title:'Casing'},{title:'Tempered Glass'},{title:'Box'}]})
    //  this.selecttypesparepartlist();
     // this.selectbrandsparepartlist();
      this.setState({loading:false})
    }else if(value == 'Spare Part'){
      this.setState({productnamelist:[]})
      this.setState({productnamelist:[{title:"Battery"},{title:"LCD"},{title:"Housing"}]})
     // this.selecttypesparepartlist();
    //  this.selectbrandsparepartlist();
      this.setState({loading:false})
    }
   
  }

  selectstorage =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectgb'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({storagelist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){
            
                this.setState({storagelist:[...this.state.storagelist,
                  { no:i+1,
                    username:json.employee[i].phonestorage,
                    title:json.employee[i].phonestorage,
                    value:json.employee[i].phonestorage,
                  },            
                ]})
              
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  selectcolorlist =() =>{ 
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectcolorlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({colorlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){
                this.setState({colorlist:[...this.state.colorlist,
                  { no:i+1,
                    username:json.employee[i].phonecolor,
                    title:json.employee[i].phonecolor,
                    value:json.employee[i].phonecolor,
                  },            
                ]})
              
               
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  selectproductname = (value) =>{
    if(this.state.selectedcategory == "Phone"){

    }else if(this.state.selectedcategory == "Accessories"){
      this.setState({productnamevalue:value});
     // this.selecttypeaccessorieslist(value);
    //  this.selectbrandaccessorieslist(value);
    }else if(this.state.selectedcategory == "Spare Part"){
      this.setState({productnamevalue:value});
    //  this.selecttypesparepartlist(value);
     // this.selectbrandsparepartlist(value);
    }
  
  }

  selectedcategoryfunction = (value) =>{
    this.setState({selectedcategory:value})
    this.selectsupplier(value);
    
    
    if(value == "Phone"){
      this.setState({statusstock:'Purchase'})
     // this.setState({choosennewproductornot:2})
    }else{
      this.selectproductmodel(value);
      this.setState({statusstock:'Sold'})      
     // this.setState({choosennewproductornot:0})
    }
    //refreshall
  }

  refreshallthing =() =>{
    
    this.setState({tabs: 1,
      exampleModal: false,
      editpopuout:false,
      aftersubmitstatus:0,
      productname:'',
      Createdbyaccount:sessionStorage.getItem('Ref'),
      requestdateerrorinvalid:false,
      categoryinput:'',  
      categorylist:[{title:'Phone'},{title:'Accessories'},{title:'Spare Part'}],
      userlist:[],
      selectedcategory:'',
      storagelist:[],
      modellist:[],
      productnamelist:[],
      supplierlist:[],
      storagelist:[],
      colorlist:[],
      costmoney:0,
      salesmoney:0,
      quantityproduct:0,
      repairbywhochoosevalue:'',
      productname:'',
      brand:'',
      imei:'',
      repaircostmoney:0,
      repairdate:0,
      wastageornot:'No',
      suppliername:'',
      purchasedate:Moment().format("DD/MM/YYYY"),
      statusstock:'',
      remark:'',
      storagevalue:'',    
      colorphonevalue:'',
      fingerprinthaveornot:'No',
      faceidhaveornot:'No',
      phonemodelvalue:'',
      typevalue:'',
      productnamevalueerrorvalidation:false,
      typevalueerrorvalidation:false,
      branderrorvalidation:false,
      suppliernameerrorvalidation:false,
      feedbackmessage:"Something wrong, please try again",
      choosencategory:0,
      choosennewproductornot:2,
      cabletype:[{title:"Type C"},{title:'Normal'}],
      cablebrand:[{title:"Apple"},{title:'Android'}],
      chargertype:[{title:"Normal"},{title:'Fast Charger'}],
      chargerbrand:[{title:"Apple"},{title:'Android'}],
      earpodtype:[{title:"Normal"}],
      earpodbrand:[{title:"Apple"},{title:'Android'}],
      casingtype:[{title:"Black"},{title:'Blue'},{title:'Transparent'},{title:'Green'}],
      casingbrand:[{title:""}],
      temperedglassbrand:[{title:"Normal"}],
      temperedglasstype:[{title:"Privacy"},{title:"Matt"},{title:"Normal"}],
      boxtype:[{title:"Normal"}],
      boxbrand:[{title:"Apple"},{title:'Android'}],
      batterytype:[{title:"Normal"}],
      batterybrand:[{title:"Normal"}],
      lcdtype:[{title:"Normal"}],
      lcdbrand:[{title:"Brand1"},{title:"Brand2"}],
      housingtype:[{title:"Black"},{title:"White"}],
      housingbrand:[{title:"Normal"}],
      tradeinornot:"",
  })
  }


 submitnewrotforpending =() =>{ 

    var errorornot = false;
   
    if(this.state.selectedcategory == "Phone"){

      if(this.state.phonemodelvalue ==""){
        this.setState({phonemodelerrorvalidation:true});
        this.setState({phonemodelerrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({phonemodelerrorvalidation:false});
        this.setState({phonemodelerrormessage:""});
      }  

      if(this.state.imei ==""){
        this.setState({imeierrorvalidation:true});
        this.setState({imeierrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({imeierrorvalidation:false});
        this.setState({imeierrormessage:""});
      }  


      if(this.state.brand ==""){
        this.setState({branderrorvalidation:true});
        this.setState({branderrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({branderrorvalidation:false});
        this.setState({branderrormessage:""});
      }  


      if(this.state.storagevalue ==""){
        this.setState({storagevalueerrorvalidation:true});
        this.setState({storagevalueerrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({storagevalueerrorvalidation:false});
        this.setState({storagevalueerrormessage:""});
      }  


      if(this.state.colorvalue ==""){
        this.setState({colorvalueerrorvalidation:true});
        this.setState({colorvalueerrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({colorvalueerrorvalidation:false});
        this.setState({colorvalueerrormessage:""});
      }  


      if(this.state.fingerprinthaveornot ==""){
        this.setState({fingerprinthaveornoterrorvalidation:true});
        this.setState({fingerprinthaveornoterrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({fingerprinthaveornoterrorvalidation:false});
        this.setState({fingerprinthaveornoterrormessage:""});
      }  

      if(this.state.faceidhaveornot ==""){
        this.setState({faceidhaveornoterrorvalidation:true});
        this.setState({faceidhaveornoterrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({faceidhaveornoterrorvalidation:false});
        this.setState({faceidhaveornoterrormessage:""});
      }  

      if(this.state.suppliername ==""){
        this.setState({suppliernameerrorvalidation:true});
        this.setState({suppliernameerrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({suppliernameerrorvalidation:false});
        this.setState({suppliernameerrormessage:""});
      }  
      
      if(this.state.tradeinornot ==""){
        this.setState({tradeinornotvalidation:true});
        this.setState({tradeinornotvalidationmessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({tradeinornotvalidation:false});
        this.setState({tradeinornotvalidationmessage:""});
      }  


    }else{
      if(this.state.productnamevalue ==""){
        this.setState({productnamevalueerrorvalidation:true});
        this.setState({productnamevalueerrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({productnamevalueerrorvalidation:false});
        this.setState({productnamevalueerrormessage:""});
      }  

      if(this.state.typevalue ==""){
        this.setState({typevalueerrorvalidation:true});
        this.setState({typevalueerrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({typevalueerrorvalidation:false});
        this.setState({typevalueerrormessage:""});
      }  


      if(this.state.brand ==""){
        this.setState({branderrorvalidation:true});
        this.setState({branderrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({branderrorvalidation:false});
        this.setState({branderrormessage:""});
      }  

      if(this.state.suppliername ==""){
        this.setState({suppliernameerrorvalidation:true});
        this.setState({suppliernameerrormessage:"Please fill in the detail"});
        errorornot=true;  
       
      }else{
        this.setState({suppliernameerrorvalidation:false});
        this.setState({suppliernameerrormessage:""});
      }  
      console.log("productnamevalue: " + this.state.productnamevalue)
      console.log("typevalue: " + this.state.typevalue)
      console.log("brand: " + this.state.brand)
      console.log("suppliername: " + this.state.suppliername)


      console.log("productnamevalueerrorvalidation: " + this.state.productnamevalueerrorvalidation)
      console.log("typevalueerrorvalidation: " + this.state.typevalueerrorvalidation)
      console.log("branderrorvalidation: " + this.state.branderrorvalidation)
      console.log("suppliernameerrorvalidation: " + this.state.suppliernameerrorvalidation)
 
    }

    if(errorornot == true){
      this.setState({alerterrormessage:'Something wrong, Please fill in all details'})
    }

    
    if( errorornot==true){
      window.scrollTo(0, 0)
      this.setState({errorsubmit:true})
      
    }else{
      
        this.setState({aftersubmitstatus:3})
        var urlencoded = new URLSearchParams();
        
        if(this.state.repairdate == 0){
          urlencoded.append("repairdate", 0);
        }else{
          var repairdatevalue = new Date(this.state.repairdate).getTime()/1000;
          urlencoded.append("repairdate", repairdatevalue);
        }
        
        if(this.state.purchasedate == 0){
          urlencoded.append("purchasedate", 0);
          console.log("purchasedate: " + 0)
         // return
        }else{
          var purchasedatevalue = Moment(this.state.purchasedate, 'DD/MM/YYYY').unix(); 
          urlencoded.append("purchasedate", purchasedatevalue);
          console.log("this.state.purchasedate: " + this.state.purchasedate)
          console.log("purchasedatavalue: " + purchasedatevalue)
         // return
        }
        

        if(this.state.selectedcategory == "Phone"){
          urlencoded.append("productname", this.state.phonemodelvalue);
          urlencoded.append("typevalue", '-');
          urlencoded.append("quantityvalue", '1');
          urlencoded.append("fingerprintvalue", this.state.fingerprinthaveornot);
          urlencoded.append("faceidvalue", this.state.faceidhaveornot);
          urlencoded.append("imei", this.state.imei);
          urlencoded.append("storagevalue", this.state.storagevalue);
          urlencoded.append("colorvalue", this.state.colorphonevalue);
          if(this.state.tradeinornot=="Yes"){
            urlencoded.append("tradeininvoice",this.state.tradeininvoicenumber)
            urlencoded.append("tradeinsupplier",this.state.tradeinsupplier)
          }else{
            urlencoded.append("tradeininvoice","-")
            urlencoded.append("tradeinsupplier","-")
          }
          
        }else{
          urlencoded.append("productname", this.state.productnamevalue);
          urlencoded.append("typevalue", this.state.typevalue);
          urlencoded.append("quantityvalue", this.state.quantityproduct);
          urlencoded.append("fingerprintvalue", "No");
          urlencoded.append("faceidvalue", "No");
          urlencoded.append("imei", '-');
          urlencoded.append("storagevalue", '-');
          urlencoded.append("colorvalue", '-');
          urlencoded.append("tradeininvoice","-")
          urlencoded.append("tradeinsupplier","-")
      
        }
        urlencoded.append("category", this.state.selectedcategory);
        urlencoded.append("costprice", this.state.costmoney);
        urlencoded.append("saleprice", this.state.salesmoney);
        urlencoded.append("brandvalue", this.state.brand);
        urlencoded.append("repairbywho", this.state.repairbywhochoosevalue);
        urlencoded.append("repaircost", this.state.repaircostmoney);
        //urlencoded.append("wastagevalue", this.state.wastageornot);
        urlencoded.append("suppliervalue", this.state.suppliername);
        urlencoded.append("statusvalue", this.state.statusstock);
        urlencoded.append("remark", this.state.remark);
       
        let link = this.state.linkurl +'mode=admin-insertstocktable'
        fetch(link, {
          method: 'POST', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body:urlencoded
        })
        .then(response => response.json())
        .then(json => {
          console.log(json.success)
          if(json.success == 1){
            
                this.setState({aftersubmitstatus:1})

          }
                   
          else{
               //fail submit
              this.setState({aftersubmitstatus:2})
              this.setState({loading:false}) 
              this.setState({feedbackmessage:json.message})
              console.log(json)
          }
        
         })
      
    


     
    }

  }

  // choosennewproductornot=(value)=>{
  //   if(value=="New Product"){
  //     this.setState({choosennewproductornot : 1})
  //   }else if(value=="Existing Product"){
  //     this.setState({choosennewproductornot : 3})
  //   }
     
  // }
render() {
  
    return (
      <>
        <Header />
        {/* Page content2 */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Create New Stock:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>


              {              
              this.state.aftersubmitstatus == 1
              ?    
              /* Success Status*/         
              <CardBody>

              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Submit Success</p>
           
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/stock"
              tag={Link}
              >
              Go To Stock List
            </Button>
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.refreshallthing()}
              >
              Create Another Stock
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :              
              this.state.aftersubmitstatus == 2
              ?
              /* Fail Status */
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">{this.state.feedbackmessage}</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={(e) => this.refreshallthing()}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :              
              this.state.aftersubmitstatus == 3
              ?
              /* Loading Status */
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :
              /* Normal Status*/
              <CardBody>
                <div className="modal-body p-10">
                <Form>
           

                  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
               

                 
                  {/*Category*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Category:</p>
                      <FormGroup >
                      <Autocomplete
                        options={this.state.categorylist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.selectedcategoryfunction(value)}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}  
                       disableClearable={true}
                      />                   
                      </FormGroup>
                    </Col>
                  </Row>


                  {this.state.selectedcategory == "Phone" ||  this.state.selectedcategory == "Accessories" || this.state.selectedcategory == "Spare Part"?
                  /* Phone */
                  <div>

                    {this.state.selectedcategory == "Phone" ?
                    <div> {/*ProductName,IMEI,Brand2*/}
                    <Row>
                    <Col md="4">
                        <p className="h5">Product Name:</p>
                        <FormGroup >
                        <Autocomplete
                              options={this.state.modellist}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.setState({phonemodelvalue:value})}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.phonemodelerrorvalidation} helperText={this.state.phonemodelerrormessage}/>}  
                            disableClearable={true}
                            />                 
                        </FormGroup>
                      </Col>
                    <Col md="4">
                        <p className="h5">IMEI:</p>
                        <FormGroup>
                        <Input
                            type="text"
                            onChange={e => this.setState({imei: e.target.value})}
                            value={this.state.imei}
                            invalid={this.state.imeierrorvalidation}
                            />
                          <FormFeedback invalid>Please fill in the detail</FormFeedback>
                        </FormGroup>
                      </Col>
                    <Col md="4">
                        <p className="h5">Brand:</p>
                        <FormGroup>
                        <Autocomplete
                        //options={[{title:'Yes'},{title:'No'}]}
                              options={[{title:'Apple'},{title:'Samsung'},{title:'Huawei'},{title:'Oppo'},{title:'Xiaomi'},{title:'Redmi'},{title:'Vivo'},{title:'Asus'},{title:'Others'}]}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.setState({brand:value})}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
                            disableClearable={true}
                            />    
                      
                          <FormFeedback invalid>Please fill in the detail</FormFeedback>
                        </FormGroup>
                      
                      </Col>
                    
                    </Row>
                    
                      {/*Storage,color,fingerprint,faceid*/}
                      <Row>
                    <Col md="3">
                        <p className="h5">Storage:</p>
                        <FormGroup >
                            <Autocomplete
                              options={this.state.storagelist}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.setState({storagevalue:value})}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.storagevalueerrorvalidation} helperText={this.state.storagevalueerrormessage}/>}  
                            disableClearable={true}
                            />                   
                            </FormGroup>
                      </Col>
                    <Col md="3">
                        <p className="h5">Color:</p>
                        <FormGroup>
                        <Autocomplete
                              options={this.state.colorlist}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.setState({colorphonevalue:value})}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.colorvalueerrorvalidation} helperText={this.state.colorvalueerrormessage} />}  
                            disableClearable={true}
                            />     
                        </FormGroup>
                      </Col>
                    <Col md="3">
                        <p className="h5">Fingerprint:</p>
                        <FormGroup>
                        <Autocomplete
                              options={[{title:'Yes'},{title:'No'}]}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.setState({fingerprinthaveornot:value})}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.fingerprinthaveornoterrorvalidation} helperText={this.state.fingerprinthaveornoterrormessage} />}  
                            disableClearable={true}
                            />   
                        </FormGroup>
                      
                      </Col>
                      <Col md="3">
                        <p className="h5">FaceID:</p>
                        <FormGroup>
                        <Autocomplete
                              options={[{title:'Yes'},{title:'No'}]}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.setState({faceidhaveornot:value})}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.faceidhaveornoterrorvalidation} helperText={this.state.faceidhaveornoterrormessage}  />}  
                            disableClearable={true}
                            />   
                        </FormGroup>
                      
                      </Col>
                    </Row>
    {/*Cost,Sales, Warranty*/}
    <Row> 
              
                      <Col md="6">
                      <p className="h5">Cost $:</p>
                      <FormGroup>
                      <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({costmoney: e.target.value})}
                          value={this.state.costmoney}
                          
                        />
                      </FormGroup>
                      </Col>
                      <Col md="6">
                      <p className="h5">Sales $:</p>
                      <FormGroup>                  
                      <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({salesmoney: e.target.value})}
                          value={this.state.salesmoney}
                        />
                          
                      </FormGroup>
                    </Col>
                    

                
                  </Row>
                
                      </div>
                    :
                    this.state.selectedcategory == "Accessories" ?
                    <div>
                      
                   
                      <div>
                        
                        {/*ProductName,IMEI,Brand*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Product Name:</p>
                      <FormGroup >
                      <Autocomplete
                            options={this.state.productnamelist}
                            getOptionLabel={(option) => option.title}                       
                            style={{ flex:1,display:"block" }}
                            onInputChange={(event,value) => this.selectproductname(value)}
                            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.productnamevalueerrorvalidation} helperText={this.state.productnamevalueerrormessage} />}  
                          disableClearable={true}
                          />                 
                      </FormGroup>
                    </Col>
                  <Col md="4">
                      <p className="h5">Type:</p>
                      <FormGroup>
                        {this.state.productnamevalue == "Cable" ? 
                            <Autocomplete
                            options={this.state.cabletype}
                            getOptionLabel={(option) => option.title}                       
                            style={{ flex:1,display:"block" }}
                            onInputChange={(event,value) =>this.setState({typevalue:value})}
                            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.typeerrorvalidation} helperText={this.state.typenoterrormessage}  />}  
                          disableClearable={true}
                          />    
                        :
                        this.state.productnamevalue == "Charger" ?
                        <Autocomplete
                            options={this.state.chargertype}
                            getOptionLabel={(option) => option.title}                       
                            style={{ flex:1,display:"block" }}
                            onInputChange={(event,value) =>this.setState({typevalue:value})}
                            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.typeerrorvalidation} helperText={this.state.typenoterrormessage}  />}  
                          disableClearable={true}
                          />   
                        :
                        this.state.productnamevalue == "Earpod" ?
                        <Autocomplete
                            options={this.state.earpodtype}
                            getOptionLabel={(option) => option.title}                       
                            style={{ flex:1,display:"block" }}
                            onInputChange={(event,value) =>this.setState({typevalue:value})}
                            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.typeerrorvalidation} helperText={this.state.typenoterrormessage}  />}  
                          disableClearable={true}
                          />   
                        :
                        this.state.productnamevalue == "Casing" ?
                        <Autocomplete
                            options={this.state.casingtype}
                            getOptionLabel={(option) => option.title}                       
                            style={{ flex:1,display:"block" }}
                            onInputChange={(event,value) =>this.setState({typevalue:value})}
                            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.typeerrorvalidation} helperText={this.state.typenoterrormessage}  />}  
                          disableClearable={true}
                          />  
                          :
                          this.state.productnamevalue == "Tempered Glass" ?
                          <Autocomplete
                              options={this.state.temperedglasstype}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.setState({typevalue:value})}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.typeerrorvalidation} helperText={this.state.typenoterrormessage}  />}  
                            disableClearable={true}
                            />  
                            :
                            this.state.productnamevalue == "Box" ?
                          <Autocomplete
                              options={this.state.boxtype}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.setState({typevalue:value})}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.typeerrorvalidation} helperText={this.state.typenoterrormessage}  />}  
                            disableClearable={true}
                            />  
                            :
                            <Autocomplete
                            options={[{title:""}]}
                            getOptionLabel={(option) => option.title}                       
                            style={{ flex:1,display:"block" }}
                            onInputChange={(event,value) =>this.setState({typevalue:value})}
                            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.typeerrorvalidation} helperText={this.state.typenoterrormessage}  />}  
                          disableClearable={true}
                          /> 
                        }
                                  
                      
                      </FormGroup>
                    </Col>
                  <Col md="4">
                      <p className="h5">Brand:</p>
                      <FormGroup>
                        {this.state.productnamevalue =="Cable"?
                           <Autocomplete
                           options={this.state.cablebrand}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({brand:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.branderrorvalidation} helperText={this.state.brandnoterrormessage}  />}  
                         disableClearable={true}
                         />  
                        :
                        this.state.productnamevalue =="Charger"?
                           <Autocomplete
                           options={this.state.chargerbrand}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({brand:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.branderrorvalidation} helperText={this.state.brandnoterrormessage}  />}  
                         disableClearable={true}
                         />  
                        :
                        this.state.productnamevalue =="Earpod"?
                           <Autocomplete
                           options={this.state.earpodbrand}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({brand:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.branderrorvalidation} helperText={this.state.brandnoterrormessage}  />}  
                         disableClearable={true}
                         />  
                        :
                        this.state.productnamevalue =="Casing"?
                        <Autocomplete
                        options={this.state.casingbrand}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({brand:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.branderrorvalidation} helperText={this.state.brandnoterrormessage}  />}  
                      disableClearable={true}
                      />  
                     :
                     this.state.productnamevalue =="Tempered Glass"?
                     <Autocomplete
                     options={this.state.temperedglassbrand}
                     getOptionLabel={(option) => option.title}                       
                     style={{ flex:1,display:"block" }}
                     onInputChange={(event,value) =>this.setState({brand:value})}
                     renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.branderrorvalidation} helperText={this.state.brandnoterrormessage}  />}  
                   disableClearable={true}
                   />  
                  :
                  this.state.productnamevalue =="Box"?
                  <Autocomplete
                  options={this.state.boxbrand}
                  getOptionLabel={(option) => option.title}                       
                  style={{ flex:1,display:"block" }}
                  onInputChange={(event,value) =>this.setState({brand:value})}
                  renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.branderrorvalidation} helperText={this.state.brandnoterrormessage}  />}  
                disableClearable={true}
                />  
               :
                  <Autocomplete
                  options={[{title:""}]}
                  getOptionLabel={(option) => option.title}                       
                  style={{ flex:1,display:"block" }}
                  onInputChange={(event,value) =>this.setState({brand:value})}
                  renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.branderrorvalidation} helperText={this.state.brandnoterrormessage}  />}  
                disableClearable={true}
                />  
                        }
                              
                      
                      </FormGroup>
                    
                    </Col>
                  
                  </Row>
                    {/*Cost,Sales, Warranty*/}
                    <Row> 
                  <Col md="4">
                  <p className="h5">Quantity:</p>
                      <FormGroup>
                      <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({quantityproduct: e.target.value})}
                          value={this.state.quantityproduct}
                          
                        />
                      </FormGroup>
                      </Col>
                      <Col md="4">
                      <p className="h5">Cost $:</p>
                      <FormGroup>
                      <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({costmoney: e.target.value})}
                          value={this.state.costmoney}
                          
                        />
                      </FormGroup>
                      </Col>
                      <Col md="4">
                      <p className="h5">Sales $:</p>
                      <FormGroup>                  
                      <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({salesmoney: e.target.value})}
                          value={this.state.salesmoney}
                        />
                          
                      </FormGroup>
                    </Col>
                    

                
                  </Row>
                
                      </div>
                    
                  
                       
                      </div>
                    :
                    this.state.selectedcategory == "Spare Part" ?
                    
                    <div>
                      <div>
                       {/*ProductName,IMEI,Brand*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Product Name:</p>
                      <FormGroup >
                      <Autocomplete
                            options={this.state.productnamelist}
                            getOptionLabel={(option) => option.title}                       
                            style={{ flex:1,display:"block" }}
                            onInputChange={(event,value) =>this.selectproductname(value)}
                            renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.productnamevalueerrorvalidation} helperText={this.state.productnamevalueerrormessage} />}  
                          disableClearable={true}
                          />                 
                      </FormGroup>
                    </Col>
                  <Col md="4">
                      <p className="h5">Type:</p>
                      <FormGroup>
                        {this.state.productnamevalue == "Battery" ?
                           <Autocomplete
                           options={this.state.batterytype}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({typevalue:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
                         disableClearable={true}
                         />    
                        :
                        this.state.productnamevalue == "LCD" ?
                           <Autocomplete
                           options={this.state.lcdtype}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({typevalue:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
                         disableClearable={true}
                         />    
                        :
                        this.state.productnamevalue == "Housing" ?
                        <Autocomplete
                        options={this.state.housingtype}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({typevalue:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
                      disableClearable={true}
                      />  
                      :
                      <Autocomplete
                      options={[{title:''}]}
                      getOptionLabel={(option) => option.title}                       
                      style={{ flex:1,display:"block" }}
                      onInputChange={(event,value) =>this.setState({typevalue:value})}
                      renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
                    disableClearable={true}
                    />  
                      }
                     
                        
                   
                      </FormGroup>
                    </Col>
                  <Col md="4">
                      <p className="h5">Brand:</p>
                      <FormGroup>
                        {this.state.productnamevalue == "Battery" ?
                         <Autocomplete
                         options={this.state.batterybrand}
                         getOptionLabel={(option) => option.title}                       
                         style={{ flex:1,display:"block" }}
                         onInputChange={(event,value) =>this.setState({brand:value})}
                         renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
                       disableClearable={true}/>
                        :
                        this.state.productnamevalue == "LCD" ?
                        <Autocomplete
                        options={this.state.lcdbrand}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({brand:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
                      disableClearable={true}/>
                      :
                      this.state.productnamevalue == "Housing" ?
                      <Autocomplete
                      options={this.state.housingbrand}
                      getOptionLabel={(option) => option.title}                       
                      style={{ flex:1,display:"block" }}
                      onInputChange={(event,value) =>this.setState({brand:value})}
                      renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
                    disableClearable={true}/>
                    :                  
                    <Autocomplete
                    options={[{title:""}]}
                    getOptionLabel={(option) => option.title}                       
                    style={{ flex:1,display:"block" }}
                    onInputChange={(event,value) =>this.setState({brand:value})}
                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}  
                  disableClearable={true}/>
                        }
                     
                     
                        <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    
                    </Col>
                  
                  </Row>
                    {/*Cost,Sales, Warranty*/}
                    <Row> 
                  <Col md="4">
                  <p className="h5">Quantity:</p>
                      <FormGroup>
                      <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({quantityproduct: e.target.value})}
                          value={this.state.quantityproduct}
                          
                        />
                      </FormGroup>
                      </Col>
                      <Col md="4">
                      <p className="h5">Cost $:</p>
                      <FormGroup>
                      <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({costmoney: e.target.value})}
                          value={this.state.costmoney}
                          
                        />
                      </FormGroup>
                      </Col>
                      <Col md="4">
                      <p className="h5">Sales $:</p>
                      <FormGroup>                  
                      <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({salesmoney: e.target.value})}
                          value={this.state.salesmoney}
                        />
                          
                      </FormGroup>
                    </Col>
                    

                
                  </Row>
                </div>
                             
                      </div>
                    :
                    null
                    }
                     
                     <div>
                  {/*Repair by who,cost,date*/}
                  
                  {this.state.selectedcategory == "Phone" ?
                   <Row>
                   <Col md="4">
                       <p className="h5">Repair by Who:</p>
                       <FormGroup >
                         <Autocomplete
                           id="combo-box-demo2"
                           options={this.state.userlist}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({repairbywhochoosevalue:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.shippervalidation} helperText={this.state.shippervalidationmessage} />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
                       </Col>

                       <Col md="4">
                       <p className="h5">Repair Cost $:</p>
                       <FormGroup>                                   
                       <Input
                           type="number"
                           min="0"
                           onChange={e => this.setState({repaircostmoney: e.target.value})}
                           value={this.state.repaircostmoney}
                         />
                         
                       </FormGroup>
                     </Col>
                     <Col md="4">
                       <p className="h5">Repair Date:</p>
                     <ReactDatetime
                     inputProps={{
                       placeholder: "Repair Date",                  
                     }}    
                   closeOnSelect={true}                  
                     value={this.state.repairdate}
                     onChange={(e) => this.setState({repairdate:e})}                           
                     timeFormat={false}
                     dateFormat="DD/MM/YYYY"
                   />
                     </Col>
                 
                   </Row>
                  :
                  null }
                 
                  {/*Trade in detail*/}
                  
                  {this.state.selectedcategory == "Phone" ?
                   <Row>
                   <Col md="4">
                       <p className="h5">Is Trade in ?:</p>
                       <FormGroup >
                         <Autocomplete
                           id="combo-box-demo2"
                           options={[{title:"Yes"},{title:"No"}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({tradeinornot:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.tradeinornotvalidation} helperText={this.state.tradeinornotvalidationmessage} />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
                       </Col>

                      {this.state.tradeinornot == "Yes" ?
                      
                        <Col md="4">
                          <p className="h5">Trade In Invoice:</p>
                          <FormGroup>                                   
                          <Input
                              type="text"
                              onChange={e => this.setState({tradeininvoicenumber: e.target.value})}
                              value={this.state.tradeininvoicenumber}
                            />
                            
                          </FormGroup>
                        </Col>   
                      :
                    null
                      }

                      {this.state.tradeinornot == "Yes" ?
                       <Col md="4">
                       <p className="h5">Trade In Supplier:</p>
                       <FormGroup>                                   
                       <Input
                           type="text"
                           onChange={e => this.setState({tradeinsupplier: e.target.value})}
                           value={this.state.tradeinsupplier}
                         />
                         
                       </FormGroup>
                     </Col>
                   
                      :
                      null}
                     
                 
                   </Row>
                  :
                  null }


                  {/*Supplier, wastage, salesman*/}
                  <Row>                
                      <Col md="6">
                      <p className="h5">Supplier :</p>
                      <FormGroup >
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.supplierlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({suppliername:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}
                        disableClearable={true}
                      />                   
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <p className="h5">Status :</p>
                      <FormGroup >
                      <Autocomplete
                        id="combo-box-demo2"
                        options={[{title:"Purchase"},{title:"Repair"},{title:"Ready For Sell"},{title:"Sold"},{title:"Wastage"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({statusstock:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}
                        disableClearable={true}
                      />                   
                      </FormGroup>
                      </Col>
                
                  </Row>

                {/*Warranty Period*/}
                <Row>
                  <Col md="4">
                      <p className="h5">Purchase Date :</p>
                      <ReactDatetime
                      inputProps={{
                        placeholder: 'Purchase Date',                  
                      }}    
                      
                      closeOnSelect={true}                  
                      value={this.state.purchasedate}
                      onChange={(e) => this.setState({purchasedate:e})}                           
                      timeFormat={false}
                      dateFormat="DD/MM/YYYY"
                    />
                      </Col>
                      
                  
                  </Row>

                    <br></br>
                  {/*Remark*/}
                  <Row>
            <Col md="12">
                <p className="h5">Remark:</p>
                <FormGroup>
                <Input
                    rows="5"
                    className="textcolorblack"
                    type="textarea"
                    value={this.state.remark}
                    onChange={(e)=>this.setState({remark:e.target.value})}
                  />
                </FormGroup>
              </Col>
            
            </Row>
                  </div>
                 
                
               </div>
                  :
                  null
                  }
                 
               
                 
        </Form>
          </div>

          <div className="modal-footer">
      
          {this.state.selectedcategory == "Phone" ||  this.state.selectedcategory == "Accessories" || this.state.selectedcategory == "Spare Part"?
          <div>
            {this.state.choosennewproductornot == 1 || this.state.choosennewproductornot==2  || this.state.choosennewproductornot==3?
              <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending()}>
              Submit
            </Button>
            :
            null
            }
          </div>
    :
      null 
      }
           
          </div>
           
          </CardBody>
              }

              </Card>
            </div>
          </Row>
        </Container>
     
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

     
       
      </>
    );
  }

}


export default AddNewStock;


