/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  Dropdown,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
} from "reactstrap";
// core components
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import ReactDatetime from "react-datetime";
import Moment from 'moment';


      //test in stock
      //sales table,stock table;



class AddNewSales extends React.Component {
  
  state = {
    loading:false,

    selectedcategories1:'',
    stockphonelist:[],
    stockaccessorieslist:[],
    stocksparepartlist:[],
    paymentmethodlist:[],
    unitprice1:0,
    subtotal1:0,
    productname1:'',
    quantity1:0,
    productnumbersection:1,
    remark:'',
    customername:'',
    paymentmethodoneortwo:"",
    totalprice:0,
    paymentmethodvalue:"",
    linkurl:"http://pos.mzprotrading.com/api/mzprotrading.php?",
    //linkurl:"https://cors-anywhere.herokuapp.com/http://pos.mzprotrading.com/api/mzprotrading.php?",
    
  };
  

  refreshallthingsale = () =>{

    for(let i = 0;i<this.state.productnumbersection;i++){
      this.setState({
        ["selectedcategories"+(i+1)]:'',
        ["productname"+(i+1)]:'',
        ["imei"+(i+1)]:'',
        ["quantity"+(i+1)]:'',
        ["unitprice"+(i+1)]:'',
        ["subtotal"+(i+1)]:'',
        ["warrantystart"+(i+1)]:'',
        ["warrantyend"+(i+1)]:'',
        ["salesdate"+(i+1)]:'',
      })
    }
    this.setState({     
    stockphonelist:[],
    stockaccessorieslist:[],
    stocksparepartlist:[],
    productnumbersection:1,
    aftersubmitstatus:0,
    phonenumbervalue:'',
    customername:'',
    remark:'',
    invoicenumber:'',
    totalprice:0,
    paymentmethodoneortwo:"",
    paymentmethodvalue:"",
    })
  }
  componentDidMount(){
    // this.getarealist();
    this.selectuser();
    
  }

  toggledropdownarea =  state => {
    this.setState({
      [state]:!this.state[state]
    });
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
 
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
 
  addproductbutton = () =>{
    if(this.state.productnumbersection >= 10){

    }else{
      this.setState({productnumbersection:this.state.productnumbersection+1})    
    }    
  }

  minusproductbutton = () =>{
    if(this.state.productnumbersection <= 1){

    }else{
      this.setState({productnumbersection:this.state.productnumbersection-1})    
    }    
  }

  warrantyendchoose = (numberrow,e) =>{
    
    var timestampend = e.unix()
    var timestampstart = new Date(this.state["warrantystart"+(numberrow+1)]).getTime()/1000

    console.log("timestampend:" + timestampend)
    console.log("timestampstart:" +timestampstart )

    if(timestampend < timestampstart){
      if(window.confirm("Warranty end cannot after warranty start")){
        this.setState({["warrantyend"+(numberrow+1)]:''})
        return;
      }
    }else{
      this.setState({["warrantyend"+(numberrow+1)]:e})
    }

   
  }
  
  warrantystartchoose = (numberrow,e) =>{
    this.setState({["warrantystart"+(numberrow+1)]:e})
    this.setState({["warrantyend"+(numberrow+1)]:''})
   
  }

  getphonestocklist = () =>{     
   
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectphonestocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({stockphonelist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          if(json.employee[i].statusvalue == "Ready For Sell"){
            this.setState({stockphonelist:[...this.state.stockphonelist,
              { no:i+1,
                productname:json.employee[i].productname,   
                imei:json.employee[i].imei,             
                storagevalue:json.employee[i].storagevalue,
                colorvalue:json.employee[i].colorvalue,
                //fingerprintvalue:json.employee[i].fingerprintvalue,
                //brandvalue:json.employee[i].brandvalue,      
                //faceidvalue:json.employee[i].faceidvalue,
                saleprice:json.employee[i].saleprice,
                quantityvalue:json.employee[i].quantityvalue,
                purchasedate:purchasedateshow,
                statusvalue:json.employee[i].statusvalue,
                title:json.employee[i].imei,
                productid:json.employee[i].Ref,
              },            
            ]})
          }
          
         
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }

  getaccessoriesstocklist = () =>{     
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectaccesoriesstocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
      
      console.log(json)
      this.setState({stockaccessorieslist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          if(json.employee[i].statusvalue == "Ready For Sell"){
          this.setState({stockaccessorieslist:[...this.state.stockaccessorieslist,
            { no:i+1,
              productname:json.employee[i].productname,   
              typevalue:json.employee[i].typevalue,     
              brandvalue:json.employee[i].brandvalue,     
              saleprice:json.employee[i].saleprice,
              quantityvalue:json.employee[i].quantityvalue,
              purchasedate:purchasedateshow,
              statusvalue:json.employee[i].statusvalue,
              productid:json.employee[i].Ref,
              title:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
            },            
          ]})
          }
          
        }
        // this.setState({loading:false})  
      }        
          
    else{
    this.setState({loading:false}) 
  console.log(json)
  }
    })

  }

  getsparepartstocklist = () =>{     
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectsparepartstocklist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)2
      if(json.success == 1){
      
      console.log(json)
      this.setState({stocksparepartlist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          if(json.employee[i].statusvalue == "Ready For Sell"){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          this.setState({stocksparepartlist:[...this.state.stocksparepartlist,
            { no:i+1,
              productname:json.employee[i].productname,   
              typevalue:json.employee[i].typevalue,     
              brandvalue:json.employee[i].brandvalue,     
              saleprice:json.employee[i].saleprice,
              quantityvalue:json.employee[i].quantityvalue,
              purchasedate:purchasedateshow,
              statusvalue:json.employee[i].statusvalue,
              productid:json.employee[i].Ref,
              title:json.employee[i].productname + '---'+json.employee[i].typevalue+'---'+json.employee[i].brandvalue+'---' + json.employee[i].Ref,
            },            
          ]})
          }
          
        }
        // this.setState({loading:false})  
      }        
          
    else{
    this.setState({loading:false}) 
  console.log(json)
  }
    })

  }
  
  selectedcategoriesfunction = (numberrow,value) =>{
    this.setState({["selectedcategories"+numberrow]:value})
    if(value == "Phone"){
      this.setState(
        {
        ["productname"+numberrow]:'',
        ["unitprice"+numberrow]:0,
        ["quantity"+numberrow]:0,
        ["subtotal"+numberrow]:0,
      })
      this.getphonestocklist();
    }else if(value =="Accessories"){
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
      })
      this.getaccessoriesstocklist();
    }else if(value =="Spare Part"){
      this.setState(
        {
          ["productname"+numberrow]:'',
          ["unitprice"+numberrow]:0,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
      })
      this.getsparepartstocklist();
    }
  }

  selectedimeifunction = (numberrow,value) =>{
    this.setState({["imei"+numberrow]:value})
    var lengthofphonelist = Object.keys(this.state.stockphonelist).length;
    for(let i=0;i<lengthofphonelist;i++){
       if(this.state.stockphonelist[i].imei == value){
         this.setState({
          ["productname"+numberrow]:this.state.stockphonelist[i].productname ,
          ["productnameupload"+numberrow]:this.state.stockphonelist[i].productname ,
          ["unitprice"+numberrow]:this.state.stockphonelist[i].saleprice,
          ["productid"+numberrow]:this.state.stockphonelist[i].productid,
          ["imei"+numberrow]:this.state.stockphonelist[i].imei,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ["maxquantity" + numberrow] : this.state.stockphonelist[i].quantityvalue,
         })
       }
      
         }  
  }

  selectedproductnameaccessories = (numberrow,value) =>{
    this.setState({["productname"+numberrow]:value})
    var lengthofaccessorieslist = Object.keys(this.state.stockaccessorieslist).length;
    for(let i=0;i<lengthofaccessorieslist;i++){

        var nametoshow = this.state.stockaccessorieslist[i].productname + '---' +this.state.stockaccessorieslist[i].typevalue + '---' +this.state.stockaccessorieslist[i].brandvalue +'---'+ this.state.stockaccessorieslist[i].productid
       
        if(nametoshow == value){
         this.setState({
          ["productnameupload"+numberrow]:this.state.stockaccessorieslist[i].productname,
          ["unitprice"+numberrow]:this.state.stockaccessorieslist[i].saleprice,
          ["productid"+numberrow]:this.state.stockaccessorieslist[i].productid,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ["typevalue"+numberrow]:this.state.stockaccessorieslist[i].typevalue ,
          ["brandvalue"+numberrow]:this.state.stockaccessorieslist[i].brandvalue ,
          ["maxquantity" + numberrow] : this.state.stockaccessorieslist[i].quantityvalue,
         })
       }
      
         }  
  }
  
  selectedproductnamesparepart = (numberrow,value) =>{
    this.setState({["productname"+numberrow]:value})
    var lengthofaccessorieslist = Object.keys(this.state.stocksparepartlist).length;
    for(let i=0;i<lengthofaccessorieslist;i++){

        var nametoshow = this.state.stocksparepartlist[i].productname + '---' +this.state.stocksparepartlist[i].typevalue + '---' +this.state.stocksparepartlist[i].brandvalue +'---'+ this.state.stocksparepartlist[i].productid
       
      console.log("value: " + value)
      console.log("nametoshow: " + nametoshow)
        if(nametoshow == value){
         this.setState({
          ["productnameupload"+numberrow]:this.state.stockaccessorieslist[i].productname,
          ["unitprice"+numberrow]:this.state.stocksparepartlist[i].saleprice,
          ["productid"+numberrow]:this.state.stocksparepartlist[i].productid,
          ["quantity"+numberrow]:0,
          ["subtotal"+numberrow]:0,
          ["typevalue"+numberrow]:this.state.stocksparepartlist[i].typevalue ,
          ["brandvalue"+numberrow]:this.state.stocksparepartlist[i].brandvalue ,
          ["maxquantity" + numberrow] : this.state.stocksparepartlist[i].quantityvalue,
         })
       }
      
         }  
  }
  
  selectuser =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectuser'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({userlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({userlist:[...this.state.userlist,
                { no:i+1,
                  username:json.employee[i].name,
                  title:json.employee[i].name,
                  value:json.employee[i].name,
                },            
              ]})
               console.log(this.state.userlist)
              }
              this.selectpaymentmethod();
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  selectpaymentmethod =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectpaymentmethod'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({paymentmethodlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({paymentmethodlist:[...this.state.paymentmethodlist,
                { no:i+1,
                  paymentmethod:json.employee[i].method,
                  title:json.employee[i].method,
                  value:json.employee[i].method,
                },            
              ]})
               //console.log(this.state.userlist)
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  } 

 quantity1changefunction = (numberrow,e) =>{
  this.setState({["quantity"+numberrow]: e.target.value})
  this.setState({["subtotal"+numberrow]:e.target.value * this.state["unitprice"+numberrow]});  
 }

 renderproductsection() {

  let consignmentnorow=[];
  for(let i=0;i<this.state.productnumbersection;i++){
    var numbershow = i+1
    

    

    consignmentnorow.push(
      <div>
        <Row>
        <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
        </Row>
      


      {/*product number*/}
   <Row>
     <Col md="9">
     
     <p className="h3 bold producttextcolor" style={{color:"blue",fontSize:25}}>Product {i+1}</p> 
     
     </Col>
    
   </Row>
     
    {/*Category*/}
    <Row>
    <Col md="4">
    <p className="h5">Category:</p>
         <FormGroup >
                         <Autocomplete
                           options={[{title:'Phone'},{title:'Accessories'},{title:'Spare Part'}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.selectedcategoriesfunction(i+1,value)}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
                         </Col>
       </Row>
   
   {this.state["selectedcategories" +(i+1)] == 'Phone'?
   <div>
      {/*IMEI,ProductName,UnitPrice*/}
      <Row>
       <Col md="4">
    <p className="h5">IMEI:</p>
         <FormGroup >
                         <Autocomplete
                           id="combo-box-demo2"
                           options={this.state.stockphonelist}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.selectedimeifunction((i+1),value)}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
                         </Col>
      
      
       <Col md="4">
   
   
         <p className="h5">Product Name:</p>
         <FormGroup>
           <Input
             type="text"
             value={this.state["productname"+(i+1)]}
             disabled
           />
         </FormGroup>
       </Col>
       <Col md="4">
         <p className="h5">Unit Price:</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="text"
             disabled
             value={this.state["unitprice"+(i+1)]} 
             onChange={e => this.setState({["unitprice"+(i+1)]: e.target.value})}
           />
         </FormGroup>
       </Col>
       
       </Row>
   
      {/* quantity, subtotalprice*/}
     <Row>
   
       <Col md="4">
         <p className="h5">Quantity: (max {this.state["maxquantity"+(i+1)]} stock)</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="number"
             min={0}maxquantity
             value={this.state["quantity"+(i+1)]} 
             onChange={e => this.quantity1changefunction(i+1,e)}
            max={this.state["maxquantity"+(i+1)]} 
           />
         </FormGroup>
       </Col>
       <Col md="4">
         <p className="h5">Subtotal Price:</p>
         <FormGroup>
         <Input
             id="Shipperinput1"
             className="textcolorblack"
             type="text"
             value={this.state["subtotal"+(i+1)]} 
             onChange={e => this.setState({["subtotal"+(i+1)]: e.target.value})}
             disabled
           />
         </FormGroup>
       </Col>
       <Col md="4">
                         <p className="h5">Sales Date :</p>
                         <ReactDatetime
                         inputProps={{
                           placeholder: "Sales Date",                  
                         }}    
                         
                       closeOnSelect={true}                  
                         value={this.state["salesdate"+(i+1)]}
                         onChange={(e) => this.setState({["salesdate"+(i+1)]:e})}
                         timeFormat={false}
                         dateFormat="DD/MM/YYYY"
                       />
                         </Col>
                
   </Row>
       
      {/*Salesdate, Warranty Period*/}
      <Row>
               
                     <Col md="6">
                         <p className="h5">Warranty Start :</p>
                         <ReactDatetime
                         inputProps={{
                           placeholder: "Warranty Start",                  
                         }}    
                         
                       closeOnSelect={true}                  
                         value={this.state["warrantystart"+(i+1)]}
                         onChange={(e) => this.setState({["warrantystart"+(i+1)]:e})}
                         timeFormat={false}
                         dateFormat="DD/MM/YYYY"
                       />
                         </Col>
                         <Col md="6">
                         <p className="h5">Warranty Duration:</p>
                         <Autocomplete
                           options={[{title:'1 Week'},{title:'2 Weeks'},{title:'3 Weeks'},{title:'1 Month'},{title:'2 Months'},{title:'3 Months'},{title:'1 Year'},{title:'2 Years'},{title:'3 Years'},{title:'No Warranty'}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({["warrantyduration"+(i+1)]:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />
                       </Col>
                      
                      
                     </Row>
           
   
   
   </div>
   
   :
   this.state["selectedcategories" +(i+1)] =='Accessories' ?
   <div>
      {/*ProductName*/}
      <Row>
       <Col md="10">
    <p className="h5">Product Name:</p>
         <FormGroup >
                         <Autocomplete
                           id="combo-box-demo2"
                           options={this.state.stockaccessorieslist}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.selectedproductnameaccessories((i+1),value)}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
                         </Col>
      <Col md="2">
         <p className="h5">Unit Price:</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="text"
             disabled
             value={this.state["unitprice"+(i+1)]} 
             onChange={e => this.setState({["unitprice"+(i+1)]: e.target.value})}
           />
         </FormGroup>
       </Col>
       
       </Row>
   
      {/* quantity, subtotalprice*/}
     <Row>
   
       <Col md="4">
         <p className="h5">Quantity: (max {this.state["maxquantity"+(i+1)]} stock)</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="number"
             min={0}
             value={this.state["quantity"+(i+1)]} 
             onChange={e => this.quantity1changefunction((i+1),e)}
             max={this.state["maxquantity"+(i+1)]} 
           />
         </FormGroup>
       </Col>
       <Col md="4">
         <p className="h5">Subtotal Price:</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="text"
             value={this.state["subtotal"+(i+1)]} 
             onChange={e => this.setState({["subtotal"+(i+1)]: e.target.value})}
             disabled
           />
         </FormGroup>
       </Col>
       <Col md="4">
                         <p className="h5">Sales Date :</p>
                         <ReactDatetime
                         inputProps={{
                           placeholder: "Sales Date",                  
                         }}    
                         
                       closeOnSelect={true}                  
                         value={this.state["salesdate"+(i+1)]}
                         onChange={(e) => this.setState({["salesdate"+(i+1)]:e})}
                         timeFormat={false}
                         dateFormat="DD/MM/YYYY"
                       />
                         </Col>
                
   </Row>
       
      {/*Salesdate, Warranty Period*/}
      <Row>
               
                     <Col md="6">
                         <p className="h5">Warranty Start :</p>
                         <ReactDatetime
                         inputProps={{
                           placeholder: "Warranty Start",                  
                         }}    
                         
                       closeOnSelect={true}                  
                         value={this.state["warrantystart"+(i+1)]}
                         onChange={(e) => this.warrantystartchoose(i,e)}
                         timeFormat={false}
                         dateFormat="DD/MM/YYYY"
                       />
                         </Col>
                         <Col md="6">
                         <p className="h5">Warranty Duration:</p>
                         <Autocomplete
                           options={[{title:'1 Week'},{title:'2 Weeks'},{title:'3 Weeks'},{title:'1 Month'},{title:'2 Months'},{title:'3 Months'},{title:'1 Year'},{title:'2 Years'},{title:'3 Years'},{title:'No Warranty'}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({["warrantyduration"+(i+1)]:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />
                       </Col>
                      
                      
                     </Row>
           
   
   
   </div>
   
   :
   this.state["selectedcategories" +(i+1)] =="Spare Part" ?
   <div>
      {/*ProductName*/}
      <Row>
       <Col md="10">
    <p className="h5">Product Name:</p>
         <FormGroup >
                         <Autocomplete
                           id="combo-box-demo2"
                           options={this.state.stocksparepartlist}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.selectedproductnamesparepart((i+1),value)}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
                         </Col>
      <Col md="2">
         <p className="h5">Unit Price:</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="text"
             disabled
             value={this.state["unitprice"+(i+1)]} 
             onChange={e => this.setState({["unitprice"+(i+1)]: e.target.value})}
           />
         </FormGroup>
       </Col>
       
       </Row>
   
      {/* quantity, subtotalprice*/}
     <Row>
   
       <Col md="4">
         <p className="h5">Quantity: (max {this.state["maxquantity"+(i+1)]} stock)</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="number"
             min={0}
             max={this.state["maxquantity"+(i+1)]} 
             value={this.state["quantity"+(i+1)]} 
             onChange={e => this.quantity1changefunction((i+1),e)}
           />
         </FormGroup>
       </Col>
       <Col md="4">
         <p className="h5">Subtotal Price:</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="text"
             value={this.state["subtotal"+(i+1)]} 
             onChange={e => this.setState({["subtotal"+(i+1)]: e.target.value})}
             disabled
           />
         </FormGroup>
       </Col>
       <Col md="4">
                         <p className="h5">Sales Date :</p>
                         <ReactDatetime
                         inputProps={{
                           placeholder: "Sales Date",                  
                         }}    
                         
                       closeOnSelect={true}                  
                         value={this.state["salesdate"+(i+1)]}
                         onChange={(e) => this.setState({["salesdate"+(i+1)]:e})}
                         timeFormat={false}
                         dateFormat="DD/MM/YYYY"
                       />
                         </Col>
                
   </Row>
       
      {/*Salesdate, Warranty Period*/}
      <Row>
                     <Col md="6">
                         <p className="h5">Warranty Start :</p>
                         <ReactDatetime
                         inputProps={{
                           placeholder: "Warranty Start",                  
                         }}    
                         
                       closeOnSelect={true}                  
                         value={this.state["warrantystart"+(i+1)]}
                         onChange={(e) => this.warrantystartchoose(i,e)}
                         timeFormat={false}
                         dateFormat="DD/MM/YYYY"
                       />
                         </Col>
                         <Col md="6">
                         <p className="h5">Warranty Duration:</p>
                         <Autocomplete
                           options={[{title:'1 Week'},{title:'2 Weeks'},{title:'3 Weeks'},{title:'1 Month'},{title:'2 Months'},{title:'3 Months'},{title:'1 Year'},{title:'2 Years'},{title:'3 Years'},{title:'No Warranty'}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({["warrantyduration"+(i+1)]:value})}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />
                       </Col>
                      
                      
                     </Row>
           
   
   
   </div>
   
   :
   null}
    </div>
    
    )
  }

  return consignmentnorow

}

submitnewrotforpending =() =>{ 

  var errorornot = false;
 
  if(this.state.invoicenumber ==""){
    this.setState({invoicenumbererrorvalidation:false});
    this.setState({invoicenumbererrormessage:""});
    errorornot=true;  
  }else{
    this.setState({invoicenumbererrorvalidation:false});
    this.setState({invoicenumbererrormessage:""});
  }

 

  if(this.state.paymentmethodoneortwo ==""){
    this.setState({alerterrormessage:'Please fill in How Many Payment Method '})
    errorornot=true;  
  }else{
    this.setState({paymentmethodvalueerrorvalidation:false});
    this.setState({paymentmethodvalueerrormessage:""});
    errorornot=false;  
  }

  if(this.state.paymentmethodvalue ==""){
    this.setState({alerterrormessage:'Please fill in Payment Method '})
    errorornot=true;  
  }else{
    this.setState({paymentmethodvalueerrorvalidation:false});
    this.setState({paymentmethodvalueerrormessage:""});
    errorornot=false;  
  }
  
  if(this.state.phoneprefix ==""){
    this.setState({phoneprefix:'+60'});
  }

  

  if(this.state.selectedcategories1 == "Phone"){

    if(this.state.imei1 ==""){
      this.setState({imei1errorvalidation:true});
      this.setState({imei1errormessage:"Please fill in the detail"});
      errorornot=true;  
     
    }else{
      this.setState({imei1errorvalidation:false});
      this.setState({imei1errormessage:""});
    }  

  }

  if(this.state.productname1 ==""){
    this.setState({productname1errorvalidation:true});
    this.setState({productname1errormessage:"Please fill in the detail"});
    errorornot=true;  
  }else{
    this.setState({productname1errorvalidation:false});
    this.setState({productname1errormessage:""});
  }


  if(this.state.quantity1 ==0){
    this.setState({quantity1errorvalidation:true});
    this.setState({quantity1eerrormessage:"Please fill in the detail"});
    errorornot=true;  
   
  }else{
    this.setState({quantity1errorvalidation:false});
    this.setState({quantity1errormessage:""});
  }  


  if(this.state.salesdate1 ==""){
    this.setState({salesdate1errorvalidation:true});
    this.setState({salesdate1errormessage:"Please fill in the detail"});
    errorornot=true;  
   
  }else{
    this.setState({salesdate1errorvalidation:false});
    this.setState({salesdate1errormessage:""});
  }  


  if(this.state.warrantystart1 ==""){
    this.setState({warrantystart1errorvalidation:true});
    this.setState({warrantystart1errormessage:"Please fill in the detail"});
    errorornot=true;  
   
  }else{
    this.setState({warrantystart1errorvalidation:false});
    this.setState({warrantystart1errormessage:""});
  }  

  if(this.state.warrantyduration1 ==""){
    this.setState({warrantyend1errorvalidation:true});
    this.setState({warrantyend1errormessage:"Please fill in the detail"});
    errorornot=true; 
  }else{
    this.setState({warrantydurationerrorvalidation:false});
    this.setState({warrantydurationerrormessage:""});
  }  

  if(errorornot == true){
    this.setState({alerterrormessage:'Something wrong, Please fill in all details'})
  }

  for(let i=0;i<this.state.productnumbersection;i++){
    for(let j=1;j<this.state.productnumbersection;j++){
        if(this.state["productid"+(i+1)] == this.state["productid"+(j+i+1)]){
          this.setState({alerterrormessage:'Duplicate product, please check again'})
          errorornot=true
        }
    }
  }

  if( errorornot==true){
    window.scrollTo(0, 0)
    this.setState({errorsubmit:true})
  }else{
      this.setState({aftersubmitstatus:3})
      var urlencoded = new URLSearchParams();
      
      for(let i=0;i<this.state.productnumbersection;i++){
       

        urlencoded.append(("category"+(i+1)), this.state["selectedcategories"+(i+1)]);
        if(this.state["selectedcategories"+(i+1)] == "Phone"){
          urlencoded.append(("imei"+(i+1)), this.state["imei"+(i+1)]);
        }else{
          urlencoded.append(("imei"+(i+1)), '-');
          urlencoded.append(("typevalue"+(i+1)), this.state["typevalue"+(i+1)]);
          urlencoded.append(("brandvalue"+(i+1)), this.state["brandvalue"+(i+1)]);
        }
        urlencoded.append(("productid"+(i+1)), this.state["productid"+(i+1)]);      
        urlencoded.append(("unitprice"+(i+1)), this.state["unitprice"+(i+1)]);  
        urlencoded.append(("productname"+(i+1)), this.state["productnameupload"+(i+1)]);
        urlencoded.append(("quantity"+(i+1)), this.state["quantity"+(i+1)]);
        urlencoded.append(("subtotal"+(i+1)), this.state["subtotal"+(i+1)]);

        var salesdate = new Date(this.state["salesdate"+(i+1)]).getTime()/1000;
        urlencoded.append(("salesdate"+(i+1)), salesdate);

        var warrantystart = new Date(this.state["warrantystart"+(i+1)]).getTime()/1000;
        var warrantystartdate = Moment(this.state["warrantystart"+(i+1)], 'DD/MM/YYYY');
        urlencoded.append(("warrantystart"+(i+1)), warrantystart);

        if(this.state["warrantyduration"+(i+1)] == "1 Week"){
          var warrantyenddate = warrantystartdate.add(1, 'week')   
          var warrantyend = warrantyenddate.unix();
         // console.log("warrantyend: "  + warrantyend);
         // return;
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "2 Weeks"){
          var warrantyenddate = warrantystartdate.add(2, 'week')  
          var warrantyend = warrantyenddate.unix();
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "3 Weeks"){
          var warrantyenddate = warrantystartdate.add(3, 'week') 
          var warrantyend = warrantyenddate.unix(); 
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "1 Month"){
          var warrantyenddate = warrantystartdate.add(1, 'M') 
          var warrantyend = warrantyenddate.unix();
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "2 Months"){
          var warrantyenddate = warrantystartdate.add(2, 'M') 
          var warrantyend = warrantyenddate.unix();
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "3 Months"){
          var warrantyenddate = warrantystartdate.add(3, 'M')
          var warrantyend = warrantyenddate.unix(); 
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "1 Year"){
          var warrantyenddate = warrantystartdate.add(1, 'y') 
          var warrantyend = warrantyenddate.unix();
          urlencoded.append(("warrantyend"+(i+1)), warrantyend);
        }else if(this.state["warrantyduration"+(i+1)] == "No Warranty"){
          var warrantyenddate = 0
          var warrantyend = warrantyenddate.unix();
          urlencoded.append(("warrantyend"+(i+1)), 0);
        }
        
        
       
        
      }
      var invoicedate2 = new Date(this.state.invoicedate).getTime()/1000;
      urlencoded.append("invoicedate", invoicedate2);
      
      urlencoded.append("numberofproduct", this.state.productnumbersection);
      urlencoded.append("invoicenumber", this.state.invoicenumber);
      urlencoded.append("salesperson", this.state.salesperson);
      urlencoded.append("customername", this.state.customername);

      if(this.state.phonenumbervalue ==""){
        urlencoded.append("phonenumbervalue", '-')
      }else{
        var phonenumbervalue2 = this.state.phoneprefix & this.state.phonenumbervalue
        urlencoded.append("phonenumbervalue", phonenumbervalue2)
      }
      
      if(this.state.paymentmethodoneortwo == "Two"){
        urlencoded.append("paymentmethodvalue", this.state.paymentmethodvalue);
        urlencoded.append("paymentmethod1value", this.state.paymentvalue1);
        urlencoded.append("paymentmethod2", this.state.paymentmethod2);
        urlencoded.append("paymentmethod2value", this.state.paymentvalue2);
      }else{
        urlencoded.append("paymentmethodvalue", this.state.paymentmethodvalue);
        urlencoded.append("paymentmethod1value", 0);
        urlencoded.append("paymentmethod2", "");
        urlencoded.append("paymentmethod2value", 0);
      }
      
  
      //return;
      urlencoded.append("remark", this.state.remark);
     
      let link = this.state.linkurl +'mode=admin-insertsalestable'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        if(json.success == 1){
          
              this.setState({aftersubmitstatus:1})

        }
                 
           else{
             //fail submit
            this.setState({aftersubmitstatus:2})
            this.setState({loading:false}) 
          console.log(json)
        }
      
       })
    
  


   
  }

}

calculatetotalprice = () =>{
  var totalpricetemp = 0;
  var i = 0;
  for(i=1;i<=this.state.productnumbersection;i++){
    totalpricetemp = totalpricetemp + this.state["subtotal"+i]
  }
  this.setState({totalprice:totalpricetemp})
}

  render() {
    


    
    return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>

            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Create New Sales:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>
              
              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <CardBody>

              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Submit Success</p>
           
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/sales"
              tag={Link}
              >
              Go To Sales List
            </Button>
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.refreshallthingsale()}
              >
              Create Another Sales
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again Later.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.refreshallthingsale()}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :
              /* Normal Status */
             <CardBody>
      <div className="modal-header">
   
  </div>
  <div className="modal-body mt--5">
  <Form>
  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
  
  {/*invoice*/}
  <Row>
  <Col md="12">
      <p className="h5">Invoice Number:</p>
      <FormGroup>
      <Input
          className="textcolorblack"
          type="text"
          value={this.state.invoicenumber} 
          onChange={e => this.setState({invoicenumber: e.target.value})}
        />
      </FormGroup>
    </Col>
    </Row>
    
    {/*product+,product-*/}
    <Row>
      <Col md="9">
        {this.state.productnumbersection >= 10 ?
        null
        :
        <Button className=" mb-2" color="primary" type="button" onClick={this.addproductbutton.bind(this)}>
        + Product
      </Button>
        }

        {this.state.productnumbersection <=1 ?
        null
        :
        <Button className=" mb-2" color="primary" type="button" onClick={this.minusproductbutton.bind(this)}>
        - Product
      </Button>
        }
    
 
      </Col>
  
  </Row>

 {this.renderproductsection()}


  {/*space*/}          
  <Row >
  <Col md="12">
    <p> </p>
    <p> </p>
  </Col>
  </Row>

  {/*space*/}
  <Row>
  <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
  </Row>
  
   {/*Salesperson, customer name, phone*/}
  <Row>
  <Col md="4">
      <p className="h5">Sales Person :</p>
      <FormGroup >
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.userlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({salesperson:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                        disableClearable={true}
                      />                   
                      </FormGroup>
    </Col>
  <Col md="4">
      <p className="h5">Customer Name :</p>
      <FormGroup>
      <Input
          className="textcolorblack"
          type="text"
          value={this.state.customername} 
          onChange={e => this.setState({customername: e.target.value})}
        />
      </FormGroup>
    </Col>
   

    <Col md="2">
      <p className="h5">Customer Phone :</p>
      
      <FormGroup>
      <Autocomplete
                        options={[{title:'+60'},{title:'+65'}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({phoneprefix:value})}
                        renderInput={(params) => <TextField {...params}  variant="outlined" />}
                        disableClearable={true}
                      /> 
    
      </FormGroup>
    </Col>
    <Col md="2" >
      
      <FormGroup>
      <Input
          style={{marginTop:25}}
          className="textcolorblack "
          type="number"
          value={this.state.phonenumbervalue} 
          onChange={e => this.setState({phonenumbervalue: e.target.value})}
        />
    
      </FormGroup>
    </Col>

    
  </Row>
  <Row>
  <Col md="2">
         <p className="h5">Calculate</p>
         <FormGroup>
         <Button color="primary" type="button" onClick={this.calculatetotalprice.bind(this)}>
            Calculate Total Price
          </Button>
         </FormGroup>
       </Col>
  <Col md="2">
         <p className="h5">Total Price:</p>
         <FormGroup>
           <Input
             type="text"
             value={this.state.totalprice}
             disabled
           />
         </FormGroup>
       </Col>
    <Col md="4">
         <p className="h5">How Many Payment Method:</p>
         <FormGroup >
                         <Autocomplete
                           id="combo-box-demo2"
                           options={[{title:"One"},{title:"Two"}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.setState({paymentmethodoneortwo:value})}                          
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
       </Col>
      {this.state.paymentmethodoneortwo == "One" ?
      <Col md="4">
      <p className="h5">Payment Method 1:</p>
      <FormGroup >
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.paymentmethodlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({paymentmethodvalue:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                        disableClearable={true}
                      />                   
                      </FormGroup>
    </Col>
    :
    null}
       
    </Row>
 {/*payment method*/}
 
   {
   this.state.paymentmethodoneortwo == "Two" ?
   <Row>
   <Col md="3">
        <p className="h5">Payment Method 1:</p>
        <FormGroup >
                        <Autocomplete
                          id="combo-box-demo2"
                          options={this.state.paymentmethodlist}
                          getOptionLabel={(option) => option.title}                       
                          style={{ flex:1,display:"block" }}
                          onInputChange={(event,value) =>this.setState({paymentmethodvalue:value})}
                          renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                          disableClearable={true}
                        />                   
                        </FormGroup>
      </Col>
      <Col md="3">
        <p className="h5">Paid Value:</p>
        <FormGroup >
        <Input
                            type="number"
                            min="0"
                            onChange={e => this.setState({paymentvalue1: e.target.value})}
                            value={this.state.costmoney}
                            
                          />               
                        </FormGroup>
      </Col>
      <Col md="3">
        <p className="h5">Payment Method 2:</p>
        <FormGroup >
                        <Autocomplete
                          id="combo-box-demo2"
                          options={this.state.paymentmethodlist}
                          getOptionLabel={(option) => option.title}                       
                          style={{ flex:1,display:"block" }}
                          onInputChange={(event,value) =>this.setState({paymentmethod2:value})}
                          renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                          disableClearable={true}
                        />                   
                        </FormGroup>
      </Col>
      <Col md="3">
        <p className="h5">Paid Value 2:</p>
        <FormGroup >
        <Input
                            type="number"
                            min="0"
                            onChange={e => this.setState({paymentvalue2: e.target.value})}
                            value={this.state.costmoney}
                            
                          />                 
                        </FormGroup>
      </Col>
     
   </Row>
   :null
   }
  
 <Row>
 <Col md="4">
    <p className="h5">Invoice Date :</p>
                         <ReactDatetime
                         inputProps={{
                           placeholder: "Invoice Date",                  
                         }}    
                         
                       closeOnSelect={true}                  
                         value={this.state.invoicedate}
                         onChange={(e) => this.setState({invoicedate:e})}
                         timeFormat={false}
                         dateFormat="DD/MM/YYYY"
                       />
    </Col>
 </Row>

  {/*Remark*/}
  <Row>
            <Col md="12">
                <p className="h5">Remark:</p>
                <FormGroup>
                <Input
                    rows="5"
                    className="textcolorblack"
                    type="textarea"
                    value={this.state.remark}
                    onChange={(e)=>this.setState({remark:e.target.value})}
                  />
                </FormGroup>
              </Col>
            
            </Row>
                  
               
</Form>
  </div>
  <div className="modal-footer">
    {/* <Button
      color="secondary"
      data-dismiss="modal"
      type="button"
      onClick={() => this.toggleModal("editpopuout")}2
    >
      Close
    </Button> */}
    <Button color="primary" type="button" onClick={this.submitnewrotforpending.bind(this)}>
      Save changes
    </Button>
  </div>
   
  </CardBody>
              }
              <Row>
              <Col md="12" >
              <p className="h2" >
                

                
              </p>

                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              
              </Card>
            </div>
          </Row>
        </Container>
     
       

      
      </>
    );
  }
}

export default AddNewSales;
