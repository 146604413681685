/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  Dropdown,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Alert,
} from "reactstrap";
// core components
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import ReactDatetime from "react-datetime";
import { textChangeRangeIsUnchanged } from 'typescript';
import Moment from 'moment';
class ClaimWarranty extends React.Component {
  
  state = {
    loading:false,
    tabs: 1,
    exampleModal: false,
    editpopuout:false,
    isopendropdownarea:false,
    invoiceorimeitext:'',
    aftersubmitstatus:0,
    alerterrormessage:'',
    errorsubmit:false,
    quotationnumbershow:'',
    linkurl:"http://pos.mzprotrading.com/api/mzprotrading.php?",
    categoryselectedornot:0,
    productlist:[],
    //linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
   //imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
  };
  

  refreshallthingquotation = () =>{
    this.setState({
      tabs: 1,
      exampleModal: false,
      editpopuout:false,
      isopendropdownarea:false,
      areadropdowntext:'',
      Billtoparty:sessionStorage.getItem('Billtoparty'),
      lasteditby:sessionStorage.getItem('Ref'),
      shippervalue:'',
      addressvalue:'',
      picvalue:'',
      depogatechargevalue:'',
      contactvalue:'',
      backtobackchargevalue:'',
      gp20pgu:'',
      isotank20pgu:'',
      rf20pgu:'',
      ot20pgu:'',
      tipper20pgu:'',
      gp40pgu:'',
      rf40pgu:'',
      isotank40pgu:'',
      tipper40pgu:'',
      gp20ptp:'',
      isotank20ptp:'',
      rf20ptp:'',
      ot20ptp:'',
      tipper20ptp:'',
      gp40ptp:'',
      rf40ptp:'',
      isotank40ptp:'',
      tipper40ptp:'',
      aftersubmitstatus:0,
      alerterrormessage:'',
      errorsubmit:false,
      quotationnumbershow:'',
      linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
      //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
      //linkurl:"https://cors-anywhere.herokuapp.com/http://crm.mewahtrans.com.my/api/mewahapicode.php?",
      imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
    })
  }
  componentDidMount(){
    // this.getarealist();
  }
  toggledropdownarea =  state => {
    this.setState({
      [state]:!this.state[state]
    });
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
 
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
 

  categoryselected = (value) => {
    this.setState({categoryselectedornot:1})
    this.setState({selectedcategory:value})
  }

  invoiceorimeiselected = () =>{
    //this.setState({invoiceorimeiselectedornot:1})
  }

  productnameselected = (value) =>{
    this.setState({
      productnameselectedornot:1,
      selectedproductname:value,
    })
    this.choosenproductnamecheckwarrantydate(value)
    
  }

  clicktocheckbuttonclick = () =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    console.log("checkbuttoncalled")
    //sum of phone category quantity status in ready for sell

    urlencoded.append("selectedcategory", this.state.selectedcategory);
    urlencoded.append("invoiceorimei", this.state.invoiceorimeitext);
    
    let link = this.state.linkurl +'mode=admin-checkhavingthisproductornot'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
        console.log(json)
        this.setState({productlist:[]});   
         var lengthemployee = Object.keys(json.employee).length;
         for(let i=0;i<lengthemployee;i++){
           //var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
            this.setState({productlist:[...this.state.productlist,
              { no:i+1,
               ref:json.employee[i].Ref,
               invoicenumber:json.employee[i].invoicenumber,
               title:json.employee[i].productname + "-" + json.employee[i].typevalue +"-" +json.employee[i].brandvalue +"-" +json.employee[i].Ref,
               productname:json.employee[i].productname,
               productid:json.employee[i].productid,
               warrantystart:json.employee[i].warrantystart,
               warrantyend:json.employee[i].warrantyend,
               brandvalue:json.employee[i].brandvalue,
               typevalue:json.employee[i].typevalue,
              },            
            ]})
          
           
          }
          this.setState({
            invoiceorimeiselectedornot:1,
          })
        }
               
         else{
          window.confirm(json.message)
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  choosenproductnamecheckwarrantydate = (value) =>{
    this.setState({loading:true})
   // var urlencoded = new URLSearchParams();
  
    console.log("Called choosen product check warranty")
    
    var lengthofaccessorieslist = Object.keys(this.state.productlist).length;
    for(let i=0;i<lengthofaccessorieslist;i++){

      var nametoshow = this.state.productlist[i].productname + "-" + this.state.productlist[i].typevalue +"-" +this.state.productlist[i].brandvalue +"-" +this.state.productlist[i].ref
      console.log("nametoshow" +nametoshow)
  //  console.log("value: " + value)
    //console.log("nametoshow: " + nametoshow)
      if(nametoshow == value){
       this.setState({
        selectedwarrantystart:this.state.productlist[i].warrantystart,
        selectedwarrantyend:this.state.productlist[i].warrantyend,
        selectedref:this.state.productlist[i].ref,        
       })
       console.log("this.state.productlist[i].warrantystart: " + this.state.productlist[i].warrantystart)
       console.log("this.state.productlist[i].warrantyend: " + this.state.productlist[i].warrantyend)
       console.log("Moment().unix(): " + Moment().unix());

       if(parseInt(this.state.productlist[i].warrantystart)<= Moment().unix() && parseInt(this.state.productlist[i].warrantyend) >= Moment().unix()){
          this.setState({
            selectedwarrantystart: Moment(this.state.productlist[i].warrantystart*1000).format("DD/MM/YYYY"),
            selectedwarrantyend: Moment(this.state.productlist[i].warrantyend*1000).format("DD/MM/YYYY"),
            canbeclaimornott:"Can be Claimed",
            colorclaim:"green",
          })
          console.log("green")
       }else{
        this.setState({
          selectedwarrantystart: Moment(this.state.productlist[i].warrantystart*1000).format("DD/MM/YYYY"),
          selectedwarrantyend: Moment(this.state.productlist[i].warrantyend*1000).format("DD/MM/YYYY"),
          canbeclaimornott:"Cannot be Claimed",
          colorclaim:"red",
        })
        console.log("red")
       }
     }
    
         
    }

  }



  render() {
    


    
    return (
      <>
        <Header />
       
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>

            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Claim Warranty:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>
              
              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Thanks for choose our services,</p>
              <p className="word-quotationaddnewfinish">Your Quotation Number is {this.state.quotationnumbershow}.</p>
             
               <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/quotation"
              tag={Link}
              >
              Go To Pending Approval
            </Button>
            
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.buttonclickedtocreatenewquotation()}
              >
              Create Another Quotation
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again Later.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={() => this.buttonclickedtocreatenewquotation()}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :
              /* Normal Status */
             <CardBody>
      <div className="modal-header">
   
  </div>
  <div className="modal-body mt--5">
  <Form>
  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
  <Row>
  <Col md="6">
  <p className="h5">Phone/Accessories/SparePart:</p>
         <FormGroup >
                         <Autocomplete
                           options={[{title:'Phone'},{title:'Accessories'},{title:'Spare Part'}]}
                           getOptionLabel={(option) => option.title}                       
                           style={{ flex:1,display:"block" }}
                           onInputChange={(event,value) =>this.categoryselected(value)}
                           renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                           disableClearable={true}
                         />                   
                         </FormGroup>
    </Col>
    </Row>


{this.state.categoryselectedornot ? 
 <Row>
 <Col md="6">
 <p className="h5">Invoice / IMEI:</p>
        <FormGroup >
        <Input
                           type="text"
                           onChange={e => this.setState({invoiceorimeitext: e.target.value})}
                           value={this.state.invoiceorimeitext}
                           
                         />
                         
                        </FormGroup>
   </Col>
   <Col md="2">
   <p className="h5">Click to check:</p>
   <Button color="primary" type="button" onClick={this.clicktocheckbuttonclick.bind(this)} >
      Check
    </Button>
   </Col>
   </Row>

:null}
   


    {this.state.invoiceorimeiselectedornot == 1 ?
     <Row>
     <Col md="6">
     <p className="h5">Product Name:</p>
            <FormGroup >
                            <Autocomplete
                              options={this.state.productlist}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.productnameselected(value)}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                              disableClearable={true}
                            />                   
                            </FormGroup>
       </Col>
       </Row>
    :
    null}
   

   
 
{this.state.productnameselectedornot == 1 ?
<div>
{/* word section warranty, status */}
<Row>
  <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
  </Row>
<Row>
  <Col md="9">
<p className="h3">Warranty Start : {this.state.selectedwarrantystart}    -     Warranty End : {this.state.selectedwarrantyend}</p> 
<p className="h3" style={{color:this.state.colorclaim}}>Status : {this.state.canbeclaimornott}</p> 

  </Col>
</Row>
  

   {/* fill in section claim date or warranty cost */}
<Row>
  <Col md="12" >
   <div style={{width:'100%',backgroundColor:'black',height:2,borderWidth:10,borderColor:'black',textAlign:'center',marginTop:10,marginBottom:20}}></div>
    </Col>
  </Row>
 <Row>
 <Col md="4">
 <p className="h5">Claim Date :</p>
                         <ReactDatetime
                         inputProps={{
                           placeholder: "Claim Date",                  
                         }}    
                         
                       closeOnSelect={true}                  
                         value={3}
                         onChange={(e) => console.log(e)}
                         timeFormat={false}
                         dateFormat="DD/MM/YYYY"
                       />
                      </Col>
   
    <Col md="4">
    <p className="h5">Warranty Cost:</p>
         <FormGroup>
         <Input
             className="textcolorblack"
             type="number"
             min={0}
             value={1} 
             onChange={e => console.log(e)}
            max={1} 
           />            
                      </FormGroup>
                      </Col>
   
    </Row>
     
        
</div>
:
null}
     
</Form>
  </div>
  <div className="modal-footer">
    {/* <Button
      color="secondary"
      data-dismiss="modal"
      type="button"
      onClick={() => this.toggleModal("editpopuout")}2
    >
      Close
    </Button> */}
    {this.state.productnameselectedornot == 1 ?
    <Button color="primary" type="button" >
      Save changes
    </Button>:null}
  </div>
   
  </CardBody>
              }
              <Row>
              <Col md="12" >
              <p className="h2" >
                

                
              </p>

                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              <Row>
              <Col md="12" >
                </Col>
              </Row>
              
              </Card>
            </div>
          </Row>
        </Container>
     
       

      
      </>
    );
  }
}

export default ClaimWarranty;
