/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
* npm run build
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import classnames from "classnames";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
// reactstrap components
import { Card,
  Alert,
  Modal,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Form,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Pagination, 
  PaginationItem, 
  PaginationLink ,
  FormFeedback,

  Button,} from "reactstrap";
  import ReactToPrint from 'react-to-print';
  import { Link } from 'react-router-dom';
// core components
import ReactDatetime from "react-datetime";
import Header from "components/Headers/Header.jsx";
import Footer from "components/Footers/AdminFooter.jsx";
import { textChangeRangeIsUnchanged } from "typescript";
import axios ,{post} from 'axios'; 
import Moment from 'moment';

class Stock extends React.Component {

  constructor(props){
    super(props);  
  } 

  //1:purchase,2:repairr,3:readyforsell,4:sold,5:wastage
  state = {
    tabs: 1,
    tabsstatus:3,   
    loading:false,   
    stockphonelist:[],
    stockaccessorieslist:[],
    stocksparepartlist:[],
    searchtextvalue:'',
    buttoncolor1:'primary',
    buttoncolor2:'white',
    buttoncolor3:'white',
    deletemodalonoff:false,
    messageboxmodalonoff:false,
    messageformessagebox:"",
    editstockmodalonoff:false,
    selectedjobid:0,
    userid:sessionStorage.getItem('userid'),
    changetohowmanystock:0,
    editmodalonoffaccessories:false,
    editmodalonoffsparepart:false,
    editstatusmodalonoff:false,
    editstatusphonemodalonoff:false,
    editdetailphonemodalonoff:false,
    supplierlistphone:[{title:"Andy"},{title:'Joseph'}],
    supplierlistaccessories:[{title:"Taobao"},{title:'Hightell'}],
    supplierlistsparepart:[{title:"Taobao"},{title:'GS'},{title:'ZM'},{title:'HPC'}],    
    cabletype:[{title:"Type C"},{title:'Normal'}],
    cablebrand:[{title:"Apple"},{title:'Android'}],
    chargertype:[{title:"Normal"},{title:'Fast Charger'}],
    chargerbrand:[{title:"Apple"},{title:'Android'}],
    earpodtype:[{title:"Normal"}],
    earpodbrand:[{title:"Apple"},{title:'Android'}],
    casingtype:[{title:"Black"},{title:'Blue'},{title:'Transparent'},{title:'Green'}],
    casingbrand:[{title:""}],
    temperedglassbrand:[{title:"Normal"}],
    temperedglasstype:[{title:"Privacy"},{title:"Matt"},{title:"Normal"}],
    boxtype:[{title:"Normal"}],
    boxbrand:[{title:"Apple"},{title:'Android'}],
    batterytype:[{title:"Normal"}],
    batterybrand:[{title:"Normal"}],
    lcdtype:[{title:"Normal"}],
    lcdbrand:[{title:"Brand1"},{title:"Brand2"}],
    housingtype:[{title:"Black"},{title:"White"}],
    housingbrand:[{title:"Normal"}],
    phonebrandlist: [{title:'Apple'},{title:'Samsung'},{title:'Huawei'},{title:'Oppo'},{title:'Xiaomi'},{title:'Redmi'},{title:'Vivo'},{title:'Asus'},{title:'Others'}],
    changestatusvalue:"",
    howmanychangetostatus:0,
    userlist:[],
    colorlist:[],
    storagelist:[],

    //linkurl:"http://crm.mewahtrans.com.my/api/mewahapicode.php?",
    //imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
     linkurl:"http://pos.mzprotrading.com/api/mzprotrading.php?",
    // linkurl:"https://cors-anywhere.herokuapp.com/http://pos.mzprotrading.com/api/mzprotrading.php?",

    //claim warranty
    //1 to 1 exchange in warranty
    //change battery at claim warranty
    //new surface register new customer
    //select customer at sales there 
    
    //commission
    //export excel
  };

  selectuser =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectuser'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({userlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({userlist:[...this.state.userlist,
                { no:i+1,
                  username:json.employee[i].name,
                  title:json.employee[i].name,
                  value:json.employee[i].name,
                },            
              ]})
               console.log(this.state.userlist)
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }


  getphonestocklist = (status) =>{     
   
     var urlencoded = new URLSearchParams();
     urlencoded.append("status", status);
     let link = this.state.linkurl +'mode=admin-selectphonestockliststtockpage'
     fetch(link, {
       method: 'POST', 
       headers: {
         'Accept': 'application/json',
         'Content-Type': 'application/x-www-form-urlencoded',
       },
       body:urlencoded
     })
     .then(response => response.json())
     .then(json => {
       console.log(json.success)
       //console.log(json)
       if(json.success == 1){
        
        console.log(json)
        this.setState({stockphonelist:[]});   
         var lengthemployee = Object.keys(json.employee).length;
         for(let i=0;i<lengthemployee;i++){
           var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
            this.setState({stockphonelist:[...this.state.stockphonelist,
              { no:i+1,
                ref:json.employee[i].Ref,
                productname:json.employee[i].productname,   
                imei:json.employee[i].imei,             
                storagevalue:json.employee[i].storagevalue,
                colorvalue:json.employee[i].colorvalue,
                suppliername:json.employee[i].suppliername,
                //fingerprintvalue:json.employee[i].fingerprintvalue,
                //brandvalue:json.employee[i].brandvalue,      
                //faceidvalue:json.employee[i].faceidvalue,
                saleprice:json.employee[i].saleprice,
                costprice:json.employee[i].costprice,
                //quantityvalue:json.employee[i].quantityvalue,
                purchasedate:purchasedateshow,
                statusvalue:json.employee[i].statusvalue,
                costprice:json.employee[i].costprice,
              },            
            ]})
          
           
          }
         // this.setState({loading:false})  
        }        
           
     else{
      this.setState({stockphonelist:[]});  
      this.setState({loading:false}) 
      console.log(json)
  }
      })
   
  }

  getaccessoriesstocklist = (status) =>{     
   
    var urlencoded = new URLSearchParams();
    urlencoded.append("status", status);
    let link = this.state.linkurl +'mode=admin-selectaccesoriesstockliststocktable'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({stockaccessorieslist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
           this.setState({stockaccessorieslist:[...this.state.stockaccessorieslist,
             { no:i+1,
              ref:json.employee[i].Ref,
               productname:json.employee[i].productname,   
               typevalue:json.employee[i].typevalue,     
               brandvalue:json.employee[i].brandvalue,     
               saleprice:json.employee[i].saleprice,
               costprice:json.employee[i].costprice,
               quantityvalue:json.employee[i].quantityvalue,
               purchasedate:purchasedateshow,
               statusvalue:json.employee[i].statusvalue,
             },            
           ]})
         
          
         }
        // this.setState({loading:false})  
       }        
          
    else{
      this.setState({stockaccessorieslist:[]});  
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }

  getsparepartstocklist = (status) =>{     
   
  var urlencoded = new URLSearchParams();
  urlencoded.append("status", status);
  let link = this.state.linkurl +'mode=admin-selectsparepartstockliststockpage'
  fetch(link, {
    method: 'POST', 
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body:urlencoded
  })
  .then(response => response.json())
  .then(json => {
    console.log(json.success)
    //console.log(json)
    if(json.success == 1){
     
     console.log(json)
     this.setState({stocksparepartlist:[]});   
      var lengthemployee = Object.keys(json.employee).length;
      for(let i=0;i<lengthemployee;i++){
        var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
         this.setState({stocksparepartlist:[...this.state.stocksparepartlist,
           { no:i+1,
            ref:json.employee[i].Ref,
             productname:json.employee[i].productname,   
             typevalue:json.employee[i].typevalue,     
             brandvalue:json.employee[i].brandvalue,     
             saleprice:json.employee[i].saleprice,
             costprice:json.employee[i].costprice,
             quantityvalue:json.employee[i].quantityvalue,
             purchasedate:purchasedateshow,
             statusvalue:json.employee[i].statusvalue,
           },            
         ]})
       
        
       }
      // this.setState({loading:false})  
     }        
        
  else{
    this.setState({stocksparepartlist:[]}); 
   this.setState({loading:false}) 
 console.log(json)
}
   })

  }

  componentDidMount(){
    
    this.selectuser();
    this.selectcolorlist();
    this.selectstorage();
    this.phonebuttonclicked();  
  }

  renderPhonelist() {
      
    return this.state.stockphonelist.map((data, index) => {
       const {no,ref,productname,imei,saleprice,storagevalue, colorvalue,statusvalue,purchasedate,suppliername,costprice} = data 
       return (
    
            
            <tbody>
              
             <tr>
   
            <td>{no})</td>
              <td >{productname}</td>
              <td>{imei}</td> 
              <td >{storagevalue}</td>
              <td >{colorvalue}</td>        
                  <td>{saleprice}</td> 
                  <td>{suppliername}</td> 
                  <td>{purchasedate}</td> 
                  <td>
                    <Badge color="" className="badge-dot mr-4">
                        {statusvalue =="Purchase"                
                        ?
                        <i className="bg-warning" />
                        :
                        statusvalue =="Repair"                
                        ?
                        <i className="bg-info" />
                        :
                        statusvalue =="Ready For Sell"                
                        ?
                        <i className="bg-success" />
                        :
                        statusvalue =="Wastage"  
                        ?
                        <i className="bg-dark" />
                        :
                        <i className="bg-primary" />
                        
                      }
                      
                        {statusvalue}
                      </Badge>
                  </td>  
              
                  
                  <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={()=>this.toggledetaileditphone("editdetailphonemodalonoff",ref)}
                    >
                      Detail/Edit
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.togglechangephonestatus("editstatusphonemodalonoff",ref,productname,statusvalue,costprice,colorvalue,storagevalue,imei)}
                    >
                      Edit Status
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.toggledeletemodal("deletemodalonoff",ref)}
                    >
                     Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
                      
                  </tr>    
                 
                </tbody>

       
       
   
       )
    })
  }

  renderAccessorieslist() {
      
    return this.state.stockaccessorieslist.map((data, index) => {
      
       const {no,ref,productname,typevalue,brandvalue,saleprice, quantityvalue,purchasedate,statusvalue,costprice} = data 
       return (
    
            
            <tbody>
              
             <tr>
   
            <td>{no})</td>
              <td >{productname}</td>
              <td>{typevalue}</td> 
              <td >{brandvalue}</td>
              <td >{saleprice}</td>        
                  <td>{quantityvalue}</td> 
                  <td>{purchasedate}</td> 
                  <td>
                    <Badge color="" className="badge-dot mr-4">
                        {statusvalue =="Purchase"                
                        ?
                        <i className="bg-warning" />
                        :
                        statusvalue =="Repair"                
                        ?
                        <i className="bg-info" />
                        :
                        statusvalue =="Ready For Sell"                
                        ?
                        <i className="bg-success" />
                        :
                        <i className="bg-primary" />
                        
                      }
                      
                        {statusvalue}
                      </Badge>
                  </td>  
              
                  <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.toggledetaileditaccessories("editmodalonoffaccessories",ref)}
                    >
                      Detail/Edit
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.togglechangestock("editstockmodalonoff",ref,productname,typevalue,brandvalue,quantityvalue,statusvalue)}
                    >
                      Edit Stock
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.togglechangestatus("editstatusmodalonoff",ref,productname,typevalue,brandvalue,quantityvalue,statusvalue,costprice)}
                    >
                      Edit Status
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.toggledeletemodal("deletemodalonoff",ref)}
                    >
                     Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
                  </tr>    
                 
                </tbody>

       
       
   
       )
    })
  }

  rendersparepartlist() {
      
    return this.state.stocksparepartlist.map((data, index) => {
      
       const {no,ref,productname,typevalue,brandvalue,saleprice, quantityvalue,purchasedate,statusvalue,costprice} = data 
       return (
    
            
            <tbody>
              
             <tr>
   
            <td>{no})</td>
              <td >{productname}</td>
              <td>{typevalue}</td> 
              <td >{brandvalue}</td>
              <td >{saleprice}</td>        
                  <td>{quantityvalue}</td> 
                  <td>{purchasedate}</td> 
                  <td>
                    <Badge color="" className="badge-dot mr-4">
                        {statusvalue =="Purchase"                
                        ?
                        <i className="bg-warning" />
                        :
                        statusvalue =="Repair"                
                        ?
                        <i className="bg-info" />
                        :
                        statusvalue =="Ready For Sell"                
                        ?
                        <i className="bg-success" />
                        :
                        <i className="bg-primary" />
                        
                      }
                      
                        {statusvalue}
                      </Badge>
                  </td>  
              
                  <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={e => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={()=>this.toggledetaileditsparepart("editmodalonoffsparepart",ref)}
                    >
                      Detail/Edit
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.togglechangestock("editstockmodalonoff",ref,productname,typevalue,brandvalue,quantityvalue,statusvalue)}
                    >
                      Edit Stock
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.togglechangestatus("editstatusmodalonoff",ref,productname,typevalue,brandvalue,quantityvalue,statusvalue,costprice)}
                    >
                      Edit Status
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={() => this.toggledeletemodal("deletemodalonoff",ref)}
                    >
                     Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
                  </tr>    
                 
                </tbody>

       
       
   
       )
    })
  }

  toggledeletemodal = (state,ref) =>{
    this.setState({
      [state]: !this.state[state],
      selectedjobid:ref,
    });
  }

  togglemessagemodal = state =>{
    this.setState({
      [state]: !this.state[state]
    });
  }

  toggledetaileditaccessories = (state,ref) =>{
    this.setState({
      [state]: !this.state[state],
      selectedjobid:ref,
    });
    this.getdetaildataaccessories(ref);
  }

  toggledetaileditsparepart = (state,ref) =>{
    this.setState({
      [state]: !this.state[state],
      selectedjobid:ref,
    });
    this.getdetaildatasparepart(ref);
  }

  toggledetaileditphone = (state,ref) =>{
    this.setState({
      [state]: !this.state[state],
      selectedjobid:ref,
    });
    this.getdetaildataphone(ref);
  }
  



  getdetaildataaccessories = (ref) =>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", ref);
    let link = this.state.linkurl +'mode=admin-getselectedaccessoriesdata'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)     
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var dateCreateshow = Moment(json.employee[i].dateCreate*1000).format('DD/MM/YYYY')
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          this.setState({
            productnameaccessories:json.employee[0].productname,
            datecreateaccessories:dateCreateshow,
            purchasedataaccessories:purchasedateshow,
            costpriceaccessories:json.employee[0].costprice,
            salepriceaccessories:json.employee[0].saleprice,
            warrantycostaccessories:json.employee[0].warrantycost,
            quantityvalueaccessories:json.employee[0].quantityvalue,
            brandvalueaccessories:json.employee[0].brandvalue,
            suppliervalueaccessories:json.employee[0].suppliervalue,
            statusvalueaccessories:json.employee[0].statusvalue,
            remarkaccessories:json.employee[0].remark,
            typevalueaccessories:json.employee[0].typevalue,
            tradeininvoiceaccessories:json.employee[0].tradeininvoice,
            tradeinsupplieraccessories:json.employee[0].tradeinsupplier,
          })
         
          
         }
         this.setState({loading:false})  
       }        
          
    else{
      //this.setState({stockaccessorieslist:[]});  
     this.setState({loading:false}) 
     console.log(json)
    }
     })
  
  }

  getdetaildatasparepart = (ref) =>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", ref);
    let link = this.state.linkurl +'mode=admin-getselectedsparepart'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)     
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var dateCreateshow = Moment(json.employee[i].dateCreate*1000).format('DD/MM/YYYY')
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          this.setState({
            productnamesparepart:json.employee[0].productname,
            datecreatesparepart:dateCreateshow,
            purchasedatasparepart:purchasedateshow,
            costpricesparepart:json.employee[0].costprice,
            salepricesparepart:json.employee[0].saleprice,
            warrantycostsparepart:json.employee[0].warrantycost,
            quantityvaluesparepart:json.employee[0].quantityvalue,
            brandvaluesparepart:json.employee[0].brandvalue,
            suppliervaluesparepart:json.employee[0].suppliervalue,
            statusvaluesparepart:json.employee[0].statusvalue,
            remarksparepart:json.employee[0].remark,
            typevaluesparepart:json.employee[0].typevalue,
            tradeininvoicesparepart:json.employee[0].tradeininvoice,
            tradeinsuppliersparepart:json.employee[0].tradeinsupplier,
          })
         
          
         }
         this.setState({loading:false})  
       }        
          
    else{
      //this.setState({stockaccessorieslist:[]});  
     this.setState({loading:false}) 
     console.log(json)
    }
     })
  
  }

  getdetaildataphone = (ref) =>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", ref);
    console.log("getdetailcalled,ref: " + ref)
    let link = this.state.linkurl +'mode=admin-getselectedphonedetail'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)     
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var dateCreateshow = Moment(json.employee[i].dateCreate*1000).format('DD/MM/YYYY')
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
          var repairdateshow = Moment(json.employee[i].repairdate*1000).format('DD/MM/YYYY')
          this.setState({
            productnamephone:json.employee[0].productname,
            costpricephone:json.employee[0].costprice,
            salepricephone:json.employee[0].saleprice,
            warrantycostphone:json.employee[0].warrantycost,
            imeiphone:json.employee[0].imei,
            storagevaluephone:json.employee[0].storagevalue,
            colorvaluephone:json.employee[0].colorvalue,
            fingerprintvaluephone:json.employee[0].fingerprintvalue,
            faceidvaluephone:json.employee[0].faceidvalue,
            brandvaluephone:json.employee[0].brandvalue,
            repairbywhophone:json.employee[0].repairbywho,
            repaircostphone:json.employee[0].repaircost,
            repairdatephone:repairdateshow,
            suppliervaluephone:json.employee[0].suppliervalue,
            statusvaluephone:json.employee[0].statusvalue,
            purchasedatephone:purchasedateshow,
            remarkphone:json.employee[0].remark,
            tradeininvoicephone:json.employee[0].tradeininvoice,
            tradeinsupplierphone:json.employee[0].tradeinsupplier,
            datecreatephone:dateCreateshow,
          })
         
          
         }
         this.setState({loading:false})  
       }        
          
    else{
      //this.setState({stockaccessorieslist:[]});  
     this.setState({loading:false}) 
     console.log(json)
    }
     })
  
  }

  togglechangestock = (state,ref,productname,typevalue,brandvalue,quantityvalue,statusvalue) =>{
    
    this.setState({
      [state]: !this.state[state],
      selectedjobid:ref,
      selectedproductname:productname,
      selectedtypevalue:typevalue,
      selectedbrandvalue:brandvalue,
      selectedquantityvalue:quantityvalue,
      selectedstatusvalue:statusvalue,
    });
  }

  togglechangestatus = (state,ref,productname,typevalue,brandvalue,quantityvalue,statusvalue,costprice) =>{
    
    this.setState({
      [state]: !this.state[state],
      selectedjobid:ref,
      selectedproductname:productname,
      selectedtypevalue:typevalue,
      selectedbrandvalue:brandvalue,
      selectedquantityvalue:quantityvalue,
      selectedstatusvalue:statusvalue,
      changestatusvalue:statusvalue,
      howmanychangetostatus:quantityvalue,
      selectedcostprice:costprice,
    });
  }

  togglechangephonestatus = (state,ref,productname,statusvalue,costprice,colorvalue,storagevalue,imei) =>{
    this.setState({
      [state]: !this.state[state],
      selectedjobid:ref,
      selectedproductname:productname,
      selectedstatusvalue:statusvalue,
      changestatusvalue:statusvalue,
      selectedcostprice:costprice,
      selectedcolorvalue:colorvalue,
      selectedstoragevalue:storagevalue,
      selectedimei:imei,
    });
  }


  handleChange=(event,stateid)=> {
    if(stateid === "picvalue"){
      this.setState({picvalue: event});
    } else if(stateid == "contactvalue"){
      this.setState({contactvalue: event});
    }else if(stateid == "poltext"){
      this.setState({poltext: event});
    }else if(stateid == "podtext"){
      this.setState({podtext: event});
    }else if(stateid == "bookingno"){
      this.setState({bookingno: event});
    }else if(stateid == "volumenumberinput"){
      this.setState({volumenumberinput: event});
      
    }else if(stateid == "volumenumberinput1"){
      this.setState({volumenumberinput1: event});
      console.log("volumenumberinput1: "+ this.state.volumenumberinput1)
    }else if(stateid == "volumenumberinput2"){
      this.setState({volumenumberinput2: event});
      console.log("volumenumber2value2: "+ this.state.volumenumberinput2)
    }else if(stateid == "volumenumberinput3"){
      this.setState({volumenumberinput3: event});
    }else if(stateid == "volumenumberinput4"){
      this.setState({volumenumberinput4: event});
    }else if(stateid == "volumenumberinput5"){
      this.setState({volumenumberinput5: event});
    }else if(stateid == "volumenumberinput6"){
      this.setState({volumenumberinput6: event});
    }else if(stateid == "volumenumberinput7"){
      this.setState({volumenumberinput7: event});
    }else if(stateid == "volumenumberinput8"){
      this.setState({volumenumberinput8: event});
    }else if(stateid == "volumenumberinput9"){
      this.setState({volumenumberinput9: event});
    }
    
    
  }

  refreshallthing =(numberrow) =>{

    for(let i=0;i<numberrow;i++){
          
        this.setState({["container" + i] :''})
        this.setState({["requestdatedisable"+i] :false})
        this.setState({["requestdate" + i] :new Date().getTime()})
        
     
        this.setState({["Seal" +i] :''})
       
      
        this.setState({["checktbe"+i] :false})
       
      console.log("numberrow: "+numberrow);
      console.log("container: "+this.state["container" + i]);
      console.log("requestdate: "+this.state["requestdate" + i]);
      console.log("checktbe: "+this.state["checktbe" + i]);
    }

    
    this.setState({tabs: 1,
    exampleModal: false,
    editpopuout:false,
    isopendropdownshipper:false,
    isopendropdownshipmenttype:false,
    isopendropdownpod:false,
    isopendropdownpol:false,
    shipmenttypeadropdowntext:'',
    isopendropdownoperator:false,
    operatoreadropdowntext:'',
    isopendropdownpickup:false,
    isopendropdownreturnyard:false,
    pickupdropdowntext:'',
    returnyarddropdowntext:'',
    type2dropdowntext:'',
    isopendropdownforwardingagent:false,
    forwardingagentdropdowntext:'',
  
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    deliveryaddress:'',
    areatextbox:'',
    poltext:'',
    podtext:'',
    disableddropdownpol:true,
    disableddropdownpod:true,
    volumenumberinput:0,
    volumenumberinput1:0,
    volumenumberinput2:0,
    volumenumberinput3:0,
    volumenumberinput4:0,
    volumenumberinput5:0,
    volumenumberinput6:0,
    volumenumberinput7:0,
    volumenumberinput8:0,
    volumenumberinput9:0,
    type1dropdowntext:'',
    type1dropdowntext1:'',
    type1dropdowntext2:'',
    type1dropdowntext3:'',
    type1dropdowntext4:'',
    type1dropdowntext5:'',
    type1dropdowntext6:'',
    type1dropdowntext7:'',
    type1dropdowntext8:'',
    type1dropdowntext9:'',
    isopendropdowntype:false,
    isopendropdowntype1:false,
    isopendropdowntype2:false,
    isopendropdowntype3:false,
    isopendropdowntype4:false,
    isopendropdowntype5:false,
    isopendropdowntype6:false,
    isopendropdowntype7:false,
    isopendropdowntype8:false,
    isopendropdowntype9:false,
    volumeshowrow:0,
    rowvolume1:false,
    rowvolume2:false,
    rowvolume3:false,
    rowvolume4:false,
    rowvolume5:false,
    rowvolume6:false,
    rowvolume7:false,
    rowvolume8:false,
    minusbuttonshow:false,
    picvalue:'',
    contactvalue:'',
    bookingno:'',
    Vsleta:'',
    closingday:'',
    submitbuttonclick:0,
    picvalidation:false,
    contactvalidation:false,
    podtextvalidation:false,
    poltextvalidation:false,
    bookingnovalidation:false,
    vsletavalidation:false,
    closingvalidation:false,
    volumenumber1validation:false,
    volumenumber2validation:false,
    volumenumber3validation:false,
    volumenumber4validation:false,
    volumenumber5validation:false,
    volumenumber6validation:false,
    volumenumber7validation:false,
    volumenumber8validation:false,
    volumenumber9validation:false,
    volumenumber10validation:false,
    containervalidation:false,
    requestdatevalidation:false,
    vesselnamevalidation:false,
    vesselvoyagevalidation:false,
    commodityvalidation:false,
    areadropdowntext:'',
    commodity:'',
    vesselname:'',
    vesselvoyage:'',
    errorsubmit:false,
    aftersubmitstatus:0,
  })
  }

  sparepartbuttonclicked = () =>{
    this.setState({buttoncolor1:"white"})
    this.setState({buttoncolor2:"white"})
    this.setState({buttoncolor3:"primary"})
    this.setState({buttonpurchase:"white"})
    this.setState({buttonrepair:"white"})
    this.setState({buttonreadyforsell:"primary"})
    this.setState({buttonsold:"white"})
    this.setState({buttonwastage:"white"})
    this.setState({tabs:3})
    this.setState({tabsstatus:3})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})
    
      this.getsparepartstocklist("Ready For Sell");
      
  }

  phonebuttonclicked = () =>{
    this.setState({buttoncolor1:"primary"})
    this.setState({buttoncolor2:"white"})
    this.setState({buttoncolor3:"white"})
    this.setState({buttonpurchase:"white"})
    this.setState({buttonrepair:"white"})
    this.setState({buttonreadyforsell:"primary"})
    this.setState({buttonsold:"white"})
    this.setState({buttonwastage:"white"})
    this.setState({tabs:1})
    this.setState({tabsstatus:3})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})
    this.getphonestocklist("Ready For Sell");
  
  }

  accessoriesbuttonclicked = () =>{
    this.setState({buttoncolor1:"white"})
    this.setState({buttoncolor2:"primary"})
    this.setState({buttoncolor3:"white"})
    this.setState({buttonpurchase:"white"})
    this.setState({buttonrepair:"white"})
    this.setState({buttonreadyforsell:"primary"})
    this.setState({buttonsold:"white"})
    this.setState({buttonwastage:"white"})
    this.setState({tabs:2})   
    this.setState({tabsstatus:3})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})
    this.getaccessoriesstocklist("Ready For Sell");
    
  }

  purchasebuttonclicked = () =>{
    this.setState({buttonpurchase:"primary"})
    this.setState({buttonrepair:"white"})
    this.setState({buttonreadyforsell:"white"})
    this.setState({buttonsold:"white"})
    this.setState({buttonwastage:"white"})
    this.setState({tabsstatus:1})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})    

    if(this.state.tabs == 1){
      this.getphonestocklist("Purchase");
    }else if(this.state.tabs == 2){
      this.getaccessoriesstocklist("Purchase");
    }else if(this.state.tabs ==3){
      this.getsparepartstocklist("Purchase");
    }
    
  }

  repairbuttonclicked = () =>{
    this.setState({buttonpurchase:"white"})
    this.setState({buttonrepair:"primary"})
    this.setState({buttonreadyforsell:"white"})
    this.setState({buttonsold:"white"})
    this.setState({buttonwastage:"white"})
    this.setState({tabsstatus:2})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})    

    if(this.state.tabs == 1){
      this.getphonestocklist("Repair");
    }else if(this.state.tabs == 2){
      this.getaccessoriesstocklist("Repair");
    }else if(this.state.tabs ==3){
      this.getsparepartstocklist("Repair");
    }
  }
  
  readyforsellbuttonclicked = () =>{
    this.setState({buttonpurchase:"white"})
    this.setState({buttonrepair:"white"})
    this.setState({buttonreadyforsell:"primary"})
    this.setState({buttonsold:"white"})
    this.setState({buttonwastage:"white"})
    this.setState({tabsstatus:3})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})    

    if(this.state.tabs == 1){
      this.getphonestocklist("Ready For Sell");
    }else if(this.state.tabs == 2){
      this.getaccessoriesstocklist("Ready For Sell");
    }else if(this.state.tabs ==3){
      this.getsparepartstocklist("Ready For Sell");
    }
  }

  soldbuttonclicked = () =>{
    this.setState({buttonpurchase:"white"})
    this.setState({buttonrepair:"white"})
    this.setState({buttonreadyforsell:"white"})
    this.setState({buttonsold:"primary"})
    this.setState({buttonwastage:"white"})
    this.setState({tabsstatus:4})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})    

    if(this.state.tabs == 1){
      this.getphonestocklist("Sold");
    }else if(this.state.tabs == 2){
      this.getaccessoriesstocklist("Sold");
    }else if(this.state.tabs ==3){
      this.getsparepartstocklist("Sold");
    }
  }

  wastagebuttonclicked = ()=>{
    this.setState({buttonpurchase:"white"})
    this.setState({buttonrepair:"white"})
    this.setState({buttonreadyforsell:"white"})
    this.setState({buttonsold:"white"})
    this.setState({buttonwastage:"primary"})
    this.setState({tabsstatus:5})
    this.setState({searchtextvalue:''})
    this.setState({currentcompletedpagenumber:1})    

    if(this.state.tabs == 1){
      this.getphonestocklist("Wastage");
    }else if(this.state.tabs == 2){
      this.getaccessoriesstocklist("Wastage");
    }else if(this.state.tabs ==3){
      this.getsparepartstocklist("Wastage");
    }
  }
  
  functionselectshipmenttype =(shippername2)=>{
       
    var data = this.state.quotationtabledatafinal.filter(item => item.shippername === shippername2)
   
    this.setState({type1dropdowntext:''})
    this.setState({type1dropdowntext1:''})
    this.setState({type1dropdowntext2:''})
    this.setState({type1dropdowntext3:''})
    this.setState({type1dropdowntext4:''})
    this.setState({type1dropdowntext5:''})
    this.setState({type1dropdowntext6:''})
    this.setState({type1dropdowntext7:''})
    this.setState({type1dropdowntext8:''})
    this.setState({type1dropdowntext9:''})
    
    if(data[0] != null){
      
      this.setState({
        areadropdowntext:shippername2,
        deliveryaddress:data[0].address,
        areatextbox:data[0].area,
        picvalue:data[0].picvalue,
        contactvalue:data[0].contactvalue,
      })

      if(data[0].picvalue == ""){
        this.setState({picvalue:"-"});
      }
      if(data[0].contactvalue ==""){
        this.setState({contactvalue:"-"});
      }
    }

    if(shippername2 ==""){
      this.setState({
        areadropdowntext:"",
        deliveryaddress:"",
        areatextbox:"",
        picvalue:"",
        contactvalue:"",
        
      })
    }
   
    console.log(shippername2)
    console.log(this.state.poltext)
    console.log(this.state.podtext)

    if(this.state.shipmenttypeadropdowntext =='Export'){
     this.getcontainertypelist(this.state.poltext,shippername2,this.state.shipmenttypeadropdowntext)
    } else if(this.state.shipmenttypeadropdowntext =='Import'){
     this.getcontainertypelist(this.state.podtext,shippername2,this.state.shipmenttypeadropdowntext)
    } else{

    }
  }

  showmodalapifunctioncalldetail = (rotnumber,no) =>{
    this.setState({["loadingmodalpending" + no]:true})
    console.log(rotnumber)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("rotnumber", rotnumber);
    let link = this.state.linkurl + 'mode=admin-getrotdetailpendingdata'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
            this.setState({rotnumbermodalpending:rotnumber})
            console.log(json)
            this.setState({["detailpendinglist"+no]:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

              this.setState({
                shipper:json.user[i].Shipper,
                bookingno:json.user[i].bookingno,
                pol:json.user[i].POL,
                pod:json.user[i].POD,
              })
              //status need do
               this.setState({["detailpendinglist"+no]:[...this.state["detailpendinglist"+no],
                { no:i+1,
                  consignmentno:json.user[i].Consignmentno,
                  containerno:json.user[i].Containerno,
                  requiredate:json.user[i].Requestdate-28800,
                  closedate:json.user[i].Closingday-28800,
                  type:json.user[i].Type,
                },            
              ]})
              
              
             
               console.log(this.state["detailpendinglist"+no])
             

             
              }
              this.setState({["loadingmodalpending" + no]:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  searchfunctionphone = (value) =>{   
    
    this.setState({buttonpurchase:"white"})
    this.setState({buttonrepair:"white"})
    this.setState({buttonreadyforsell:"white"})
    this.setState({buttonsold:"white"})
    this.setState({buttonwastage:"white"})
    this.setState({searchtextvalue:value})
    if(this.state.tabs == 1){
      this.setState({buttoncolor1:"primary"})
      this.setState({buttoncolor2:"white"})
      this.setState({buttoncolor3:"white"})
      this.setState({loading:true})

      var urlencoded = new URLSearchParams();    
     urlencoded.append("searchvalue",value);
     urlencoded.append("category","Phone");
     let link = this.state.linkurl + 'mode=admin-searchstocktablephone'
     fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({stockphonelist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
            this.setState({stockphonelist:[...this.state.stockphonelist,
              { no:i+1,
                ref:json.employee[i].Ref,
                productname:json.employee[i].productname,   
                imei:json.employee[i].imei,             
                storagevalue:json.employee[i].storagevalue,
                colorvalue:json.employee[i].colorvalue,
                suppliername:json.employee[i].suppliername,
                //fingerprintvalue:json.employee[i].fingerprintvalue,
                //brandvalue:json.employee[i].brandvalue,      
                //faceidvalue:json.employee[i].faceidvalue,
                saleprice:json.employee[i].saleprice,
                costprice:json.employee[i].costprice,
                //quantityvalue:json.employee[i].quantityvalue,
                purchasedate:purchasedateshow,
                statusvalue:json.employee[i].statusvalue,
              },            
            ]})
          
         }
         this.setState({loading:false})  
       }        
          
    else{
      this.setState({stockphonelist:[]}); 
      this.setState({loading:false}) 
      console.log(json)
    }
     })

    }else if(this.state.tabs == 2){
      this.setState({buttoncolor1:"white"})
      this.setState({buttoncolor2:"primary"})
      this.setState({buttoncolor3:"white"})
      this.setState({loading:true})

      var urlencoded = new URLSearchParams();    
     urlencoded.append("searchvalue",value);
    // urlencoded.append("category","Phone");
     let link = this.state.linkurl + 'mode=admin-searchstocktableaccessories'
     fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
       
       console.log(json)
       this.setState({stockaccessorieslist:[]});   
        var lengthemployee = Object.keys(json.employee).length;
        for(let i=0;i<lengthemployee;i++){
          var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
           this.setState({stockaccessorieslist:[...this.state.stockaccessorieslist,
             { no:i+1,
              ref:json.employee[i].Ref,
               productname:json.employee[i].productname,   
               typevalue:json.employee[i].typevalue,     
               brandvalue:json.employee[i].brandvalue,     
               saleprice:json.employee[i].saleprice,
               costprice:json.employee[i].costprice,
               quantityvalue:json.employee[i].quantityvalue,
               purchasedate:purchasedateshow,
               statusvalue:json.employee[i].statusvalue,
             },            
           ]})
         
          
         }
         this.setState({loading:false})  
       }        
          
       else{
        this.setState({stockaccessorieslist:[]});  
       this.setState({loading:false}) 
     console.log(json)
   }
     })
    }else if(this.state.tabs == 3){
      this.setState({buttoncolor1:"white"})
      this.setState({buttoncolor2:"white"})
      this.setState({buttoncolor3:"primary"})
      this.setState({loading:true})

      var urlencoded = new URLSearchParams();    
     urlencoded.append("searchvalue",value);
    // urlencoded.append("category","Phone");
     let link = this.state.linkurl + 'mode=admin-searchstocktablesparepart'
     fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){
        console.log(json.success)
        //console.log(json)
        if(json.success == 1){
         
         console.log(json)
         this.setState({stocksparepartlist:[]});   
          var lengthemployee = Object.keys(json.employee).length;
          for(let i=0;i<lengthemployee;i++){
            var purchasedateshow = Moment(json.employee[i].purchasedate*1000).format('DD/MM/YYYY')
             this.setState({stocksparepartlist:[...this.state.stocksparepartlist,
               { no:i+1,
                 ref:json.employee[i].Ref,
                 productname:json.employee[i].productname,   
                 typevalue:json.employee[i].typevalue,     
                 brandvalue:json.employee[i].brandvalue,     
                 saleprice:json.employee[i].saleprice,
                 costprice:json.employee[i].costprice,
                 quantityvalue:json.employee[i].quantityvalue,
                 purchasedate:purchasedateshow,
                 statusvalue:json.employee[i].statusvalue,
               },            
             ]})
           
            
           }
           this.setState({loading:false})  
         }        
            
      else{
        this.setState({stocksparepartlist:[]}); 
       this.setState({loading:false}) 
     console.log(json)
    }

     }})
    }
     
     
    
    //}
  
  
   
   }

   deletestockfunction = (id) =>{

    console.log("delete called")
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("userref", this.state.userid);
    let link = this.state.linkurl +'mode=admin-deletestockphone'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Delete Success"});
        
            if(this.state.tabsstatus == 1){
              this.purchasebuttonclicked();
            }else if(this.state.tabsstatus ==2){
              this.repairbuttonclicked();
            }else if(this.state.tabsstatus ==3 ){
              this.readyforsellbuttonclicked();
            }else if(this.state.tabsstatus ==4){
              this.soldbuttonclicked();
            }else if(this.state.tabsstatus ==5){
              this.wastagebuttonclicked();
            }        
          this.togglemessagemodal("messageboxmodalonoff")
          this.toggledeletemodal("deletemodalonoff",0)
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:"Delete Fail Please trry again"});
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
   }

  cancelledsearchbuttonclick = () =>{
    if(this.state.tabs == 1){
     this.setState({searchtextvalue:""})
     this.setState({currentcompletedpagenumber:1})
    
      this.phonebuttonclicked("Ready For Sell");    
     
    }else if( this.state.tabs ==2){
      this.setState({searchtextvalue:""})
      this.setState({currentcompletedpagenumber:1})
     
       this.accessoriesbuttonclicked("Ready For Sell");  
    }else if( this.state.tabs ==3){
      this.setState({searchtextvalue:""})
      this.setState({currentcompletedpagenumber:1})
     
       this.sparepartbuttonclicked("Ready For Sell");  
     }      
  }

  nextpaginationfunction = (e) =>{
    if(this.state.completelist.length/100 <= this.state.currentcompletedpagenumber){
      
    }else{
     this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber +1})
    }
  }
 
  previouspaginationfunction = (e) =>{
   if(this.state.currentcompletedpagenumber== 1){
     
   }else{
    this.setState({currentcompletedpagenumber:this.state.currentcompletedpagenumber - 1})
   }
  }

  tabbuttonclick = (number) =>{
    if(number == 1){
      //purchase
      this.setState({tabsstatus:1});
      if(this.state.selected)
      this.getpurchasetabledata();
    }
  }

  editstockfunction= (id)=>{  
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("userref", this.state.userid);
    urlencoded.append("changetostock", this.state.changetohowmanystock);
    let link = this.state.linkurl +'mode=admin-changestockquantity'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Update Success"});
        
            if(this.state.tabsstatus == 1){
              this.purchasebuttonclicked();
            }else if(this.state.tabsstatus ==2){
              this.repairbuttonclicked();
            }else if(this.state.tabsstatus ==3 ){
              this.readyforsellbuttonclicked();
            }else if(this.state.tabsstatus ==4){
              this.soldbuttonclicked();
            }else if(this.state.tabsstatus ==5){
              this.wastagebuttonclicked();
            }        
          this.togglemessagemodal("messageboxmodalonoff")
          this.setState({editstockmodalonoff:false})
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:"Delete Fail Please trry again"});
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }

  editdetailfunctionaccessories = (id) =>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("userref", this.state.userid);
    urlencoded.append("costpriceaccessories", this.state.costpriceaccessories);
    urlencoded.append("salepriceaccessories", this.state.salepriceaccessories);
    urlencoded.append("warrantycostaccessories", this.state.warrantycostaccessories);
    urlencoded.append("suppliervalueaccessories", this.state.suppliervalueaccessories);
    urlencoded.append("remarkaccessories", this.state.remarkaccessories);
    urlencoded.append("tradeinsupplieraccessories", this.state.tradeinsupplieraccessories);
    urlencoded.append("tradeininvoiceaccessories", this.state.tradeininvoiceaccessories);

    let link = this.state.linkurl +'mode=admin-updateaccessoriesdetail'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Update Success"});
        
            if(this.state.tabsstatus == 1){
              this.purchasebuttonclicked();
            }else if(this.state.tabsstatus ==2){
              this.repairbuttonclicked();
            }else if(this.state.tabsstatus ==3 ){
              this.readyforsellbuttonclicked();
            }else if(this.state.tabsstatus ==4){
              this.soldbuttonclicked();
            }else if(this.state.tabsstatus ==5){
              this.wastagebuttonclicked();
            }        
          this.togglemessagemodal("messageboxmodalonoff")
          this.setState({editmodalonoffaccessories:false})
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:"Update Fail Please try again"});
      this.togglemessagemodal("messageboxmodalonoff")
      this.setState({loading:false}) 
      console.log(json)
    }
     })
  }

  editdetailfunctionsparepart = (id) =>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("userref", this.state.userid);
    urlencoded.append("costpricesparepart", this.state.costpricesparepart);
    urlencoded.append("salepricesparepart", this.state.salepricesparepart);
    urlencoded.append("warrantycostsparepart", this.state.warrantycostsparepart);
    urlencoded.append("suppliervaluesparepart", this.state.suppliervaluesparepart);
    urlencoded.append("remarksparepart", this.state.remarksparepart);
    urlencoded.append("tradeinsuppliersparepart", this.state.tradeinsuppliersparepart);
    urlencoded.append("tradeininvoicesparepart", this.state.tradeininvoicesparepart);

    let link = this.state.linkurl +'mode=admin-updatesparepartdetail'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Update Success"});
        
            if(this.state.tabsstatus == 1){
              this.purchasebuttonclicked();
            }else if(this.state.tabsstatus ==2){
              this.repairbuttonclicked();
            }else if(this.state.tabsstatus ==3 ){
              this.readyforsellbuttonclicked();
            }else if(this.state.tabsstatus ==4){
              this.soldbuttonclicked();
            }else if(this.state.tabsstatus ==5){
              this.wastagebuttonclicked();
            }        
          this.togglemessagemodal("messageboxmodalonoff")
          this.setState({editmodalonoffsparepart:false})
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:"Update Fail Please try again"});
      this.togglemessagemodal("messageboxmodalonoff")
      this.setState({loading:false}) 
      console.log(json)
    }
     })
  }

  editdetailfunctionphone = (id) =>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("userref", this.state.userid);

    urlencoded.append("brandvaluephone", this.state.brandvaluephone);
    urlencoded.append("colorvaluephone", this.state.colorvaluephone);
    urlencoded.append("storagevaluephone", this.state.storagevaluephone);
    urlencoded.append("fingerprintvaluephone", this.state.fingerprintvaluephone);
    urlencoded.append("faceidvaluephone", this.state.faceidvaluephone);
    urlencoded.append("costpricephone", this.state.costpricephone);
    urlencoded.append("salepricephone", this.state.salepricephone);
    urlencoded.append("warrantycostphone", this.state.warrantycostphone);
    urlencoded.append("repairbywhophone", this.state.repairbywhophone);
    urlencoded.append("repaircostphone", this.state.repaircostphone);   
    urlencoded.append("suppliervaluephone", this.state.suppliervaluephone);
    urlencoded.append("remarkphone", this.state.remarkphone);
    urlencoded.append("tradeininvoicephone", this.state.tradeininvoicephone);
    urlencoded.append("tradeinsupplierphone", this.state.tradeinsupplierphone);

    if(this.state.purchasedatephone == ""){

    }else{
      var purchasedatevalue = Moment(this.state.purchasedatephone, 'DD/MM/YYYY').unix(); 
      var finalpurchasedatevalue = purchasedatevalue
      urlencoded.append("purchasedatephone",finalpurchasedatevalue);
    }
   
    
    console.log("repairdate: " + this.state.repairdatephone)
    if(this.state.repairdatephone == ""){

    }else{
      var repairdatephonevalue = Moment(this.state.repairdatephone, 'DD/MM/YYYY').unix(); 
      var finalrepairdatephonevalue = repairdatephonevalue
      urlencoded.append("repairdatephone",finalrepairdatephonevalue);
      console.log("repairdatephonevalue: " + finalrepairdatephonevalue)
    }

    let link = this.state.linkurl +'mode=admin-updatedetailphone'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Update Success"});
        
            if(this.state.tabsstatus == 1){
              this.purchasebuttonclicked();
            }else if(this.state.tabsstatus ==2){
              this.repairbuttonclicked();
            }else if(this.state.tabsstatus ==3 ){
              this.readyforsellbuttonclicked();
            }else if(this.state.tabsstatus ==4){
              this.soldbuttonclicked();
            }else if(this.state.tabsstatus ==5){
              this.wastagebuttonclicked();
            }        
          this.togglemessagemodal("messageboxmodalonoff")
          this.setState({editdetailfunctionphone:false})
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:"Update Fail Please try again"});
      this.togglemessagemodal("messageboxmodalonoff")
      this.setState({loading:false}) 
      console.log(json)
    }
     })
  }

  editstatusfunction= (id)=>{  
    
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("userref", this.state.userid);
    urlencoded.append("changestatusvalue", this.state.changestatusvalue);
    urlencoded.append("howmanychangetostatus", this.state.howmanychangetostatus);
    urlencoded.append("maxquantity", this.state.selectedquantityvalue);
    urlencoded.append("selectedproductname", this.state.selectedproductname);
    urlencoded.append("selectedtypevalue", this.state.selectedtypevalue);
    urlencoded.append("selectedbrandvalue", this.state.selectedbrandvalue);
    urlencoded.append("selectedcostprice", this.state.selectedcostprice);
    
    let link = this.state.linkurl +'mode=admin-changestatusvalue'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Update Success"});
        
            if(this.state.tabsstatus == 1){
              this.purchasebuttonclicked();
            }else if(this.state.tabsstatus ==2){
              this.repairbuttonclicked();
            }else if(this.state.tabsstatus ==3 ){
              this.readyforsellbuttonclicked();
            }else if(this.state.tabsstatus ==4){
              this.soldbuttonclicked();
            }else if(this.state.tabsstatus ==5){
              this.wastagebuttonclicked();
            }        
          this.togglemessagemodal("messageboxmodalonoff")
          this.setState({editstatusmodalonoff:false})
          this.setState({loading:false}) 
      }  
    else{
      this.setState({messageformessagebox:"Delete Fail Please try again"});
     this.setState({loading:false}) 
   console.log(json)
 }
     })
  
  }

  editstatusphonefunction =(id)=>{
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("userref", this.state.userid);
    urlencoded.append("changestatusvalue", this.state.changestatusvalue);
   
    let link = this.state.linkurl +'mode=admin-changestatusvaluephone'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      //console.log(json)
      if(json.success == 1){      
          this.setState({messageformessagebox:"Update Success"});
        
            if(this.state.tabsstatus == 1){
              this.purchasebuttonclicked();
            }else if(this.state.tabsstatus ==2){
              this.repairbuttonclicked();
            }else if(this.state.tabsstatus ==3 ){
              this.readyforsellbuttonclicked();
            }else if(this.state.tabsstatus ==4){
              this.soldbuttonclicked();
            }else if(this.state.tabsstatus ==5){
              this.wastagebuttonclicked();
            }        
          this.togglemessagemodal("messageboxmodalonoff")
          this.setState({editstatusphonemodalonoff:false})
          this.setState({loading:false}) 
      }  
    else{
          this.setState({messageformessagebox:"Delete Fail Please try again"});
        this.setState({loading:false}) 
      console.log(json)
    }
     })
  }
  selectstorage =() =>{
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectgb'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({storagelist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){
            
                this.setState({storagelist:[...this.state.storagelist,
                  { no:i+1,
                    username:json.employee[i].phonestorage,
                    title:json.employee[i].phonestorage,
                    value:json.employee[i].phonestorage,
                  },            
                ]})
              
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  selectcolorlist =() =>{ 
    this.setState({loading:true})
    var urlencoded = new URLSearchParams();
    urlencoded.append("empty", '-');
    let link = this.state.linkurl +'mode=admin-selectcolorlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({colorlist:[]});   
             var lengthemployee = Object.keys(json.employee).length;
             for(let i=0;i<lengthemployee;i++){
                this.setState({colorlist:[...this.state.colorlist,
                  { no:i+1,
                    username:json.employee[i].phonecolor,
                    title:json.employee[i].phonecolor,
                    value:json.employee[i].phonecolor,
                  },            
                ]})
              
               
              }
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  


  //tillbutton and show table
  render() {
    return (
      <>
        <Header />
        {/* Page contentas */}
        <Container className=" mt--6" fluid>
          {/* Table */}
          <Row>
            <div className=" col-12 ">
        
        <Col md="12  mt-3">
        {window.innerWidth <= 760 ?
                <div className="d-md-inline-block">
                <NavLink className="nav-link-icon d-md-inline-block col-12" to="/admin/addnewrot" tag={Link}>
              {/* <Button className="col-12" color="default" type="button" >
                + Add New Stock
              </Button> */}
              </NavLink>
                <NavLink className="nav-link-icon d-md-inline-block col-12 mt-3" >
              <Button  className={classnames("col-12")} color={this.state.buttoncolor1} type="button"  onClick={(e)=>this.phonebuttonclicked()}>
              <i className="ni ni-planet mr-2" />
              Phone
              </Button>
              </NavLink>
                <NavLink className="nav-link-icon d-md-inline-block col-12" >
              <Button  className={classnames("col-12")} color={this.state.buttoncolor2} type="button"  onClick={(e)=> this.accessoriesbuttonclicked() }>
              <i className="ni ni-check-bold mr-2" />
              Accessories
              </Button>
              </NavLink>
                <NavLink className="nav-link-icon d-md-inline-block col-12">
              <Button  className={classnames("col-12")} color={this.state.buttoncolor3} type="button"  onClick={(e)=>this.sparepartbuttonclicked()}>
               <i className="ni ni-collection  mr-2" />
               Sparepart
              </Button>
              </NavLink>
              
                </div>
         :
<div>
      <NavLink className="nav-link-icon" to="/admin/addnewrot" tag={Link}>
       {/* <Button className="my-4" color="default" type="button" style={{marginLeft:-10}}>
         + Add New Stock
       </Button> */}
       </NavLink>
         <div className="d-md-inline-block">
         <NavLink className="nav-link-icon d-md-inline-block ">
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor1} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.phonebuttonclicked()}>
        <i className="ni ni-collection  mr-2" />
          Phone
       </Button>
       </NavLink>
       <NavLink className="nav-link-icon d-md-inline-block" >
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor2} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.accessoriesbuttonclicked()}>
       <i className="ni ni-planet mr-2" />
          Accessories
       </Button>
       </NavLink>
       <NavLink className="nav-link-icon d-md-inline-block" >
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttoncolor3} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=> this.sparepartbuttonclicked() }>
       <i className="ni ni-check-bold mr-2" />
          Sparepart
       </Button>
       </NavLink>
         </div>
  
        
       </div>
        }

                
        </Col>
            </div>
            <div className=" col-12 ">
              <Card className=" shadow  ">
              
              <Row>
                <Col >

                <div className=" col-12 d-md-inline-block ">
                <div className="nav-wrapper ">
              
                <Col md="12  mt-3">
        {window.innerWidth <= 760 ?
                <div className="d-md-inline-block">               
                <NavLink className="nav-link-icon d-md-inline-block col-12 mt-3" >
              <Button  className={classnames("col-12")} color={this.state.buttonpurchase} type="button"  onClick={(e)=>this.purchasebuttonclicked()}>
              <i className="ni ni-planet mr-2" />
                 Purchase
              </Button>
              </NavLink>
                <NavLink className="nav-link-icon d-md-inline-block col-12" >
              <Button  className={classnames("col-12")} color={this.state.buttonrepair} type="button"  onClick={(e)=> this.repairbuttonclicked() }>
              <i className="ni ni-check-bold mr-2" />
                 Repair
              </Button>
              </NavLink>
                <NavLink className="nav-link-icon d-md-inline-block col-12">
              <Button  className={classnames("col-12")} color={this.state.buttonreadyforsell} type="button"  onClick={(e)=>this.readyforsellbuttonclicked()}>
               <i className="ni ni-collection  mr-2" />
                 Ready For Sell
              </Button>
              </NavLink>
              <NavLink className="nav-link-icon d-md-inline-block col-12">
              <Button  className={classnames("col-12")} color={this.state.buttonsold} type="button"  onClick={(e)=>this.soldbuttonclicked()}>
               <i className="ni ni-collection  mr-2" />
                 Sold
              </Button>
              </NavLink>
              <NavLink className="nav-link-icon d-md-inline-block col-12">
              <Button  className={classnames("col-12")} color={this.state.buttonwastage} type="button"  onClick={(e)=>this.wastagebuttonclicked()}>
               <i className="ni ni-collection  mr-2" />
                 Wastage
              </Button>
              </NavLink>
                </div>
         :
            <div>
     
         <div className="d-md-inline-block">
         <NavLink className="nav-link-icon d-md-inline-block ">
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttonpurchase} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.purchasebuttonclicked()}>
        <i className="ni ni-collection  mr-2" />
        Purchase
       </Button>
       </NavLink>
       <NavLink className="nav-link-icon d-md-inline-block" >
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttonrepair} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=>this.repairbuttonclicked("Import")}>
       <i className="ni ni-planet mr-2" />
       Repair
       </Button>
       </NavLink>
       <NavLink className="nav-link-icon d-md-inline-block" >
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttonreadyforsell} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=> this.readyforsellbuttonclicked() }>
       <i className="ni ni-cart mr-2" />
       Ready For Sell
       </Button>
       </NavLink>
       <NavLink className="nav-link-icon d-md-inline-block" >
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttonsold} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=> this.soldbuttonclicked() }>
       <i className="ni ni-chart-bar-32 mr-2" />
       Sold
       </Button>
       </NavLink>
       <NavLink className="nav-link-icon d-md-inline-block" >
       <Button  className={classnames("mb-sm-3 mb-md-0")} color={this.state.buttonwastage} type="button" style={{marginTop:-20,marginLeft:-10}} onClick={(e)=> this.wastagebuttonclicked() }>
       <i className="ni ni-compass-04 mr-2" />
       Wastage
       </Button>
       </NavLink>
         </div>
  
        
       </div>
        }

                
        </Col>
            
            
            </div>


                </div>
                
               
                </Col>

                <Col md="4 form-inline  d-md-flex">
             
              
                 {/* Searchbar is here */}
                   {/* till here */}
                   {window.innerWidth <= 760 ?
                <InputGroup className="col-11 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>                   
                      <i className="fas fa-search" />                     
                    </InputGroupText>
                  </InputGroupAddon>
                
                    <Input
                      placeholder="Search" 
                      value={this.state.searchtextvalue}
                      onChange = {(event) => this.searchfunctionphone(event.target.value)}
                      type="text"
                      //onKeyPress={(e) => {this.handleKeyPress(e) && e.preventDefault();}}
                    />
                  
                 {this.state.searchtextvalue == "" ?
                 null:
                 <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                 <Button onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</Button>
                                  
              
                 </InputGroupText>
               </InputGroupAddon>
                 }
                    
                     
                  
                </InputGroup>
             :
             <InputGroup className="col-10 mr-3  ml-lg-auto alignmiddletbutton input-group-alternative">
             <InputGroupAddon addonType="prepend">
                 <InputGroupText>
                
                   <i className="fas fa-search" />
                  
                 </InputGroupText>
               </InputGroupAddon>
             
                 <Input
                   placeholder="Search" 
                   value={this.state.searchtextvalue}
                   onChange = {(event) => this.searchfunctionphone(event.target.value)}
                   type="text"
                
                 />
               
              {this.state.searchtextvalue == "" ?
              null:
              <InputGroupAddon addonType="prepend">
              <InputGroupText>
              <span onClick={(e)=>this.cancelledsearchbuttonclick()}  className="cancelledbuttonsearch">X</span>
                               
            
              </InputGroupText>
            </InputGroupAddon>
              }
                 
                  
               
             </InputGroup>
             }



                </Col>
              </Row>
             
             
            
         
            
              {this.state.loading
              ?
              <Row >
                <Col md="12" >
              <img 
              className="img-center mt-5"
              width="120"
              height="120"
              alt="..."
              src={require("./../../assets/gif/lorrygif3.gif")}
            />
            </Col>
              </Row>
              :
              <CardBody >
               
                <TabContent  activeTab={"tabs" + this.state.tabs + "andtabsstatus"+ this.state.tabsstatus}>
                  {/*Purchase*/}
                  <TabPane tabId="tabs1andtabsstatus1">
                
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                  
                 <tr>
                   <th>No.</th>
                   <th >Product</th>
                   <th  >IMEI</th>    
                   <th  >Storage</th>                    
                   <th>Color</th>
                   <th >Price</th>
                   <th >Supplier</th>
                   <th >Purchase Date</th>
                   <th >Status</th>
                   {/* <th scope="col">Remark</th> */}
                   <th >Action</th>
                   <th scope="col" />
                 </tr>
               </thead>
                   
                   {this.renderPhonelist()}                       
                   </Table>
                  
             
                  </TabPane>
                    
                  <TabPane tabId="tabs2andtabsstatus1">
                
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                    <tr>
                    <th>No.</th>
                   <th >Product</th>
                   <th >Type</th> 
                   <th  >Brand</th>    
                   <th  >Price</th>  
                   
                   <th>Quantity</th>
                   <th >Purchase Date</th> 
                   <th >Status</th>
                      <th >Action</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                                       
                      {this.renderAccessorieslist()} 
                      
                      </Table>
                    
        
                  </TabPane>

                  <TabPane tabId="tabs3andtabsstatus1">
                
                  <Table className="align-items-center" responsive>
                  <thead className="thead-light">
                  <tr>
                    <th>No.</th>
                   <th >Product</th>
                   <th >Type</th> 
                   <th  >Brand</th>    
                   <th  >Price</th>  
                   
                   <th>Quantity</th>
                   <th >Purchase Date</th> 
                   <th >Status</th>
                      <th >Action</th>
                      <th scope="col" />
                    </tr>
                  
                  </thead>
                      
                      {this.rendersparepartlist()}  
                      </Table>
                 
                
                      {/* {this.renderTablePagination()} */}
                
                      
                  </TabPane>


                    {/*Repair*/}
                    <TabPane tabId="tabs1andtabsstatus2">
                
                <Table className="align-items-center" responsive>
                <thead className="thead-light">
                
               <tr>
                 <th>No.</th>
                 <th >Product</th>
                 <th  >IMEI</th>    
                 <th  >Storage</th>                    
                 <th>Color</th>
                 <th >Price</th>
                 <th >Supplier</th>
                 <th >Purchase Date</th>
                 <th >Status</th>
                 {/* <th scope="col">Remark</th> */}
                 <th >Action</th>
                 <th scope="col" />
               </tr>
             </thead>
                 
                 {this.renderPhonelist()}                       
                 </Table>
                
           
                </TabPane>
                  
                <TabPane tabId="tabs2andtabsstatus2">
              
                <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                  <th>No.</th>
                 <th >Product</th>
                 <th >Type</th> 
                 <th  >Brand</th>    
                 <th  >Price</th>  
                 
                 <th>Quantity</th>
                 <th >Purchase Date</th> 
                 <th >Status</th>
                    <th >Action</th>
                    <th scope="col" />
                  </tr>
                </thead>
                                     
                    {this.renderAccessorieslist()} 
                    
                    </Table>
                  
      
                </TabPane>

                <TabPane tabId="tabs3andtabsstatus2">
              
                <Table className="align-items-center" responsive>
                <thead className="thead-light">
                <tr>
                  <th>No.</th>
                 <th >Product</th>
                 <th >Type</th> 
                 <th  >Brand</th>    
                 <th  >Price</th>  
                 
                 <th>Quantity</th>
                 <th >Purchase Date</th> 
                 <th >Status</th>
                    <th >Action</th>
                    <th scope="col" />
                  </tr>
                
                </thead>
                    
                    {this.rendersparepartlist()}  
                    </Table>
               
              
                    {/* {this.renderTablePagination()} */}
              
                    
                </TabPane>

                {/*Ready For Sell*/}
                <TabPane tabId="tabs1andtabsstatus3">
                                
                                <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                
                              <tr>
                                <th>No.</th>
                                <th >Product</th>
                                <th  >IMEI</th>    
                                <th  >Storage</th>                    
                                <th>Color</th>
                                <th >Price</th>
                                <th >Supplier</th>
                                <th >Purchase Date</th>
                                <th >Status</th>
                                {/* <th scope="col">Remark</th> */}
                                <th >Action</th>
                                <th scope="col" />
                              </tr>
                            </thead>
                                
                                {this.renderPhonelist()}                       
                                </Table>
                                
                          
                                </TabPane>
                                  
                <TabPane tabId="tabs2andtabsstatus3">
              
                <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                  <th>No.</th>
                 <th >Product</th>
                 <th >Type</th> 
                 <th  >Brand</th>    
                 <th  >Price</th>  
                 
                 <th>Quantity</th>
                 <th >Purchase Date</th> 
                 <th >Status</th>
                    <th >Action</th>
                    <th scope="col" />
                  </tr>
                </thead>
                                     
                    {this.renderAccessorieslist()} 
                    
                    </Table>
                  
      
                </TabPane>

                <TabPane tabId="tabs3andtabsstatus3">
              
                <Table className="align-items-center" responsive>
                <thead className="thead-light">
                <tr>
                  <th>No.</th>
                 <th >Product</th>
                 <th >Type</th> 
                 <th  >Brand</th>    
                 <th  >Price</th>  
                 
                 <th>Quantity</th>
                 <th >Purchase Date</th> 
                 <th >Status</th>
                    <th >Action</th>
                    <th scope="col" />
                  </tr>
                
                </thead>
                    
                    {this.rendersparepartlist()}  
                    </Table>
               
              
                    {/* {this.renderTablePagination()} */}
              
                    
                </TabPane>



                {/*Sold*/}
                <TabPane tabId="tabs1andtabsstatus4">
                                
                                <Table className="align-items-center" responsive>
                                <thead className="thead-light">
                                
                              <tr>
                                <th>No.</th>
                                <th >Product</th>
                                <th  >IMEI</th>    
                                <th  >Storage</th>                    
                                <th>Color</th>
                                <th >Price</th>
                                <th >Supplier</th>
                                <th >Purchase Date</th>
                                <th >Status</th>
                                {/* <th scope="col">Remark</th> */}
                                <th >Action</th>
                                <th scope="col" />
                              </tr>
                            </thead>
                                
                                {this.renderPhonelist()}                       
                                </Table>
                                
                          
                                </TabPane>
                                  
                <TabPane tabId="tabs2andtabsstatus4">
              
                <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                  <th>No.</th>
                 <th >Product</th>
                 <th >Type</th> 
                 <th  >Brand</th>    
                 <th  >Price</th>  
                 
                 <th>Quantity</th>
                 <th >Purchase Date</th> 
                 <th >Status</th>
                    <th >Action</th>
                    <th scope="col" />
                  </tr>
                </thead>
                                     
                    {this.renderAccessorieslist()} 
                    
                    </Table>
                  
      
                </TabPane>

                <TabPane tabId="tabs3andtabsstatus4">
              
                <Table className="align-items-center" responsive>
                <thead className="thead-light">
                <tr>
                  <th>No.</th>
                 <th >Product</th>
                 <th >Type</th> 
                 <th  >Brand</th>    
                 <th  >Price</th>  
                 
                 <th>Quantity</th>
                 <th >Purchase Date</th> 
                 <th >Status</th>
                    <th >Action</th>
                    <th scope="col" />
                  </tr>
                
                </thead>
                    
                    {this.rendersparepartlist()}  
                    </Table>
               
              
                    {/* {this.renderTablePagination()} */}
              
                    
                </TabPane>


                {/*Wastage*/}
                <TabPane tabId="tabs1andtabsstatus5">
                                              
                                              <Table className="align-items-center" responsive>
                                              <thead className="thead-light">
                                              
                                            <tr>
                                              <th>No.</th>
                                              <th >Product</th>
                                              <th  >IMEI</th>    
                                              <th  >Storage</th>                    
                                              <th>Color</th>
                                              <th >Price</th>
                                              <th >Supplier</th>
                                              <th >Purchase Date</th>
                                              <th >Status</th>
                                              {/* <th scope="col">Remark</th> */}
                                              <th >Action</th>
                                              <th scope="col" />
                                            </tr>
                                          </thead>
                                              
                                              {this.renderPhonelist()}                       
                                              </Table>
                                              
                                        
                                              </TabPane>
                                                
                <TabPane tabId="tabs2andtabsstatus5">
              
                <Table className="align-items-center" responsive>
                <thead className="thead-light">
                  <tr>
                  <th>No.</th>
                 <th >Product</th>
                 <th >Type</th> 
                 <th  >Brand</th>    
                 <th  >Price</th>  
                 
                 <th>Quantity</th>
                 <th >Purchase Date</th> 
                 <th >Status</th>
                    <th >Action</th>
                    <th scope="col" />
                  </tr>
                </thead>
                                     
                    {this.renderAccessorieslist()} 
                    
                    </Table>
                  
      
                </TabPane>

                <TabPane tabId="tabs3andtabsstatus5">
              
                <Table className="align-items-center" responsive>
                <thead className="thead-light">
                <tr>
                  <th>No.</th>
                 <th >Product</th>
                 <th >Type</th> 
                 <th  >Brand</th>    
                 <th  >Price</th>  
                 
                 <th>Quantity</th>
                 <th >Purchase Date</th> 
                 <th >Status</th>
                    <th >Action</th>
                    <th scope="col" />
                  </tr>
                
                </thead>
                    
                    {this.rendersparepartlist()}  
                    </Table>
               
              
                    {/* {this.renderTablePagination()} */}
              
                    
                </TabPane>


                </TabContent>
              
               
              </CardBody>
                  }

                  
             
                
              </Card>
            </div>
          </Row>
          
        </Container>
        
        {/*delete confirm*/}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.deletemodalonoff}
          toggle={() => this.toggledeletemodal("deletemodalonoff")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="deletemodalonoff">
              Confirm
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggledeletemodal("deletemodalonoff")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">Are you sure you want to delete this stock?</div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggledeletemodal("deletemodalonoff")}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.deletestockfunction.bind(this,this.state.selectedjobid)}>
              Confirm
            </Button>
          </div>
        </Modal>
     
      {/*Message*/}
      <Modal
          className="modal-dialog-centered"
          isOpen={this.state.messageboxmodalonoff}
          toggle={() => this.togglemessagemodal("messageboxmodalonoff")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="messageboxmodalonoff">
              Message
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.togglemessagemodal("messageboxmodalonoff")}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">{this.state.messageformessagebox}</div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.togglemessagemodal("messageboxmodalonoff")}
            >
              Close
            </Button>
         
          </div>
        </Modal>
     
      {/*Edit Stock Modal*/}
      <Modal
          className="modal-dialog-centered"
          isOpen={this.state.editstockmodalonoff}
          toggle={() => this.setState({editstockmodalonoff:false})}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="editstockmodalonoff">
              Edit Stock
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editstockmodalonoff:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">              
      <h5>
         Product Name: {this.state.selectedproductname}
            </h5>   
            <h5>
         Type: {this.state.selectedtypevalue}
            </h5>  
            <h5>
         Brand: {this.state.selectedbrandvalue}
            </h5> 
            <h5>
         Status: {this.state.selectedstatusvalue}
            </h5>   
          <h5>
          Now Stock: {this.state.selectedquantityvalue}
            </h5>
            <h5>
          Change to:
            </h5>
            <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({changetohowmanystock: e.target.value})}
                          value={this.state.changetohowmanystock}
                          
                        />
            
         </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editstockmodalonoff:false})}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.editstockfunction.bind(this,this.state.selectedjobid)}>
              Submit
            </Button>
          </div>
        </Modal>
     

      {/*Edit Detail Modal Accessories*/}
      <Modal
          className="modal-dialog-centered"
          isOpen={this.state.editmodalonoffaccessories}
          toggle={() => this.setState({editmodalonoffaccessories:false})}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="editmodalonoffaccessories">
              Edit Detail
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editmodalonoffaccessories:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">              
      <h5>
         Product Name: {this.state.productnameaccessories}
            </h5>
            <h5>
         Type: {this.state.typevalueaccessories}
            </h5>
            <h5>
         Brand: {this.state.brandvalueaccessories}
            </h5>           
            <h5>
          Quantity: {this.state.quantityvalueaccessories}
            </h5>
            <h5>
          Status: {this.state.statusvalueaccessories}
            </h5>
            <h5>
         Create Date: {this.state.datecreateaccessories}
            </h5>   
            <h5>
         Purchase Date: {this.state.purchasedataaccessories}
            </h5>  

            <h5>
         Cost Price: 
            </h5> 
                      <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({costpriceaccessories: e.target.value})}
                          value={this.state.costpriceaccessories}
                          
                        />
            <h5>
         Sale Price: 
            </h5>
            <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({salepriceaccessories: e.target.value})}
                          value={this.state.salepriceaccessories}
                          
                        />
            <h5>
         Warranty Cost: 
            </h5>
            <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({warrantycostaccessories: e.target.value})}
                          value={this.state.warrantycostaccessories}
                          
                        />
            <h5>
         Supplier:
            </h5>   
            <Autocomplete
                        options={this.state.supplierlistaccessories}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({suppliervalueaccessories:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.suppliervalueaccessories} variant="outlined"  />}  
                       disableClearable={true}
                      />          
            <h5>
         Remark:
            </h5>
            <Input
                          type="text"
                          onChange={e => this.setState({remarkaccessories: e.target.value})}
                          value={this.state.remarkaccessories}
                          
                        />
            <h5>
         Trade In Supplier:
            </h5> 
            <Input
                          type="text"
                          onChange={e => this.setState({tradeinsupplieraccessories: e.target.value})}
                          value={this.state.tradeinsupplieraccessories}
                          
                        />
            <h5>
         Trade In Invoice: 
            </h5>    
            <Input
                          type="text"
                          onChange={e => this.setState({tradeininvoiceaccessories: e.target.value})}
                          value={this.state.tradeininvoiceaccessories}
                          
                        />
        
           
            
         </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editmodalonoffaccessories:false})}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.editdetailfunctionaccessories.bind(this,this.state.selectedjobid)}>
              Submit
            </Button>
          </div>
        </Modal>
     

      {/*Edit Detail Modal Sparepart*/}
      <Modal
          className="modal-dialog-centered"
          isOpen={this.state.editmodalonoffsparepart}
          toggle={() => this.setState({editmodalonoffsparepart:false})}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="editmodalonoffsparepart">
              Edit Detail
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editmodalonoffsparepart:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">              
      <h5>
         Product Name: {this.state.productnamesparepart}
            </h5>
            <h5>
         Type: {this.state.typevaluesparepart}
            </h5>
            <h5>
         Brand: {this.state.brandvaluesparepart}
            </h5>           
            <h5>
          Quantity: {this.state.quantityvaluesparepart}
            </h5>
            <h5>
          Status: {this.state.statusvaluesparepart}
            </h5>
            <h5>
         Create Date: {this.state.datecreatesparepart}
            </h5>   
            <h5>
         Purchase Date: {this.state.purchasedatasparepart}
            </h5>  

            <h5>
         Cost Price: 
            </h5> 
                      <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({costpricesparepart: e.target.value})}
                          value={this.state.costpricesparepart}
                          
                        />
            <h5>
         Sale Price: 
            </h5>
            <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({salepricesparepart: e.target.value})}
                          value={this.state.salepricesparepart}
                          
                        />
            <h5>
         Warranty Cost: 
            </h5>
            <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({warrantycostsparepart: e.target.value})}
                          value={this.state.warrantycostsparepart}
                          
                        />
            <h5>
         Supplier:
            </h5>   
            <Autocomplete
                        options={this.state.supplierlistsparepart}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({suppliervaluesparepart:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.suppliervaluesparepart} variant="outlined"  />}  
                       disableClearable={true}
                      />          
            <h5>
         Remark:
            </h5>
            <Input
                          type="text"
                          onChange={e => this.setState({remarksparepart: e.target.value})}
                          value={this.state.remarksparepart}
                          
                        />
            <h5>
         Trade In Supplier:
            </h5> 
            <Input
                          type="text"
                          onChange={e => this.setState({tradeinsuppliersparepart: e.target.value})}
                          value={this.state.tradeinsuppliersparepart}
                          
                        />
            <h5>
         Trade In Invoice: 
            </h5>    
            <Input
                          type="text"
                          onChange={e => this.setState({tradeininvoicesparepart: e.target.value})}
                          value={this.state.tradeininvoicesparepart}
                          
                        />
        
           
            
         </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editmodalonoffsparepart:false})}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.editdetailfunctionsparepart.bind(this,this.state.selectedjobid)}>
              Submit
            </Button>
          </div>
        </Modal>
   
      {/*Edit Status Accesssories Modal*/}
      <Modal
                className="modal-dialog-centered"
                isOpen={this.state.editstatusmodalonoff}
                toggle={() => this.setState({editstatusmodalonoff:false})}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="editstatusmodalonoff">
                    Edit Status
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.setState({editstatusmodalonoff:false})}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">              
            <h5>
              Product Name: {this.state.selectedproductname}
                  </h5>   
                  <h5>
              Type: {this.state.selectedtypevalue}
                  </h5>  
                  <h5>
              Brand: {this.state.selectedbrandvalue}
                  </h5> 
                  <h5>
              Quantity: {this.state.selectedquantityvalue}
                  </h5>   
                  <h5>
              Cost Price: {this.state.selectedcostprice}
                  </h5>   
                <h5>
                Now Status: {this.state.selectedstatusvalue}
                  </h5>
                  <h5>

                  <h5>
              How Many Quantity:
                  </h5>  
                  <Input
                                type="number"
                                min="0"
                                max={this.state.selectedquantityvalue}
                                onChange={e => this.setState({howmanychangetostatus: e.target.value})}
                                value={this.state.howmanychangetostatus}
                                
                              />

                Change to:
                  </h5>
                  <Autocomplete
                              options={[{title:"Purchase"},{title:"Repair"},{title:"Ready For Sell"},{title:"Sold"},{title:"Wastage"}]}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.setState({changestatusvalue:value})}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}  
                            disableClearable={true}
                            />    
                
                  
              </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.setState({editstockmodalonoff:false})}
                  >
                    Close
                  </Button>
                  <Button color="primary" type="button" onClick={this.editstatusfunction.bind(this,this.state.selectedjobid)}>
                    Submit
                  </Button>
                </div>
              </Modal>
          
       {/*Edit Status Phone Modal*/}
       <Modal
                className="modal-dialog-centered"
                isOpen={this.state.editstatusphonemodalonoff}
                toggle={() => this.setState({editstatusphonemodalonoff:false})}
              >
                <div className="modal-header">
                  <h5 className="modal-title" id="editstatusphonemodalonoff">
                    Edit Status
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.setState({editstatusphonemodalonoff:false})}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">              
            <h5>
              Product Name: {this.state.selectedproductname}
                  </h5>   
                  <h5>
              IMEI: {this.state.selectedimei}
                  </h5>  
                  <h5>
              Storage: {this.state.selectedstoragevalue}
                  </h5> 
                  <h5>
              Color: {this.state.selectedcolorvalue}
                  </h5>               
                <h5>
                Now Status: {this.state.selectedstatusvalue}
                  </h5>
                  <h5>
                Change to:
                  </h5>
                  <Autocomplete
                            options={[{title:"Purchase"},{title:"Repair"},{title:"Ready For Sell"},{title:"Sold"},{title:"Wastage"}]}
                              getOptionLabel={(option) => option.title}                       
                              style={{ flex:1,display:"block" }}
                              onInputChange={(event,value) =>this.setState({changestatusvalue:value})}
                              renderInput={(params) => <TextField {...params} label="" variant="outlined"  />}  
                            disableClearable={true}
                            />    
                
                  
              </div>
                <div className="modal-footer">
                  <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.setState({editstatusphonemodalonoff:false})}
                  >
                    Close
                  </Button>
                  <Button color="primary" type="button" onClick={this.editstatusphonefunction.bind(this,this.state.selectedjobid)}>
                    Submit
                  </Button>
                </div>
              </Modal>
          
         {/*Edit Detail Modal Accessories*/}
      <Modal
          className="modal-dialog-centered"
          isOpen={this.state.editdetailphonemodalonoff}
          toggle={() => this.setState({editdetailphonemodalonoff:false})}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="editdetailphonemodalonoff">
              Edit Detail
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editdetailphonemodalonoff:false})}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">   
          <h5>
         Create Date: {this.state.datecreateaccessories}
            </h5> 
         <h5>            
         Product Name: {this.state.productnamephone}
            </h5>
            <h5>
         IMEI: {this.state.imeiphone}
            </h5>
            <h5>
         Status: {this.state.statusvaluephone}
            </h5>
            <h5>
         Brand:
            </h5>   
            <Autocomplete
                        options={this.state.phonebrandlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({brandvaluephone:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.brandvaluephone} variant="outlined"  />}  
                       disableClearable={true}
                      />              

            <h5>
         Color:
            </h5>
            <Autocomplete
                        options={this.state.colorlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({colorvaluephone:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.colorvaluephone} variant="outlined"  />}  
                       disableClearable={true}
                      />  
           

             <h5>
         Storage:
            </h5>
            <Autocomplete
                        options={this.state.storagelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({storagevaluephone:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.storagevaluephone} variant="outlined"  />}  
                       disableClearable={true}
                      />  
            
            <h5>
          Finger Print:
            </h5>
            <Autocomplete
                        options={[{title:"Yes"},{title:"No"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({fingerprintvaluephone:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.fingerprintvaluephone} variant="outlined"  />}  
                       disableClearable={true}
                      />        

            <h5>
          Face ID:
            </h5>
            <Autocomplete
                        options={[{title:"Yes"},{title:"No"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({faceidvaluephone:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.faceidvaluephone} variant="outlined"  />}  
                       disableClearable={true}
                      />    

            <h5>
          Cost $:
            </h5>
            <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({costpricephone: e.target.value})}
                          value={this.state.costpricephone}
                          
                        />
          

            <h5>
          Sale $:
            </h5>
            <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({salepricephone: e.target.value})}
                          value={this.state.salepricephone}
                          
                        />
        
            <h5>
          Warranty $:
            </h5>
            <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({warrantycostphone: e.target.value})}
                          value={this.state.warrantycostphone}
                          
                        />
            

            <h5>
          Repair By Who:
            </h5>
            <Autocomplete
                        options={this.state.userlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({repairbywhophone:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.repairbywhophone} variant="outlined"  />}  
                       disableClearable={true}
                      />     
            

            <h5>
          Repair Cost:
            </h5>
            <Input
                          type="number"
                          min="0"
                          onChange={e => this.setState({repaircostphone: e.target.value})}
                          value={this.state.repaircostphone}
                          
                        />
            
           

            <h5>
          Repair Date:
            </h5>
            <ReactDatetime
                     inputProps={{
                       placeholder: "Repair Date",                  
                     }}    
                   closeOnSelect={true}                  
                     value={this.state.repairdatephone}
                     onChange={(e) => this.setState({repairdatephone:e})}                           
                     timeFormat={false}
                     dateFormat="DD/MM/YYYY"
                   />
            
            

            <h5>
          Supplier:
            </h5>
            <Autocomplete
                        options={this.state.supplierlistphone}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({suppliervaluephone:value})}
                        renderInput={(params) => <TextField {...params} label={this.state.suppliervaluephone} variant="outlined"  />}  
                       disableClearable={true}
                      />    

        

            <h5>
          Purchase Date:
            </h5>
            <ReactDatetime
                     inputProps={{
                       placeholder: "Purchase Date",                  
                     }}    
                   closeOnSelect={true}                  
                     value={this.state.purchasedatephone}
                     onChange={(e) => this.setState({purchasedatephone:e})}                           
                     timeFormat={false}
                     dateFormat="DD/MM/YYYY"
                   />
            

            <h5>
          Remark:
            </h5>
            <Input
                          type="text"
                          onChange={e => this.setState({remarkphone: e.target.value})}
                          value={this.state.remarkphone}
                          
                        />
            
            <h5>
          Trade In Invoice:
            </h5>
            <Input
                          type="text"
                          onChange={e => this.setState({tradeininvoicephone: e.target.value})}
                          value={this.state.tradeininvoicephone}
                          
                        />
           

            <h5>
          Trade In Supplier:
            </h5>
            <Input
                          type="text"
                          onChange={e => this.setState({tradeinsupplierphone: e.target.value})}
                          value={this.state.tradeinsupplierphone}
                          
                        />      
      
         
          
           
            
         </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={() => this.setState({editdetailphonemodalonoff:false})}
            >
              Close
            </Button>
            <Button color="primary" type="button" onClick={this.editdetailfunctionphone.bind(this,this.state.selectedjobid)}>
              Submit
            </Button>
          </div>
        </Modal>
     

              
      </>
    );
  }
}


export default Stock;
