/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {Link, Route , withRouter} from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Alert,
} from "reactstrap";
import { isConstructorDeclaration } from "typescript";
import axios from 'axios';
import ReactDatetime from "react-datetime";
import {Helmet} from "react-helmet";
import Moment from 'moment';

var md5 = require('md5');
const themecolor = "#92e0e1"
const secondarycolor = "#5170c9"




class Login extends React.Component {
  
  state= {
    username:'',
    userpassword:'',
    loginfail:false,
    remembermecheck:false,
    errorsubmit:false,
    statusscreen:0,
    alerterrormessage:'',
    fivestarrate:'5',
    name:'',
    phoneprefix:'010',
    contact:'',
    birthday:'',
    model:'',
    gender:'Male',
    findus:'Facebook',
    
    //linkurl:"http://pos.mzprotrading.com/api/mzprotrading.php?",
   // imageurllink:"http://crm.mewahtrans.com.my/img/profile_img/",
    linkurl:"http://membership.mzprotrading.com/api/mzprotrading.php?",
   // imageurllink:"http://crm.mewahtrans.com.my/api/img/profile_img/",
  }
   
 
  
  componentDidMount(){
    
  }


  submitcustomerregister = () =>{


    var errorvalue = false
    var errormessage = ""

    if(this.state.name == ""){
      errorvalue = true
      errormessage = "Require to fill in Your Name"
    }

    if(this.state.phoneprefix == ""){
      errorvalue = true
      errormessage = "Require to fill in Your Phone Prefix"
    }

    if(this.state.contact == ""){
      errorvalue = true
      errormessage = "Require to fill in Your Phone Number"
    }

    if(this.state.birthday == ""){
      errorvalue = true
      errormessage = "Require to fill in Your Birthday"
    }

    if(this.state.model == ""){
      errorvalue = true
      errormessage = "Require to fill in Product you had bought"
    }

    if(this.state.gender == ""){
      errorvalue = true
      errormessage = "Require to fill in Gender"
    }

    if(this.state.findus == ""){
      errorvalue = true
      errormessage = "Require to fill in How you know us"
    }

    if(this.state.fivestarrate == ""){
      errorvalue = true
      errormessage = "Require to fill in Your Rate for us"
    }

    if(errorvalue == true){
      window.confirm(errormessage);
    }else{
      var urlencoded = new URLSearchParams();

      var birthdaydatetotimestamp = Moment(this.state.birthday, 'DD/MM/YYYY').unix();
      urlencoded.append("name", this.state.name);
      urlencoded.append("phoneprefix", this.state.phoneprefix);
      urlencoded.append("contact", this.state.contact);
   
      console.log("birtday: " + birthdaydatetotimestamp);
      urlencoded.append("birthday", birthdaydatetotimestamp);
      urlencoded.append("model", this.state.model);
      urlencoded.append("gender", this.state.gender);
      urlencoded.append("email", this.state.email);
      urlencoded.append("findus", this.state.findus);
      urlencoded.append("invoicenumber", this.state.invoicenumber);
      urlencoded.append("fivestarrate", this.state.fivestarrate);
    
      let link = this.state.linkurl +'mode=admin-registercustomer'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        //console.log(json)
        if(json.success == 1){
               
          this.setState({statusscreen:1})
          this.setState({memberid:json.memberid});
          // this.setState({loading:false})  
         }        
            
      else{
        this.setState({statusscreen:2}) 
        this.setState({memberid:0});
       this.setState({loading:false}) 
     console.log(json)
   }
       })
    }
   
  
  }

  handleChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })
}
  



  render() {
    return (
      <>
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>MZ Pro Trading</title>
            </Helmet>
        </div>
        {this.state.statusscreen == 0 ?
         <Col lg="5" md="7">
         <Card className="bg-secondary shadow border-0">
        
           <CardBody className="px-lg-5 py-lg-5">
             <div className="text-center text-muted mb-4">
               <small>* You will get member voucher and latest promotion every month *</small>                
             </div>
             <div className="text-center text-muted mb-4">
             <small>* We will send you a confirmation message within 2 days *</small>            
             </div>
             {this.state.errorsubmit == true ?
                    <Alert color="danger">
                    {this.state.alerterrormessage}
                  </Alert>
                  :
                  null
                  }
             <Form role="form">
             <small>Name:</small>
               <FormGroup className="mb-3">
                 <InputGroup className="input-group-alternative">
                  
                   <Input 
                   name='name' 
                   style={{borderWidth:1,borderColor:'black',borderBlockWidth:1,borderBlockColor:'black'}}
                   value={this.state.name} onChange={e =>this.setState({name:e.target.value})}  type="text" />
                 </InputGroup>
               </FormGroup>
   
       
      <FormGroup>
        <small>Phone Prefix:</small>
        <InputGroup className="input-group-alternative">
        <Input type="select"  value={this.state.phoneprefix}  onChange={e =>this.setState({phoneprefix:e.target.value})}>
          <option>010</option>
          <option>011</option>
          <option>012</option>
          <option>013</option>
          <option>014</option>
          <option>015</option>
          <option>016</option>
          <option>017</option>
          <option>018</option>
          <option>019</option>
          <option>+65</option>
        </Input>
        </InputGroup>
    
      </FormGroup>
    
      <FormGroup>
      <small>Phone Number:</small>
                 <InputGroup className="input-group-alternative">
                  
                   <Input name='contact' value={this.state.contact} onChange={e => this.setState({contact:e.target.value})}  type="text" />
                 </InputGroup>
               </FormGroup>
   
  



            
               <FormGroup>
               <small>Birthday:</small>
               <InputGroup className="input-group-alternative">
                 
                   <ReactDatetime
                    inputProps={{                                     
                    }}    
                  closeOnSelect={true}                  
                    value={this.state.birthday}
                    onChange={(e) => this.setState({birthday:e})}                           
                    timeFormat={false}
                    dateFormat="DD/MM/YYYY"
                  />
                 </InputGroup>
             
               </FormGroup>


               <FormGroup>
               <small>Which model you bought from us?:</small>
               <InputGroup className="input-group-alternative">
                
                   <Input name='model' value={this.state.model} onChange={e => this.setState({model:e.target.value})}  type="text" />
                 </InputGroup>
               
               </FormGroup>

               
               <FormGroup>
               <small>Gender:</small>
        <InputGroup className="input-group-alternative">
        <Input type="select"  value={this.state.gender}  onChange={e =>this.setState({gender:e.target.value})}>
          <option>Male</option>
          <option>Female</option>        
        </Input>
        </InputGroup>
            
               </FormGroup>

               <FormGroup>
               <small>Email:</small>
               <InputGroup className="input-group-alternative">
                 
                   <Input name='model' value={this.state.email} onChange={e => this.setState({email:e.target.value})}  type="text" />
                 </InputGroup>
               
               </FormGroup>

               <FormGroup>
               <small>How do you know us?:</small>
        <InputGroup className="input-group-alternative">
        <Input type="select"  value={this.state.findus}  onChange={e =>this.setState({findus:e.target.value})}>
          <option>Facebook</option>
          <option>Instagram</option>        
          <option>Mudah</option>   
          <option>FB Marketplace</option>   
          <option>Tik Tok</option>   
          <option>Friends</option>   
          <option>Walk-in</option>   
          <option>Lazada</option>  
          <option>Shopee</option>  
        </Input>
        </InputGroup>
            
               </FormGroup>

               
               <FormGroup>
               <small>Invoice Number:</small>
               <InputGroup className="input-group-alternative">
                 
                   <Input name='invoicenumber' value={this.state.invoicenumber} onChange={e => this.setState({invoicenumber:e.target.value})}  type="text" />
                 </InputGroup>
               
               </FormGroup>


               <small>Rate Us:</small>
        <InputGroup className="input-group-alternative">
        <Input type="select"  value={this.state.fivestarrate}  onChange={e =>this.setState({fivestarrate:e.target.value})}>
          <option>5</option>
          <option>4</option>        
          <option>3</option>   
          <option>2</option>   
          <option>1</option>    
        </Input>
        </InputGroup>


             
               <div className="text-center">
               
                 <NavLink className="nav-link-icon" >
                 <Button className="my-4" color="primary" type="button" onClick={this.submitcustomerregister.bind(this)}>
                   Submit
                 </Button>
                 </NavLink>
               
                
               </div>
             
             </Form>
           </CardBody>
         </Card>
        
       </Col>
        :
        this.state.statusscreen == 1?
        <Col>
           <Card className="bg-secondary shadow border-0">
           <CardBody className="px-lg-5 py-lg-5">
           <div className="text-center text-muted mb-4">
               <small>Submit Success, Thank you for your Register ! Your Member ID is : </small>   
               <p style={{fontWeight:'bold',fontSize:30}}>{this.state.memberid}</p>                  
             </div>
            </CardBody>
           </Card>
        </Col>
        :
        this.state.statusscreen == 2 ?
        <Col>
           <Card className="bg-secondary shadow border-0">
           <CardBody className="px-lg-5 py-lg-5">
           <div className="text-center text-muted mb-4">
               <small>Submit Fail, Please direct contact us, Thank you </small>                
             </div>
            </CardBody>
           </Card>
        </Col>
        :
        null
        }
       
      </>
    );
  }
}

export default Login;
